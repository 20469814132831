<section
  *ngIf="content"
  class="usa-section width-full padding-y-8 desktop:padding-y-15 padding-x-2 tablet:padding-x-8 display-flex flex-justify-center {{topTriangle}} {{bottomTriangle}} 
  {{
    bgColor ?? 'bg-primary-dark'
  }}"
>
  <div class="display-flex width-full maxw-desktop-lg flex-column gap-4">
    <span
      class="margin-y-0 font-lg maxw-tablet-lg usa-prose"
      [innerHTML]="safeBodyHtml"
    >
  </span>
  </div>
</section>
