<section aria-label="COI Tool set" aria-roledescription="carousel" 
    class="image-carousel border-top-1 border-bottom-1  display-flex flex-justify flex-column" 
    style="background: no-repeat center / cover url({{carouselItems[selectedCarouselIndex].image}});">
    <div class="image-carousel-header-wrapper display-flex flex-align-self-start">
        <div [innerHTML]="safeHeaderHtml" class="image-carousel-header text-semibold remove-margin-from-immediate-children dark-text-selector padding-x-2 text-base-darkest">
        </div>
    </div>

    <div class="image-carousel-body-container grid-row tablet:flex-row flex-column flex-justify desktop:padding-4 padding-2 tablet:gap-0 gap-2">
        <div role="group" aria-roledescription="slide" aria-live="polite" class="image-carousel-body  tablet:grid-col-5 maxw-mobile-lg grid-col tablet:grid-col-6 bg-base-darkest padding-4  display-flex flex-column gap-4">
            <ng-container *ngIf="carouselItems[selectedCarouselIndex] as carouselItem">
                <div class="image-carousel-body-header">
                    <!-- <p id="name" class="text-bold font-lg margin-0">{{carouselItem.name}}</p>
                    <p class="font-3xs margin-top-neg-1 display-flex flex-wrap gap-05 flex-align-center">
                        <i class="bi {{carouselItem.icon}} text-{{carouselItem.color}} "></i>
                        <strong>Community</strong> of <strong>Innovation</strong>
                    </p> -->
                    <img *ngIf="carouselItem.header_image !== undefined" [src]="carouselItem.header_image.url" [alt]="carouselItem.header_image.url">
                </div>
                <div id="description" [innerHTML]="carouselItem.description" class="body-content remove-margin-from-immediate-children usa-prose"></div>
                <a [href]="carouselItem .url" aria-label="Explore (opens in a new tab)" class="explore-button usa-button flex-align-self-start display-flex flex-align-center dark-text-selector flex-justify-center">
                    <span [innerHTML]="carouselItem.cta" class="remove-margin-from-immediate-children text-semibold"></span>     
                    <i aria-hidden="true"  class="bi display-flex text-white font-xl bi-arrow-right-short text-heavy text-base-darkest "></i>
                </a>
            </ng-container>
        </div>

        <div class="image-carousel-pagination  tablet:grid-col-4 grid-col tablet-lg:margin-right-2 margin-right-0 margin-x-auto tablet:margin-right-0 margin-top-auto">
           <div class="image-carousel-paginator bg-base-darkest radius-md display-flex flex-justify flex-align-center padding-y-1 padding-x-2">
                <button [attr.aria-label]="'Previous Slide'" 
                    (click)="paginateCarouselItem(false)"
                    class="display-flex usa-button--unstyled content-carousel-header-content-left-control font-lg flex-justify-center flex-align-self-center text-white flex-align-center grid-col-1 margin-right-1">
                    <i class="bi bi-chevron-left text-heavy"></i>
                </button>
                            
                <div class="paginator-orbs display-flex gap-1">
                    <button [attr.aria-current]="selectedCarouselIndex === i" (click)="selectedCarouselIndex = i" 
                        *ngFor="let item of carouselItems; let i = index"
                        [ngClass]="{'bg-primary': selectedCarouselIndex === i}" 
                        [attr.aria-label]="'Show slide ' + (i + 1) + ' of ' + carouselItems.length" 
                        class="pagination-orb usa-button--unstyled bg-base-dark radius-pill height-2 width-2 hover:bg-primary" >
                    </button>
                </div>

                <button [attr.aria-label]="'Next Slide'"  (click)="paginateCarouselItem(true)" 
                    class="display-flex content-carousel-header-content-right-control usa-button--unstyled font-lg flex-justify-center text-white flex-align-self-center flex-align-center grid-col-1 margin-left-1">
                    <i class="bi bi-chevron-right text-heavy"></i>
                </button>
            </div>
        </div>
    </div>
</section>