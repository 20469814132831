import { Pipe, PipeTransform } from '@angular/core';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItems } from '../core/content-utilities';

@Pipe({
  name: 'linkedItems'
})
export class LinkedItemsPipe implements PipeTransform {

  transform(item: IContentItem, linkedItemCode: string): IContentItem[] {
    return getLinkedItems(item, linkedItemCode);
  }

}
