<section *ngIf="content" class="usa-section width-full padding-y-8 desktop:padding-y-15 padding-x-2 tablet:padding-x-8 display-flex flex-justify-center">


    <div class="display-flex width-full maxw-desktop-lg flex-column gap-4">
        <span class="usa-intro margin-y-0 maxw-tablet-lg font-lg remove-margin-from-immediate-children" [innerHTML]="safeBodyHtml"></span>
        <ul  class="usa-list flex-column flex-justify-center desktop:flex-row usa-list--unstyled display-flex gap-6">
            <li *ngFor="let block of blocks" class="display-flex flex-column gap-2">
                <div [innerHTML]="block.header" class="block-header display-flex flex-justify-center flex-align-self-start padding-y-05 padding-x-105 font-md text-base-darkest dark-text-selector text-semibold {{block.bgColor}} remove-margin-from-immediate-children">
                 
                </div>
                <div class="block-body usa-prose" [innerHTML]="block.body"></div>
            </li>
     
            
     
            
            
          </ul>
    </div>


</section>