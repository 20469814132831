import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Elements, IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItem, getLinkedItems, getItemSystemType } from 'src/app/core/content-utilities';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';
import { HeaderService } from 'src/app/service/header.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-standard-page',
  templateUrl: './standard-page.component.html',
  styleUrls: ['./standard-page.component.scss']
})
export class StandardPageComponent {
  data: IContentItem;
  pageComponents: any[]  = [];
  header: IContentItem  | null = null;
  footer: IContentItem | null = null;
  codename: string = '';

  constructor(
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private title: Title,
    private router: Router 
    ) {
      // get data from resolver
      this.data  = this.route.snapshot.data['data'] 
  }

  async ngOnInit(){
    this.processContentItem(Array.isArray(this.data) ? this.data[0] : this.data);
  }

  ngAfterViewChecked() {
    let savedScrollY = sessionStorage.getItem('clickScrollY');
    let savedScrollYLocked = sessionStorage.getItem('clickScrollY-lock');
    if (!savedScrollYLocked && savedScrollY) {
      sessionStorage.removeItem('clickScrollY');
      window.setTimeout(() => {
        window.scrollTo({behavior: 'instant',top:parseInt(savedScrollY || '0'), left:0})},
      100);
    }
  }

  processContentItem(item: any) {
    this.header = getLinkedItem(item, 'header');
    this.headerService.currentHeader.next(this.header);
    this.pageComponents = getLinkedItems(item, 'body_content');
    this.footer = getLinkedItem(item, 'footer');
    this.title.setTitle(this.data.elements['page_title'].value as string);

    this.codename = item.system.codename;
  }

  processSEO(item: any) {
  }
  
  
  getSystemType(item: any) {
   return NAME_TYPE_MAPPING[`${item.system.codename}`] || getItemSystemType(item);
  }

}
// some content items do not merit a specific type
// this is a shortcut to perform manual mapping
// using the item name instead of the type
const NAME_TYPE_MAPPING: any = {
  'blog_view': 'component___blog_view'
};

export type StandardPageContentType = IContentItem<{
  body_content: Elements.LinkedItemsElement<any>;
}>;