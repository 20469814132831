import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { homePageResolver } from './page/standard-page/home-page-resolver';
import { StandardPageComponent } from './page/standard-page/standard-page.component';
import { standardPageResolver } from './page/standard-page/standard-page-resolver';
import { CustomRouteReuseStrategy } from './strategy/CustomRouteReuseStrategy';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: StandardPageComponent,resolve: { data: homePageResolver }},
  { path: ':slug',component: StandardPageComponent,resolve: { data: standardPageResolver }},
  { path: ':slug/:extra',component: StandardPageComponent,resolve: { data: standardPageResolver }},

];

@NgModule({
  providers: [  { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled", anchorScrolling: 'enabled', scrollOffset: [0, 50] })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
