import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getTriangleClasses } from 'src/app/core/content-utilities';
import { TriangleComponent } from 'src/app/interface/TriangleComponent';

@Component({
  selector: 'app-quote-block',
  templateUrl: './quote-block.component.html',
  styleUrls: ['./quote-block.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuoteBlockComponent extends TriangleComponent implements OnInit, AfterViewInit {


  safeQuoteHtml: SafeHtml | null = '';
  safeAttributionHtml: SafeHtml | null = '';

  @ViewChild('quoteSection') quoteSection?: ElementRef<HTMLDivElement>




  constructor(
    private sanitizer: DomSanitizer,
    ele: ElementRef
    ) { 
      super(ele);
    }

    override ngOnInit(): void {
    if(!this.content) return;

    const unsafeQuoteHtml = this.content.elements['quote'].value;
    const unsafeAttributionHtml = this.content.elements['attribution'].value;
    this.safeQuoteHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeQuoteHtml);
    this.safeAttributionHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeAttributionHtml);


    
      super.ngOnInit()

  }




}
