<section class="width-full display-flex flex-column flex-align-center">

    <div
        class="journey-step-view-container position-relative width-full display-flex flex-column tablet:flex-row gap-4">
        <!-- sidebar -->
        <div class="journey-step-nav position-sticky grid-area-a padding-right-1  padding-left-3 padding-y-3 width-full maxw-card-lg display-none tablet:display-flex flex-column gap-3">
            <a [routerLink]="['/reference-architecture']" class="journey-map-link  display-flex flex-align-center font-sm text-normal gap-1">
                <i
                    class="bi teal-bg font-lg display-flex flex-align-center text-heavy arrow-card bi-arrow-left-short"></i>
                Back</a>

            <ul
                class="usa-list display-flex border-top-2px border-base-dark padding-top-3 width-full flex-column gap-1  usa-list--unstyled">
                <li *ngFor="let term of taxonomy">
                    <span (click)="selectItem($event, term)" [class.no-border]="!term.isOpen"
                        [class.selected]="term?.codename === selectedParent?.codename" class="step-link display-flex text-normal border-right-05 border-transparent font-md padding-right-2 l1">
                        {{ term.name }}</span>
                    <div *ngIf="term.isOpen && (term.mappedTerms?.size ?? 0) > 0" class="l2">
                        <div *ngFor="let child of term.terms">
                            <div (click)="selectItem($event, child)" [innerHtml]="wrapDivPerDepth(child)"></div>
                        </div>
                    </div>
                </li>
            </ul>


        </div>
        <!-- content -->
        <div class="journey-step-body width-full display-flex grid-area-b flex-column gap-3">


            <div class="journey-step-body-content padding-x-2  desktop:padding-right-0 usa-prose desktop:padding-top-2">
                <h1 class="teal">{{selectedItem?.name}}</h1>

                <div class="step-description measure-4 usa-prose" [innerHTML]="description"></div>
            </div>




        </div>
        <div class="journey-step-resources padding-bottom-8 maxw-widescreen margin-top-2 width-full grid-area-d display-flex flex-column gap-1"
            id="resources">
            <div class="padding-x-2  desktop:padding-right-0 usa-prose">
                <h2 class="teal">{{selectedItem?.name}} Resources</h2>
            </div>


            <div role="search"
                class="resources-search width-full display-flex tablet:flex-row margin-0 flex-column gap-2 border-top-2px border-bottom-2px border-base-dark flex-align-center padding-y-05">
                <div
                    class="search-box padding-left-2 display-flex flex-1 desktop:maxw-mobile-lg gap-1 flex-align-center">
                    <input #src [value]="search" (keyup.enter)="onSearch($any($event.target).value)" type="text"
                        aria-label="Search for Resources" id="search-box"
                        class="usa-input margin-0 text-white width-full border-0" placeholder="Search...">

                    <button (click)="onSearch(src.value)" class="usa-button"> <i class="bi bi-search"></i>
                        Search</button>
                </div>


                <form class="display-flex flex-1 padding-x-2 flex-align-center gap-2" [formGroup]="filterForm">
                    <select formControlName="type" aria-label="filter by resource types"
                        class="usa-select flex-1 width-full tablet:width-auto bg-transparent text-white margin-0 tablet:margin-left-auto">
                        <option [ngValue]="''">All resource types</option>
                        <option [ngValue]="item.codename" *ngFor="let item of resourceTypes | async">
                            {{item.name}}</option>
                    </select>

                    <select formControlName="category" aria-label="filter by category"
                        class="usa-select flex-1 width-full tablet:width-auto bg-transparent text-white margin-0">
                        <option [ngValue]="''">All categories</option>
                        <option [ngValue]="item.system.codename" *ngFor="let item of categories | async">
                            {{item.elements['name'].value}}
                        </option>
                    </select>

                </form>
            </div>

            <div
                class="resources display-flex flex-wrap gap-3 flex-justify-center padding-y-2 padding-x-2  desktop:padding-right-0">
                <app-resource-card *ngFor="let resource of coiResourcesFiltered"
                    [content]="resource"></app-resource-card>

                <div aria-live="polite" *ngIf="!coiResourcesFiltered.length"
                    class="no-items display-flex padding-3 height-tablet-lg flex-align-center flex-justify-center">
                    <h2>No Resources Found</h2>
                </div>
            </div>

            <div
                class="resources-pagination border-top-2px border-base-dark display-flex width-full flex-column padding-top-2 padding-x-2 gap-2  desktop:padding-right-0 flex-align-center">
                <div class="display-flex width-full flex-column flex-justify-center tablet:flex-row gap-2">
                    <div aria-live="polite" aria-atomic="true"
                        class="showing display-flex width-full flex-align-center">

                        <ng-container *ngIf="coiResourcesFiltered">
                            <p class="font-2xs margin-y-2 margin-x-auto tablet:margin-x-0">
                                Showing {{coiResourcesFiltered.length > 0 ? ((page - 1) * this.ITEMS_PER_PAGE) + 1 : 0}}
                                -
                                {{Math.min(coiResourcesFiltered.length, page * this.ITEMS_PER_PAGE)}} of
                                <strong>{{coiResourcesFiltered.length
                                    }}
                                    items</strong>
                            </p>

                        </ng-container>

                    </div>



                    <div class="items-per-page width-full display-flex flex-align-center gap-1">
                        <div
                            class="display-flex gap-1 tablet:margin-left-auto flex-align-center tablet:margin-right-0 margin-x-auto">
                            <select [(ngModel)]="ITEMS_PER_PAGE" (ngModelChange)="onItemsPerPage()" id="per-page"
                                class="usa-select bg-transparent  text-white margin-y-0 width-auto padding-left-1">
                                <option [value]="12">12</option>
                                <option [value]="24">24</option>
                                <option [value]="36">36</option>
                            </select>
                            <label for="per-page" class="font-2xs">Items per page</label>
                        </div>


                    </div>
                </div>
                <div class="paginator width-full">
                    <app-pagination [page]="page" [maxPages]="maxPages"></app-pagination>
                </div>


            </div>

            <div class="next-prev-step width-full margin-top-8 display-flex flex-justify padding-x-2  desktop:padding-right-0">
            <a *ngIf="selectedIndex > 0"
                [href]="getLink('previous')"
                queryParamsHandling=""
                class="journey-map-link display-flex flex-align-center flex-align-self-start font-md text-normal gap-1">
                <i
                class="bi teal-bg text-heavy font-lg display-flex flex-align-center arrow-card bi-arrow-left-short"></i>
                {{this.taxonomy[selectedIndex - 1].name}}</a>

            <a *ngIf="selectedIndex< taxonomy.length - 1"
                [href]="getLink('next')"
                queryParamsHandling=""
                [ngClass]="{'margin-left-auto': selectedIndex === 0}"
                class="journey-map-link display-flex flex-align-center flex-align-self-start font-md text-normal gap-1">
                {{this.taxonomy[selectedIndex + 1].name}}<i
                    class="bi teal-bg text-heavy font-lg display-flex flex-align-center arrow-card bi-arrow-right-short"></i></a>

        </div>

        </div>
        <!-- right sidebar -->
        <div
            class="journey-step-at-a-glance grid-area-c desktop:position-sticky width-full desktop:maxw-mobile display-flex usa-prose flex-column gap-2 padding-2 tablet:padding-right-2  desktop:padding-left-3 desktop:padding-right-3 desktop:padding-y-3">
            <h2 class="teal">At a Glance</h2>


            <ng-container>

                <div class="resources-count display-flex flex-column ">
                    <p class="margin-0 font-md text-semibold">Resources</p>
                    <span
                        class="font-xl text-bold line-height-3 teal">{{coiResourcesFiltered.length}}</span>
                </div>
                <div *ngIf="stages.length" class="tages margin-top-0 display-flex flex-column gap-05">
                    <p class="margin-0 font-md text-semibold">Stages</p>
                    <div class="stage-list display-flex flex-wrap">
                        <ng-container *ngFor="let stage of stages; let isLast = last">
                            <p class="teal">{{stage}}</p>
                            <i *ngIf="!isLast" class="bi bi-dot text-heavy text-base-dark"></i>
                        </ng-container>
                    </div>


                    <div *ngIf="userGroups.length" class="categories margin-top-2 display-flex flex-column ">
                        <p class="margin-0 font-md text-semibold">User Groups</p>
                        <div class="categories-list display-flex flex-wrap">
                            <ng-container *ngFor="let category of userGroups let isLast = last">
                                <p class="teal">{{category}}</p>
                                <i *ngIf="!isLast" class="bi bi-dot text-heavy text-base-dark "></i>

                            </ng-container>
                        </div>
                    </div>

                    <div *ngIf="coiCrosswalk.length" class="categories display-flex flex-column margin-top-2">
                        <p class="margin-0 font-md text-semibold">Reference Architecture Crosswalk</p>
                        <div class="categories-list display-flex flex-wrap">
                            <ng-container *ngFor="let item of coiCrosswalk let isLast = last">
                                <a class="teal font-md" [routerLink]="['/capability-model-resources']"
                                [queryParams]="{ view: item.codename }"
                                queryParamsHandling="merge">{{item.name}}</a>
                                <i *ngIf="!isLast" class="bi bi-dot text-heavy text-base-dark "></i>

                            </ng-container>
                            
                        </div>
                    </div>
                </div>



            </ng-container>



        </div>

    </div>

</section>