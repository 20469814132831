import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItems } from 'src/app/core/content-utilities';
import { TrustedHtmlComponent } from 'src/app/interface/TrustedHtmlComponent';

@Component({
  selector: 'app-about-steps',
  templateUrl: './about-steps.component.html',
  styleUrls: ['./about-steps.component.scss']
})
export class AboutStepsComponent  extends TrustedHtmlComponent implements OnInit{

 
  @Input() content?: IContentItem;


  public journeyStepColumns: IContentItem[][] = [];

  ngOnInit(): void {
      if(!this.content) return;

    const journeySteps = getLinkedItems(this.content, 'journey_steps')

    const chunkSize = 3;
    for (let i = 0; i < journeySteps.length; i += chunkSize) {
        const chunk = journeySteps.slice(i, i + 3);
        this.journeyStepColumns.push(chunk)
    }

  }




}
