import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getTriangleClasses } from 'src/app/core/content-utilities';
import { TriangleComponent } from 'src/app/interface/TriangleComponent';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';

@Component({
  selector: 'app-featured-resources',
  templateUrl: './featured-resources.component.html',
  styleUrls: ['./featured-resources.component.scss']
})
export class FeaturedResourcesComponent extends TriangleComponent implements OnInit, AfterViewInit {



  public relatedResources: IContentItem[] = []

  constructor(private kontent: KontentDeliveryService, ele: ElementRef){
    super(ele);
  }

  override ngOnInit(): void {

    if(!this.content) return;

    this.kontent.client.items().type('coi___resource').containsFilter('elements.featured', ['yes']).toAllPromise()
    .then((result) => {

      this.relatedResources = result.data.items;
      


    })


        
    super.ngOnInit()
      
  }




}
