<div class="display-flex flex-column padding-top-8 gap-4 width-full flex-align-center data-arch-demo" id="widget">
    <div class="display-flex flex-column maxw-desktop-lg width-full">
        <span class="usa-prose" [innerHtml]="title"></span>
        <span class="usa-prose" [innerHtml]="subtitle"></span>
        <!-- <h1>Architecture <span class="text-primary-light">Model</span></h1> -->
    </div>
    <div class="model-wrapper padding-bottom-10" id="model-wrapper">

        <div class="first-wrapper">
            <div class="data-provider display-flex flex-column">
                <div class="primary-data-provider display-flex flex-column full-width">
                    <div class="model-header">
                        <label>Primary</label>
                        <a class="model-link" [href]="dataProvider.url">
                            <h2>
                                {{dataProvider.name}}
                            </h2>
                            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M9 6l6 6l-6 6" />
                            </svg>
                        </a>
                    </div>
                    <div class="content" *ngFor="let item of dataProvider.children">
                        <a class="label" [href]="item.url">{{item.name}}</a>
                        <div class="card theme" *ngFor="let sub_item of item.children">
                            <a class="model-link" [href]="sub_item.url">
                                <h3>
                                    {{sub_item.name}}
                                </h3>
                                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </a>
                            <div class="link-collection">
                                <a class="model-link" [href]="link.url" *ngFor="let link of sub_item.children">
                                    <span>{{link.name}}</span>
                                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 6l6 6l-6 6" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="api-provider display-flex flex-column">
                    <div class="heading">
                        <label>Data Application</label>
                        <a class="model-link" [href]="APIStandards.url">
                            <h2 style="text-decoration-color: var(--usa-black); overflow-wrap: normal; word-break: normal;">{{APIStandards.name}}</h2>
                        </a>
                    </div>
                    <div class="content">
                        <div class="card small" *ngFor="let item of APIStandards.children">
                            <a class="model-link" [href]="item.url">
                                <h3>
                                    {{item.name}}
                                </h3>
                                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </a>
                            <div class="link-collection" *ngIf="item.children && item.children.length">
                                <a *ngFor="let link of item.children" [href]="link.url" class="model-link">
                                    <span>{{link.name}}</span>
                                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 6l6 6l-6 6" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="authorization display-flex flex-row">
                <div class="authorization-bar theme">
                    <a [href]="FederatedAuthentication.url">{{FederatedAuthentication.name}}</a>
                </div>
                <div class="authorization-bar gray">
                    <a [href]="Authorization.url">{{Authorization.name}}</a>
                </div>
                <div class="authorization-bar yellow privacy">
                    <a [href]="DataPrivacyObligations.url">{{DataPrivacyObligations.name}}</a>
                </div>
            </div>
        </div>
        <div class="second-wrapper">
            <div class="data-aggregator display-flex flex-column">
                <div class="model-header">
                    <a class="model-link" [href]="DataAggregator.url">
                        <h2>
                            {{DataAggregator.name}}
                        </h2>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 6l6 6l-6 6" />
                        </svg>
                    </a>
                </div>
                <div class="content">
                    <div class="left">
                        <div class="first"></div>
                        <div class="second">
                            <a [href]="baseSlug + 'longitudinal_identity_resolution'" class="label">Longitudinal<br>Identity<br>Resolution</a>
                        </div>
                        <a [href]="baseSlug + 'validation_level'"  class="label">Validation<br>Level</a>
                    </div>
                    <div class="right">
                        <div class="metadata">
                            <div class="bar">
                                <a class="model-link"
                                    [href]="DataAggregator.children[0].url">{{DataAggregator.children[0].name}}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 6l6 6l-6 6" />
                                    </svg>
                                </a>
                            </div>
                            <div class="bar">
                                <a class="model-link"
                                [href]="DataAggregator.children[1].url">{{DataAggregator.children[1].name}}
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </a>
                            </div>
                        </div>
                        <div class="archived-storage">
                            <a class="model-link" [href]="baseSlug + 'archived_storage'">
                                <span>Archived Storage</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M9 6l6 6l-6 6" />
                                </svg> </a>
                        </div>
                        <div class="card-wrapper">
                            <div class="card small">
                                <a class="model-link" [href]="baseSlug + 'landing'">

                                    <h3>
                                        <span>Longitudinal Identity Resolution</span>
                                        Landing
                                    </h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 6l6 6l-6 6" />
                                    </svg>
                                </a>
                                <div class="first"></div>
                                <div class="second"></div>
                                <div class="third">
                                    <p class="step">1<sup>st</sup> <span>Validation<br>Level</span></p>
                                    <a [href]="baseSlug + 'landing_schema_level'" class="label">Schema Level</a>
                                </div>
                                <div class="fourth">
                                </div>
                            </div>
                            <div class="white-space">
                                <div>
                                    <a [href]="baseSlug + 'storage_types'"  class="label">Storage<br>Type</a>
                                </div>
                                <div class="first"></div>
                                <a [href]="baseSlug + 'organize_govern_classify'"  class="second">
                                    <p class="label">Organize</p>
                                    <p class="label">Govern</p>
                                    <p class="label">Classify</p>
                                </a>
                            </div>
                        
                            <!-- <div>
                                <a [href]="baseSlug + 'data_retention'">Data Retention</a>
                            </div>                      -->
                            <div class="card dark-gray small">
                                <a class="model-link" [href]="baseSlug + 'operational'">
                                    <h3>
                                        <span>Storage Type</span>
                                        Operational
                                    </h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 6l6 6l-6 6" />
                                    </svg>
                                </a>
                                <div class="first">
                                    <div class="retention">
                                        <a [href]="baseSlug + 'data_retention'">
                                            <h3>
                                                <span>Data Retention</span>
                                            </h3>
                                        </a>
                                    </div>
                                </div>
                                <div class="second" style="margin-top: -1em;">
                                    <div class="bars">

                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <a class="placard" [href]="baseSlug + 'organize_govern_classify'">
                                            <p class="label">Observe</p>
                                            <p class="label">Govern</p>
                                            <p class="label">Classify</p>
                                        </a>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                                <div class="third">
                                    <p class="step">2<sup>nd</sup> <span>Validation<br>Level</span></p>
                                    <a [href]="baseSlug + 'operational_unit_level_comparison'" class="label">Unit Level Comparison</a>
                                </div>
                                <div class="fourth">
                                </div>
                            </div>
                            <div class="card gray small">
                                <a class="model-link" [href]="baseSlug + 'standardized'">
                                    <h3>
                                        <span>Storage Type</span>
                                        Standardized
                                    </h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 6l6 6l-6 6" />
                                    </svg>
                                </a>
                                <div class="first">
                                    <div class="retention">
                                        <a [href]="baseSlug + 'data_retention'">
                                            <h3>
                                                <span>Data Retention</span>
                                            </h3>
                                        </a>
                                    </div>
                                    <a class="model-link padding-x-2" style="margin-top: auto;" [href]="baseSlug + 'ceds_data_warehouse'">
                                        CEDS Data Warehouse
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M9 6l6 6l-6 6" />
                                        </svg>
                                    </a>
                                    <!-- <div class="retention">
                                        <a [href]="baseSlug + 'data_retention'">Data Retention</a>
                                    </div> -->
                                </div>
                                <div class="second" style="margin-top: -1em;">
                                    <div class="bars">

                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <a class="placard" [href]="baseSlug + 'organize_govern_classify'">
                                            <p class="label">Observe</p>
                                            <p class="label">Govern</p>
                                            <p class="label">Classify</p>
                                        </a>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>

                                    </div>
                                </div>
                                <div class="third">
                                    <p class="step">3<sup>rd</sup> <span>Validation<br>Level</span></p>
                                    <a class="label" [href]="baseSlug + 'standardized_statistical_and_longitudinal_analysis'">Statistical and Longitudinal Analysis</a>
                                </div>
                            </div>
                            <div class="card small theme">
                                <a class="model-link" [href]="baseSlug + 'curated'">
                                    <h3>
                                        <span>Storage Type</span>
                                        Curated
                                    </h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 6l6 6l-6 6" />
                                    </svg>
                                </a>
                                <div class="first">
                                    <div class="retention">
                                        <a [href]="baseSlug + 'data_retention'">
                                            <h3>
                                                <span>Data Retention</span>
                                            </h3>
                                        </a>
                                    </div>
                                </div>
                                <div class="second" style="margin-top: -1em;">
                                    <div class="bars">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <a class="placard" [href]="baseSlug + 'organize_govern_classify'">
                                            <p class="label">Observe</p>
                                            <p class="label">Govern</p>
                                            <p class="label">Classify</p>
                                        </a>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                                <div class="third">
                                    <p class="step">4<sup>th</sup> <span>Validation<br>Level</span></p>
                                    <a [href]="baseSlug + 'curated_business_rule_specific'" class="label">Business Rule Specific</a>
                                </div>
                                <div class="fourth">
                                </div>
                            </div>
                        </div>
                        <div class="data-layer">
                            <a class="model-link" [href]="baseSlug + 'aggregator_data_quality_layer'" >
                                <h3>
                                    Data Quality Layer
                                </h3>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </a>
                            <div>
                                <a class="model-link" [href]="baseSlug + 'quality_business_rules_engine'" >
                                    <span>Business Rules Engine</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 6l6 6l-6 6" />
                                    </svg>
                                </a>
                                <a class="model-link" [href]="baseSlug + 'quality_standardized_business_rules_representation'" >
                                    <span>Standardized Business Rules Representation</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 6l6 6l-6 6" />
                                    </svg> </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <span class="line"></span>
                </div>
            </div>

            <div class="consumers display-flex flex-column">
                <div class="model-header">
                    <a class="model-link" [href]="DataConsumers.url">
                        <h2>
                            {{DataConsumers.name}}
                        </h2>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 6l6 6l-6 6" />
                        </svg>
                    </a>
                </div>
                <div class="content">
                    <a class="model-link" [href]="DataConsumers.children[0].url">
                        <svg class="no-hover consumer-icon" width="100" height="100" viewBox="0 0 100 100" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="100" height="100" fill="white" />
                            <rect x="91" y="5" width="4" height="90" fill="#DBDCF0" />
                            <rect x="81" y="5" width="4" height="90" fill="#595ADC" />
                            <rect x="71" y="5" width="4" height="90" fill="#1E1E1E" />
                            <rect x="5" y="5" width="60" height="90" fill="#1E1E1E" />
                            <rect x="10" y="24" width="4" height="50" transform="rotate(-90 10 24)" fill="white" />
                            <rect x="10" y="57" width="4" height="50" transform="rotate(-90 10 57)" fill="white" />
                            <rect x="10" y="35" width="4" height="50" transform="rotate(-90 10 35)" fill="white" />
                            <rect x="10" y="68" width="4" height="50" transform="rotate(-90 10 68)" fill="white" />
                            <rect x="10" y="46" width="4" height="50" transform="rotate(-90 10 46)" fill="white" />
                            <rect x="10" y="79" width="4" height="50" transform="rotate(-90 10 79)" fill="white" />
                        </svg>

                        <label>
                            <span>{{DataConsumers.children[0].name}}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M9 6l6 6l-6 6" />
                            </svg>
                        </label>
                    </a>
                    <div class="privacy-wrapper">
                        <a class="model-link" [href]="consumer.url"
                            *ngFor="let consumer of DataConsumers.children.slice(1)">

                            <svg *ngIf="consumer.url.indexOf('reporting_analytics') > -1" width="100"
                                class="no-hover consumer-icon" height="100" viewBox="0 0 100 100" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="100" height="100" fill="white" />
                                <rect x="5" y="5" width="25" height="90" fill="#1E1E1E" />
                                <rect x="37.5" y="38" width="25" height="57" fill="#595ADC" />
                                <rect x="70" y="66" width="25" height="29" fill="#DBDCF0" />
                                <rect x="37.5" y="9" width="4" height="57.5" transform="rotate(-90 37.5 9)"
                                    fill="#1E1E1E" />
                                <rect x="37.5" y="20" width="4" height="57.5" transform="rotate(-90 37.5 20)"
                                    fill="#1E1E1E" />
                                <rect x="37.5" y="31" width="4" height="57.5" transform="rotate(-90 37.5 31)"
                                    fill="#1E1E1E" />
                            </svg>
                            <svg width="100" *ngIf="consumer.url.indexOf('operational_systems') > -1"
                                class="no-hover consumer-icon" height="100" viewBox="0 0 100 100" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="100" height="100" fill="white" />
                                <rect x="5" y="69" width="25" height="26" fill="#1E1E1E" />
                                <rect x="37.5" y="37" width="25" height="26" fill="#595ADC" />
                                <rect x="70" y="5" width="25" height="26" fill="#DBDCF0" />
                                <rect x="5" y="9" width="4" height="25" transform="rotate(-90 5 9)" fill="#1E1E1E" />
                                <rect x="70" y="41" width="4" height="25" transform="rotate(-90 70 41)"
                                    fill="#1E1E1E" />
                                <rect x="37.5" y="9" width="4" height="25" transform="rotate(-90 37.5 9)"
                                    fill="#1E1E1E" />
                                <rect x="37.5" y="73" width="4" height="25" transform="rotate(-90 37.5 73)"
                                    fill="#1E1E1E" />
                                <rect x="5" y="41" width="4" height="25" transform="rotate(-90 5 41)" fill="#1E1E1E" />
                                <rect x="70" y="73" width="4" height="25" transform="rotate(-90 70 73)"
                                    fill="#1E1E1E" />
                                <rect x="5" y="20" width="4" height="25" transform="rotate(-90 5 20)" fill="#1E1E1E" />
                                <rect x="70" y="52" width="4" height="25" transform="rotate(-90 70 52)"
                                    fill="#1E1E1E" />
                                <rect x="37.5" y="20" width="4" height="25" transform="rotate(-90 37.5 20)"
                                    fill="#1E1E1E" />
                                <rect x="37.5" y="84" width="4" height="25" transform="rotate(-90 37.5 84)"
                                    fill="#1E1E1E" />
                                <rect x="5" y="52" width="4" height="25" transform="rotate(-90 5 52)" fill="#1E1E1E" />
                                <rect x="70" y="84" width="4" height="25" transform="rotate(-90 70 84)"
                                    fill="#1E1E1E" />
                                <rect x="5" y="31" width="4" height="25" transform="rotate(-90 5 31)" fill="#1E1E1E" />
                                <rect x="70" y="63" width="4" height="25" transform="rotate(-90 70 63)"
                                    fill="#1E1E1E" />
                                <rect x="37.5" y="31" width="4" height="25" transform="rotate(-90 37.5 31)"
                                    fill="#1E1E1E" />
                                <rect x="37.5" y="95" width="4" height="25" transform="rotate(-90 37.5 95)"
                                    fill="#1E1E1E" />
                                <rect x="5" y="63" width="4" height="25" transform="rotate(-90 5 63)" fill="#1E1E1E" />
                                <rect x="70" y="95" width="4" height="25" transform="rotate(-90 70 95)"
                                    fill="#1E1E1E" />
                            </svg>
                            <svg *ngIf="consumer.url.indexOf('consumer_application') > -1" width="100"
                                class="no-hover consumer-icon" height="100" viewBox="0 0 100 100" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="100" height="100" fill="white" />
                                <rect x="5" y="5" width="26" height="26" fill="#1E1E1E" />
                                <rect x="37" y="5" width="26" height="26" fill="#595ADC" />
                                <rect x="69" y="5" width="26" height="26" fill="#DBDCF0" />
                                <rect x="5" y="37" width="26" height="26" fill="#1E1E1E" />
                                <rect x="37" y="37" width="26" height="26" fill="#595ADC" />
                                <rect x="69" y="37" width="26" height="26" fill="#595ADC" />
                                <rect x="5" y="69" width="26" height="26" fill="#1E1E1E" />
                                <rect x="37" y="69" width="26" height="26" fill="#1E1E1E" />
                                <rect x="69" y="69" width="26" height="26" fill="#1E1E1E" />
                            </svg>
                            <svg width="100" *ngIf="consumer.url.indexOf('data_sharing') > -1"
                                class="no-hover consumer-icon" height="100" viewBox="0 0 100 100" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="100" height="100" fill="white" />
                                <rect x="16.7661" y="19.5938" width="4" height="90"
                                    transform="rotate(-45 16.7661 19.5938)" fill="#DBDCF0" />
                                <rect x="80.4058" y="16.7656" width="4" height="90"
                                    transform="rotate(45 80.4058 16.7656)" fill="#DBDCF0" />
                                <rect x="5" y="5" width="25" height="25" fill="#1E1E1E" />
                                <rect x="5" y="70" width="25" height="25" fill="#1E1E1E" />
                                <rect x="70" y="5" width="25" height="25" fill="#1E1E1E" />
                                <rect x="70" y="70" width="25" height="25" fill="#1E1E1E" />
                                <rect x="37.5" y="37.5" width="25" height="25" fill="#595ADC" />
                            </svg>


                            <label>
                                <span>{{consumer.name}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </label>
                        </a>
                        <div class="footer">
                            <a [href]="baseSlug + 'data_privacy_obligations'" class="label"><p>Data Privacy</p></a>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <span class="line"></span>
                </div>
            </div>
        </div>

    </div>
</div>