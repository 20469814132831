<section aria-label="Journey Map" id="map" class="usa-section display-flex flex-column">
 
        <div *ngFor="let item of steps; let i = index"  class="journey-step position-relative text-black bg-white padding-3 border-left-2 display-flex flex-column flex-justify-end flex-align-start border-bottom-2" 
        [ngClass]="{'border-left-2': (i + 1) % 2 !== 0, 'border-right-2': (i + 1) % 2 === 0, }" [ngStyle]="{'border-image': generateGradient(steps[i - 1]?.elements?.['base_color']?.value || previousColor, item?.elements?.['base_color']?.value), 'background': 'no-repeat center / cover url(' + item?.elements?.['banner_image']?.value[0]?.url + ')'}"  >

            <div [ngClass]="{'tablet:flex-align-end': (i + 1) % 2 === 0, 'tablet:flex-align-start': (i + 1) % 2 !== 0}" class="journey-step-wrapper-{{i + 1}} position-relative  display-flex flex-column width-full flex-justify-end height-full grid-row text-white ">
                <div [ngStyle]="{'--background-image-url': 'url(' + item?.elements?.['icon']?.value[0]?.url + ')'}" [ngClass]="{'margin-left-auto': (i + 1) % 2 === 0}" class="journey-step-body grid-col-2 padding-4 padding-right-6 display-flex flex-column gap-2 width-full maxw-mobile-lg bg-base-darkest">
                    <div class="step-body-header display-flex flex-column gap-1 usa-prose">
                        <h2>{{item.elements['name'].value}}</h2>

                        <p [innerHTML]="item.elements['brief'].value"></p>
                    </div>

                    <div class="resources-count maxw-card display-flex gap-1 flex-align-center">
                        <span class="font-2xl text-bold"> {{resourceCounts[i]}}</span>
                        <p class="text-thin font-sm line-height-3">associated resources</p>
                    </div>

                    <div class="call-to-action display-flex">
                        <a [routerLink]="['/journey-step', item?.system?.codename]" [style]="'background-color:' + item?.elements?.['base_color']?.value + '!important ;'" class="usa-button display-flex flex-align-self-start"><span class="remove-margin-from-immediate-children" [innerHTML]="item?.elements?.['explore_cta']?.value || 'Explore'"></span> <i class="bi display-flex font-xl bi-arrow-right-short"></i></a>
                    </div>

                </div>
            </div>

        </div>
 
</section>