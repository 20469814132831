<div class="blog-card display-flex flex-column bg-white dark-text-selector">
    <div
        class="blog-card-header text-semibold  remove-margin-from-immediate-children padding-1 font-xs line-height-3 display-flex gap-2 padding-left-2 padding-top-2">
        <span class="date-field padding-05">{{ getDate(content?.elements?.['date']?.value) }}</span>
    </div>
    <div class="blog-card-body flex-1 padding-left-2 padding-right-2 display-flex flex-column gap-1">
        <div class="blog-card-name display-flex flex-column gap-1">
            <h3 class="text-semibold line-height-3 font-lg">{{content?.elements?.['title']?.value}}</h3>
        </div>
        <p class="font-xs margin-0 flex-1 remove-margin-from-immediate-children line-height-3">
            {{content?.elements?.['short_description']?.value}}
        </p>
        <div class="display-flex flex-column gap-1">
            <div class="font-xs display-flex flex-wrap gap-05 flex-column">
                <span *ngIf="categories" [innerHtml]="categories | safeHtml"></span>
                <span class="author-name">by <span class="text-white text-bold">
                    {{ content?.elements?.['author']?.value }}
                </span></span>
            </div>
        </div>
    </div>
    <div class="blog-card-footer flex-align-center flex-justify  remove-margin-from-immediate-children padding-2 font-xs line-height-3 display-flex padding-top-10">
        <span class="text-white"><i aria-hidden="true" class="bi text-white bi-clock"></i> {{calculateReadingTime(content?.elements?.['body']?.value)}} min read</span>
        <a [routerLink]="['/blog', content?.elements?.['slug']?.value]" (click)="clickBlog()"
            class="usa-button margin-left-auto width-auto blog-card-learn-more padding-1 padding-left-2 padding-right-2">Read <i
                class="bi font-lg text-heavy bi-arrow-right-short"></i></a>
    </div>
</div>