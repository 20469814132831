import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { from, map, startWith, switchMap, tap } from 'rxjs';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlogsComponent implements OnInit {
  Math = Math

  public  ITEMS_PER_PAGE = window.localStorage.getItem('COI-ItemsPerPage') ? parseInt(window.localStorage.getItem('COI-ItemsPerPage') || '12') : 12;

  @Input() content?: IContentItem;

  public featuredBlog?: IContentItem;

  public filterFormGroup: FormGroup;
  
  public page: number = 1;

  public maxPages = 1;

  public sort = 'recent';
  public search = '';
  public category = '';

  public selectedCategories: string[] = [];

  public library = from(this.kontent.client.items().type('coi___blog').toAllPromise())
  .pipe(switchMap(result => {
    return this.route.queryParams
    .pipe(startWith({}))
    .pipe(map((params: Params) => { 
      let items = result.data.items;
      this.page = Math.max(1, parseInt(params['page'] || '1'))
      this.search = params['search'] || '';
      const {category} = params;
      const sort = params['sort'] || 'recent';
      this.sort = sort;
      this.ITEMS_PER_PAGE = parseInt(params['perPage'] || this.ITEMS_PER_PAGE);

      if (category) this.selectedCategories.push( category );
      let blogs: IContentItem[] = items
      .filter((item) => (!this.search?.length 
        || this.compareElement(item, 'title', this.search)
        || this.compareElement(item, 'body', this.search)
        || this.compareElement(item, 'author', this.search))
        && (!this.selectedCategories.length || item.elements['categories']?.value.some((cat:string) => this.selectedCategories.includes(cat)))
      );

      blogs = blogs.sort((a,b) => {
        switch(sort){
          case 'recent':{
            return new Date(b.elements['date'].value).getTime() - new Date(a.elements['date'].value).getTime()
          }
          case 'atoz':{
            return a.elements['title'].value.localeCompare(b.elements['title'].value)
          }
          case 'atoz':{
            return b.elements['title'].value.localeCompare(a.elements['title'].value)
          }
        }
      });

      this.maxPages = Math.ceil(blogs.length / this.ITEMS_PER_PAGE)
      return {total: blogs.length, blogs: blogs.slice((this.page - 1) * this.ITEMS_PER_PAGE, this.page * this.ITEMS_PER_PAGE)}
    }));
  }));

  constructor(private fb: FormBuilder, private kontent: KontentDeliveryService, private route: ActivatedRoute, private router: Router){
    this.filterFormGroup = this.fb.group({
      'category': this.fb.control('')
    });
  }

  private compareElement(item: IContentItem, field: string, compare: string){
    return (item?.elements?.[field]?.value || '').toLowerCase().includes(compare?.toLowerCase())
  }

  public clickCategory(category: string) {
    if (this.selectedCategories.includes(category)) {
        this.selectedCategories.splice(this.selectedCategories.indexOf(category), 1);
    } else {
        this.selectedCategories.push(category);
    }
    this.filterLibrary();
  }

  async ngOnInit() {
    let blogs = await this.kontent.client.items().type('coi___blog').orderByDescending('elements.date').toAllPromise();
    
    this.featuredBlog = blogs.data.items[0];
  }

  public filterLibrary() {
    this.library = from(this.kontent.client.items().type('coi___blog').toAllPromise())
    .pipe(map(result => {
      let items = result.data.items;
      let blogs: IContentItem[] = items
        .filter((item) => (!this.search?.length 
          || this.compareElement(item, 'title', this.search)
          || this.compareElement(item, 'body', this.search)
          || this.compareElement(item, 'author', this.search))
          && (!this.selectedCategories.length || item.elements['categories']?.value.some((cat:string) => this.selectedCategories.includes(cat)))
        );
  
        blogs = blogs.sort((a,b) => {
          switch(this.sort){
            case 'recent':{
                return new Date(b.elements['date'].value).getTime() - new Date(a.elements['date'].value).getTime()
            }
            case 'atoz':{
              return a.elements['title'].value.localeCompare(b.elements['title'].value);
            }
            case 'ztoa':{
              return b.elements['title'].value.localeCompare(a.elements['title'].value);
            }
          }
        });
  
        this.maxPages = Math.ceil(blogs.length / this.ITEMS_PER_PAGE);
        return {
          total: blogs.length, 
          blogs: blogs.slice((this.page - 1) * this.ITEMS_PER_PAGE, this.page * this.ITEMS_PER_PAGE)
        }
      }));
  }

  public onItemsPerPage(){
    window.localStorage.setItem('COI-ItemsPerPage', this.ITEMS_PER_PAGE.toString());

    this.router.navigate(['.'], { fragment: 'blogs', queryParams: {perPage: this.ITEMS_PER_PAGE}, relativeTo: this.route, queryParamsHandling: 'merge'})
    .then(() => window.scrollTo(0, 0))
  }

  public onSearch(search: string) {
    this.search = search?.toLowerCase();
    this.filterLibrary();
  }

  public onSort(sort: string) {
    this.sort = sort;
    this.filterLibrary();
  }

  private _copy(data: any, times: number){
    let copy = [];
    for(let i = 0; i < times; i++){
      copy.push(...structuredClone(data))
    }
    return copy;
  }

  public resetFilters(){
    this.selectedCategories = [];
    this.filterFormGroup.controls['category'].setValue('', { emitEvent: false });
    this.filterLibrary();
  }

}
