import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItem, getLinkedItems } from 'src/app/core/content-utilities';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit {
  @Input() content: IContentItem | null = null;
  safeHeaderHtml: SafeHtml | null = null;

  public selectedCarouselIndex = 0;

  public carouselItems: {
    name: string, 
    // icon: string, 
    header_image: any,
    color: string, 
    cta: SafeHtml, 
    description: SafeHtml, 
    url: string, 
    image: string, 
    imageAlt: string
  }[] = [];

  @HostBinding('attr.style')
  public get cssVars() {
    return `
      --current-toolset-color: var(--usa-${this.carouselItems[this.selectedCarouselIndex].color || 'primary'});
    `;
  }


  constructor(
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    if(!this.content) return;

    const unsafeHtml = this.content.elements['header'].value;
    this.safeHeaderHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);


    const items = getLinkedItems(this.content, 'toolset_items');


    this.carouselItems = items.map((item) => ({
      name: item.elements['name'].value,
      url: item.elements['url'].value,
      // icon: item.elements['icon'].value,
      header_image: item.elements['header_image']?.value[0],
      color: item.elements['color'].value,
      description: this.sanitizer.bypassSecurityTrustHtml(item.elements['description'].value),
      cta: this.sanitizer.bypassSecurityTrustHtml(item.elements['cta'].value || 'Explore'),
      image: item.elements['image'].value[0].url,
      imageAlt: item.elements['image'].value[0].description
    }))




  }

    /**
   * Paginates the carousel item based on the given direction.
   * 
   * @param next - A boolean indicating whether to navigate to the next item (true) or the previous item (false).
   */
    paginateCarouselItem(next: boolean) {
      if (next) {
        this.selectedCarouselIndex = (this.selectedCarouselIndex + 1) % this.carouselItems.length;
      } else {
        this.selectedCarouselIndex = (this.selectedCarouselIndex - 1 + this.carouselItems.length) % this.carouselItems.length;
      }
    }

}
