import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IsActiveMatchOptions, ActivatedRoute, Router } from '@angular/router';
import { IContentItem, IContentItemElements } from '@kontent-ai/delivery-sdk';
import { delay, switchMap, map, throwError } from 'rxjs';
import { getLinkedItems } from 'src/app/core/content-utilities';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';

@Component({
  selector: 'app-user-group-view',
  templateUrl: './user-group-view.component.html',
  styleUrls: ['./user-group-view.component.scss'],
})
export class UserGroupViewComponent implements OnInit {
  routerLinkActiveOptions: IsActiveMatchOptions = {
    matrixParams: 'ignored',
    queryParams: 'ignored',
    fragment: 'ignored',
    paths: 'subset',
  };

  Math = Math;

  public  ITEMS_PER_PAGE = window.localStorage.getItem('COI-ItemsPerPage') ? parseInt(window.localStorage.getItem('COI-ItemsPerPage') || '12') : 12;


  @Input() content: IContentItem | null = null;

  public page: number = 1;
  public maxPages = 1;
  public steps: IContentItem[] = [];
  public search: string = '';
  public type: string = '';
  public category: string = '';
  public stageOrder: string[] = [];

  public step = this.route.params.pipe(
    delay(1),
    switchMap((params) => {
      const extra = params['extra'];

      const idx = this.steps.findIndex(
        (item) => item.system.codename === extra,
      );

      if (idx === -1) {
        this.router.navigate(['/journey-map']);
        return throwError(() => {
          return '';
        });
      }

      return this.route.queryParams.pipe(
        map((query) => {
          this.type = query['type'] || '';
          this.category = query['category'] || '';
          this.search = query['search'] || '';
          this.page = parseInt(query['page']) || 1;
          this.ITEMS_PER_PAGE = parseInt(query['perPage']) || this.ITEMS_PER_PAGE;


          this.filterForm.patchValue({ type: this.type, category: this.category });

          const resources = this.kontent.client
            .items()
            .type('coi___resource')
            .containsFilter('elements.user_groups', [
              this.steps[idx].system.codename,
            ])
            .toAllPromise()
            .then((result) => ({
              total: result.data.items.length,
              all: result.data.items,
              result: result.data.items.filter(
                (item) => (!this.search?.length 
                  || this.compareElement(item, 'name', this.search)
                  || this.compareElement(item, 'short_description', this.search)
                  || this.compareElement(item, 'author', this.search)) 
                  && 
                  (this.type === '' ||
                    item.elements['format'].value.some(
                      (format: any) => format.codename === this.type,
                    )) &&
                  (this.category === '' ||
                    item.elements['journey_step'].value.includes(this.category)),
              ),
            }))
            .then(({ result, all, total }) => {
              this.maxPages = Math.ceil(result.length / this.ITEMS_PER_PAGE);
              return {
                total: result.length,
                all,
                items: result.slice(
                  (this.page - 1) * this.ITEMS_PER_PAGE,
                  this.page * this.ITEMS_PER_PAGE,
                ),
              };
            });
          return { idx, step: this.steps[idx], resources };
        }),
      );
    })
  );

  public resourceTypes = this.kontent.client
    .taxonomy('format')
    .toPromise()
    .then((result) => result.data.taxonomy.terms);
  public categories = this.kontent.client
    .items()
    .type('journey_step')
    .toPromise()
    .then((result) => result.data.items);

  public filterForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private kontent: KontentDeliveryService,
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.filterForm = this.fb.group({
      type: this.fb.control(''),
      category: this.fb.control(''),
    });

    this.kontent.client.taxonomy('stages').toPromise().then(data => {
      this.stageOrder = data.data.taxonomy.terms.map(t => t.name);
    });

    this.filterForm.valueChanges.subscribe((changes) => {
      const cleaned = Object.keys(changes).reduce(
        (accum, key) =>
          !!changes[key]
            ? Object.assign(accum, { [key]: changes[key] })
            : accum,
        {},
      ) as any;
      
      let params: any = {};
      if (cleaned.category === undefined) params.category = '';
      else params.category = cleaned.category;
      if (cleaned.type === undefined) params.type = '';
      else params.type = cleaned.type;

      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: params,
        queryParamsHandling: 'merge',
      });
    });
  }

  ngOnInit(): void {
    if (!this.content) return;

    this.steps = getLinkedItems(this.content, 'user_groups');
  }

  private compareElement(item: IContentItem, field: string, compare: string){
    return (item?.elements?.[field]?.value || '').toLowerCase().includes(compare)
  }

  public onItemsPerPage() {
    window.localStorage.setItem('COI-ItemsPerPage', this.ITEMS_PER_PAGE.toString());

    this.router
      .navigate(['.'], {
        queryParams: { perPage: this.ITEMS_PER_PAGE, page: 1 },
        relativeTo: this.route,
        queryParamsHandling: 'merge',
      })
      .then(() => window.scrollTo(0, 0));
  }

  public onSearch(search: string) {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { search },
      queryParamsHandling: 'merge',
    });
  }

  public getStages(stages: string[]): string[] {
    return this.stageOrder.filter(s => stages.includes(s));
  }
  public getCategories(categories: IContentItem<IContentItemElements>[]): IContentItem<IContentItemElements>[] {
    categories.sort((a: any, b: any) => a.elements.sort_index.value - b.elements.sort_index.value);
    return categories;
  }
}
