<section class="usa-section dark-text-selector display-flex flex-justify-center padding-x-2 tablet:padding-x-4">

    <div
        class="contact-form-container position-relative flex-align-self-center width-full offset-border offset-top-left display-flex maxw-tablet">
        <div
            class="contact-form display-flex width-full flex-column position-relative bg-white padding-2 text-base-darkest height-full gap-2">

            <form *ngIf="!submitted" (ngSubmit)="onSubmit()" [formGroup]="contactFormGroup"
                class="display-flex flex-column gap-2">
                <div class="form-title remove-margin-from-immediate-children" [innerHTML]="safeTitleHtml">
                </div>
                <div class="display-flex gap-2 flex-column desktop:flex-row">
                    <div class="form-field display-flex flex-1 flex-column gap-05">
                        <label class="usa-label" for="name">
                            {{this.content.elements['name_field_label'].value}}
                            <span class="usa-hint usa-hint--required">*</span>
                        </label>
                        <input [placeholder]="content.elements['name_field_placeholder'].value + '...'" id="name"
                            formControlName="name" class="usa-input margin-0" type="text">

                        <div class="text-emergency usa-prose" aria-live="polite"
                            *ngIf="name?.invalid && (name?.dirty || name?.touched)">

                            <p *ngIf="name?.errors?.['required']">
                                Name is required.
                            </p>

                        </div>
                    </div>

                    <div class="form-field display-flex flex-1 flex-column gap-05">
                        <label class="usa-label"
                            for="email">{{this.content.elements['email_address_field_label'].value}}
                            <span class="usa-hint usa-hint--required">*</span></label>
                        <input [placeholder]="content.elements['email_address_placeholder'].value + '...'"
                            class="usa-input margin-0" id="email" formControlName="email" type="email">


                        <div class="text-emergency usa-prose" aria-live="polite"
                            *ngIf="email?.invalid && (email?.dirty || email?.touched)">

                            <p *ngIf="email?.errors?.['required']">
                                Email is required.
                            </p>

                            <p *ngIf="email?.errors?.['email']">
                                Email is invalid.
                            </p>

                        </div>

                    </div>
                </div>


                <div class="form-field display-flex width-full flex-column gap-05">
                    <label class="usa-label"
                        for="message">{{this.content.elements['message_field_label'].value}}</label>
                    <textarea [placeholder]="content.elements['message_placeholder'].value + '...'"
                        class="width-full usa-input margin-0 maxw-none height-card" name="message" formControlName="message"
                        id="message"></textarea>
                </div>

                <div class="contact-form-footer margin-top-2 display-flex gap-1 flex-align-center">
                    <button class="usa-button flex-align-self-start">{{content?.elements?.['submit_button_label']?.value
                        || 'Submit'}} <i
                            class="bi display-flex font-xl text-heavy {{this.content.elements['submit_button_icon'].value}}"></i></button>



                    <span aria-live="polite" class="text-primary-dark text-semibold"
                        *ngIf="submitting">{{content?.elements?.['submitting_text']?.value}}</span>

                    <span aria-live="polite" *ngIf="error" class="display-flex gap-1 flex-align-center"> <i
                            class="bi text-emergency display-flex font-lg bi-exclamation-circle"></i>
                        {{content?.elements?.['error_text']?.value}}</span>

                </div>
            </form>

            <div *ngIf="submitted" class="signed-up display-flex flex-column flex-align-center flex-justify-center">
                <div
                    class="signed-up-content display-flex padding-bottom-10 padding-top-10 flex-align-center flex-column">
                    <i class="bi text-green font-2xl bi-envelope-check"></i>
                    <p>{{content.elements['successful_submit_text'].value}}</p>
                </div>
            </div>


        </div>
    </div>

</section>