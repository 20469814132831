import { Pipe, PipeTransform } from '@angular/core';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItem, getLinkedItems } from '../core/content-utilities';

@Pipe({
  name: 'resourceGroups'
})
export class ResourceGroupsPipe implements PipeTransform {

  transform(resources: IContentItem[] | IContentItem): string[] {
    const stages = new Set<string>()


    if(Array.isArray(resources)){

      for(const resource of resources){

        const groups = getLinkedItems(resource, 'user_groups')
       
        groups.forEach((group: any) =>  stages.add(group.elements['name'].value))
  
      }


    }else{

      const groups = getLinkedItems(resources, 'user_groups')
     
      groups.forEach((group: any) =>  stages.add(group.elements['name'].value))
    }






    return [...stages]
  }

}
