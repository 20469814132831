import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StandardPageComponent } from './page/standard-page/standard-page.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { ContentBlockAuthoredComponent } from './component/content-block-authored/content-block-authored.component';
import { BlockOverviewSectionComponent } from './component/block-overview-section/block-overview-section.component';
import { QuoteBlockComponent } from './component/quote-block/quote-block.component';
import { ImageTextSectionComponent } from './component/image-text-section/image-text-section.component';
import { ContentCarouselComponent } from './component/content-carousel/content-carousel.component';
import { ImageCarouselComponent } from './component/image-carousel/image-carousel.component';
import { NewsletterSignUpComponent } from './component/newsletter-sign-up/newsletter-sign-up.component';
import { CtaHeroComponent } from './component/cta-hero/cta-hero.component';
import { JourneyStepsComponent } from './component/journey-steps/journey-steps.component';
import { JourneyStepViewComponent } from './component/journey-step-view/journey-step-view.component';

import { FeaturedResourcesComponent } from './component/featured-resources/featured-resources.component';
import { ResourceCardComponent } from './component/resource-card/resource-card.component';
import { ResourcesComponent } from './component/resources/resources.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationComponent } from './component/pagination/pagination.component';
import { ResourceStagesPipe } from './pipes/resource-stages.pipe';
import { ResourceGroupsPipe } from './pipes/resource-groups.pipe';
import { UserGroupViewComponent } from './component/user-group-view/user-group-view.component';
import { LinkedItemsPipe } from './pipes/linked-items.pipe';
import { ResourceCategoriesPipe } from './pipes/resource-categories.pipe';
import { ViewResourceComponent } from './component/view-resource/view-resource.component';
import { HttpClientModule } from '@angular/common/http';
import { FaqsComponent } from './component/faqs/faqs.component';
import { AboutStepsComponent } from './component/about-steps/about-steps.component';
import { ContactFormComponent } from './component/contact-form/contact-form.component';
import { BlockOverviewGridComponent } from './component/block-overview-grid/block-overview-grid.component';
import { DataArchModelComponent } from './component/data-arch-model/data-arch-model.component';
import { RaResourceComponent } from './component/ra-resource/ra-resource.component';
import { CapabilityModelComponent } from './component/capability-model/capability-model.component';
import { BlockOverviewSimpleComponent } from './component/block-overview-simple/block-overview-simple.component';
import { TextCTABlockComponent } from './component/text-cta-block/text-cta-block.component';
import { CmResourceComponent } from './component/cm-resource/cm-resource.component';
import { BlogsComponent } from './component/blogs/blogs.component';
import { ViewBlogComponent } from './component/view-blog/view-blog.component';
import { BlogCardComponent } from './component/blog-card/blog-card.component';
import { FeaturedBlogComponent } from './component/featured-blog/featured-blog.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
  declarations: [
    AppComponent,
    StandardPageComponent,
    HeaderComponent,
    QuoteBlockComponent,
    TextCTABlockComponent,
    FooterComponent,
    ContentBlockAuthoredComponent,
    BlockOverviewSectionComponent,
    BlockOverviewGridComponent,
    BlockOverviewSimpleComponent,
    ImageTextSectionComponent,
    ContentCarouselComponent,
    ImageCarouselComponent,
    NewsletterSignUpComponent,
    CtaHeroComponent,
    JourneyStepsComponent,
    JourneyStepViewComponent,
    FeaturedResourcesComponent,
    ResourceCardComponent,
    BlogCardComponent,
    FeaturedBlogComponent,
    PaginationComponent,
    ResourcesComponent,
    BlogsComponent,
    ResourceStagesPipe,
    ResourceGroupsPipe,
    UserGroupViewComponent,
    LinkedItemsPipe,
    ResourceCategoriesPipe,
    ViewResourceComponent,
    ViewBlogComponent,
    FaqsComponent,
    AboutStepsComponent,
    ContactFormComponent,
    DataArchModelComponent,
    RaResourceComponent,
    CmResourceComponent,
    CapabilityModelComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
