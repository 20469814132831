<section class="usa-section width-full padding-bottom-8 desktop:padding-bottom-15 padding-x-2 tablet:padding-x-8 display-flex padding-top-2 flex-justify-center position-relative">
    <div class="display-flex width-full maxw-desktop-lg desktop:flex-row flex-column gap-6">
        <div class="flex-1 maxw-mobile-lg usa-prose display-flex flex-column gap-2" [innerHTML]="bypassSecurityTrustHtml(content?.elements?.['body']?.value)">

        </div>

        <div class="journey-steps-container width-full display-flex tablet:flex-row flex-2 flex-column gap-6 ">
   
            <div *ngFor="let column of journeyStepColumns" class="journey-step-column flex-1 width-full display-flex flex-column gap-4 ">
                <div *ngFor="let item of column" class="journey-step-item width-full flex-align-center display-flex gap-4">
                    <img [src]="item.elements['icon'].value[0].url" [alt]="item.elements['icon'].value[0].description">
                    
                    <a [routerLink]="['/journey-step', item.system.codename]" [ngStyle]="{'background-color': item.elements['base_color'].value}" class="cta usa-button">
                        <span>{{item.elements['name'].value}}</span>
                        <i class="bi display-flex font-xl bi-arrow-right-short"></i>
                    </a>
            
                    
                </div>
            </div>

        </div>
    </div>
</section>