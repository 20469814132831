import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItems, getTriangleClasses } from 'src/app/core/content-utilities';
import { TriangleComponent } from 'src/app/interface/TriangleComponent';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent extends TriangleComponent implements OnInit, AfterViewInit {

  public faqs: IContentItem[] = [];


  constructor(ele: ElementRef){
    super(ele);
  }


  override ngOnInit(): void {
    if(!this.content) return
    
    this.faqs = getLinkedItems(this.content, 'faqs');

    super.ngOnInit()

  }



}
