import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';

@Component({
  selector: 'app-content-block-authored',
  templateUrl: './content-block-authored.component.html',
  styleUrls: ['./content-block-authored.component.scss']
})
export class ContentBlockAuthoredComponent  implements OnInit{
  @Input() content: IContentItem | null = null;
  safeHtml: SafeHtml | null = null;

  constructor(
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    if(!this.content) return;

    const unsafeHtml = this.content.elements['rich_text_field'].value;
    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);

  }


  printItem(){
    return JSON.stringify(this.content, null, 2);
  }
}
