import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, RouteReuseStrategy } from '@angular/router';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { from, map, switchMap } from 'rxjs';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';
import { getLinkedItems } from 'src/app/core/content-utilities';

@Component({
  selector: 'app-view-blog',
  templateUrl: './view-blog.component.html',
  styleUrls: ['./view-blog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewBlogComponent implements OnDestroy, OnInit {
  @Input() content: IContentItem | null = null;

  public categories?: string;

  public blog$ = this.route.params.pipe(
    switchMap((params) => {
      return this.route.queryParams.pipe(
        switchMap((query) => {
          const res = this.kontent.client
            .items()
            .type('coi___blog')
            .equalsFilter('elements.slug', params['extra'])
            .toPromise()
            .then(val => val.data.items[0]);

          return from(res);
        }), 
      );
    }),
  );

  constructor(
    private route: ActivatedRoute,
    private kontent: KontentDeliveryService,
    private location: Location,
  ) {}

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('clickScrollY-lock');
  }

  ngOnInit() {
    if (!this.content) return;
    
    this.blog$.subscribe(blog => {
        this.categories = getLinkedItems(blog, 'categories').map((v: any) => { return `<span style="color:${v.elements.light_color.value}; font-weight: bold;">${v.elements.name.value}</span>`}).join(`<i class="bi bi-dot"></i>`);
    });
  }

  getDate(date: string) {
    return new Date(date).toLocaleString('en-us',{month:'long', day:'numeric', year:'numeric'});
  }

  public calculateReadingTime(body: string) {
    if (!body) {
      return 0;
    }
    const numberOfWords: number = (body.match(/\b[-?(\w+)?]+\b/gi) ?? []).length;
    const wordsPerMin = 180;
    const newReadingTime = Math.ceil(numberOfWords / wordsPerMin);

    return newReadingTime;
  }
}
