<section class="width-full display-flex flex-column flex-justify-center flex-align-center padding-x-2 tablet:padding-x-8 minh-viewport">
    <ng-container *ngIf="step | async as currentStep">
        <div
            class="journey-step-view-container fade-in position-relative width-full display-flex maxw-widscreen flex-column tablet:flex-row gap-6">

            <div
                class="journey-step-nav position-sticky grid-area-a padding-y-4 width-full maxw-card-lg flex-column gap-3">
                <a [routerLink]="['/journey-map']"
                    class="journey-map-link  display-flex flex-align-center font-sm text-normal gap-1">
                    <i
                        class="bi bg-map font-lg display-flex flex-align-center text-heavy arrow-card bi-arrow-left-short"></i>
                    Journey Map</a>

                <ul
                    class="usa-list display-flex border-top-2px border-base-dark padding-top-3 width-full text-right flex-column gap-1 flex-align-self-end flex-align-end usa-list--unstyled">
                    <li *ngFor="let step of steps">
                        <a class="step-link display-flex text-normal border-right-05 border-transparent font-md padding-right-2"
                            [ngStyle]="rla.isActive ? {'border-color': step.elements['light_color'].value, 'font-weight':'var(--usa-font-weight-semibold)'} : {}"
                            #rla="routerLinkActive" [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive
                            [routerLink]="['/journey-step', step.system.codename]">{{step.elements['name'].value}}</a>
                    </li>
                </ul>


            </div>

            <div class="journey-step-body flex-fill width-full display-flex grid-area-b flex-column gap-3">
                <div [ngStyle]="{'background': 'no-repeat center / cover url(' + currentStep?.step?.elements?.['banner_image']?.value[0]?.url.trim() + ')', 'border-color': currentStep.step.elements['base_color'].value}"
                    class="journey-step-body-banner display-flex flex-justify-end position-relative border-top-1 height-card">
                    <div
                        class="icon-container-{{currentStep.idx + 1}} maxw-card display-flex flex-align-center flex-justify-center width-full">
                        <img class="step-icon" [src]="currentStep.step.elements['icon'].value[0].url" alt="">
                    </div>
                </div>

                <div
                    class="journey-step-body-content usa-prose">
                    <h1 [ngStyle]="{'color': currentStep.step.elements['light_color'].value}">
                        {{currentStep.step.elements['name'].value}}</h1>

                    <div class="step-description maxw-tablet-lg usa-prose"
                        [innerHTML]="currentStep.step.elements['description'].value"></div>
                </div>



            </div>


            <div
                class="journey-step-resources padding-bottom-8 margin-top-2 width-full grid-area-d display-flex flex-column gap-1">
                <div class="usa-prose">
                    <h2 [ngStyle]="{'color': currentStep.step.elements['light_color'].value}">
                        {{currentStep.step.elements['name'].value}} Resources</h2>
                </div>


                <div role="search"
                    class="resources-search width-full display-flex tablet:flex-row margin-0 flex-column gap-2 border-top-2px border-bottom-2px border-base-dark flex-align-center padding-y-05">
                    <div
                        class="search-box display-flex flex-1 desktop:maxw-mobile-lg gap-1 flex-align-center">
                        <input #src [value]="search" (keyup.enter)="onSearch($any($event.target).value)" type="text"
                            aria-label="Search for Resources" id="search-box"
                            class="usa-input margin-0 text-white width-full border-0" placeholder="Search...">

                        <button (click)="onSearch(src.value)" class="usa-button width-auto"> <i
                                class="bi bi-search"></i>
                            Search</button>
                    </div>


                    <form class="display-flex flex-1 flex-align-center gap-2" [formGroup]="filterForm">
                        <select formControlName="type" aria-label="filter by resource types"
                            class="usa-select flex-1 width-full tablet:width-auto bg-transparent text-white margin-0 tablet:margin-left-auto">
                            <option [ngValue]="''">All stages</option>
                            <option [ngValue]="item.codename" *ngFor="let item of resourceTypes | async">
                                {{item.name}}</option>
                        </select>

                        <select formControlName="group" aria-label="filter by user groups"
                            class="usa-select flex-1 width-full tablet:width-auto bg-transparent text-white margin-0">
                            <option [ngValue]="''">All groups</option>
                            <option [ngValue]="item.system.codename" *ngFor="let item of userGroups | async">
                                {{item.elements['name'].value}}</option>
                        </select>

                    </form>
                </div>

                <div
                    class="resources display-flex flex-wrap gap-3 flex-justify-center padding-y-2">
                    <app-resource-card *ngFor="let resource of (currentStep.resources | async)?.items"
                        [content]="resource"></app-resource-card>

                    <div aria-live="polite" *ngIf="!(currentStep.resources | async)?.items?.length"
                        class="no-items display-flex padding-3 height-tablet-lg flex-align-center flex-justify-center">
                        <h2>No Resources Found</h2>
                    </div>
                </div>

                <div
                    class="resources-pagination border-top-2px border-base-dark display-flex width-full flex-column padding-top-2 padding-x-2 gap-2 tablet:padding-left-0 desktop:padding-right-0 flex-align-center">
                    <div class="display-flex width-full flex-column flex-justify-center tablet:flex-row gap-2">
                        <div aria-live="polite" aria-atomic="true"
                            class="showing display-flex width-full flex-align-center">

                            <ng-container *ngIf="(currentStep.resources | async) as resources">
                                <p class="font-2xs margin-y-2 margin-x-auto tablet:margin-x-0">
                                    Showing {{resources.items.length > 0 ? ((page - 1) * this.ITEMS_PER_PAGE) + 1 : 0}}
                                    -
                                    {{Math.min(resources.total, page * this.ITEMS_PER_PAGE)}} of
                                    <strong>{{resources.total
                                        }}
                                        items</strong>
                                </p>

                            </ng-container>

                        </div>



                        <div class="items-per-page width-full display-flex flex-align-center gap-1">
                            <div
                                class="display-flex gap-1 tablet:margin-left-auto flex-align-center tablet:margin-right-0 margin-x-auto">
                                <select [(ngModel)]="ITEMS_PER_PAGE" (ngModelChange)="onItemsPerPage()" id="per-page"
                                    class="usa-select bg-transparent  text-white margin-y-0 width-auto padding-left-1">
                                    <option [value]="12">12</option>
                                    <option [value]="24">24</option>
                                    <option [value]="36">36</option>
                                </select>
                                <label for="per-page" class="font-2xs">Items per page</label>
                            </div>


                        </div>
                    </div>
                    <div class="paginator width-full">
                        <app-pagination [page]="page" [maxPages]="maxPages"></app-pagination>
                    </div>


                </div>

                <div
                    class="next-prev-step width-full margin-top-8 display-flex flex-justify padding-x-2 tablet:padding-left-0 desktop:padding-right-0">
                    <a *ngIf="currentStep.idx > 0"
                        [routerLink]="['/journey-step', this.steps[currentStep.idx - 1].system.codename]"
                        queryParamsHandling=""
                        class="journey-map-link display-flex flex-align-center flex-align-self-start font-md text-normal gap-1">
                        <i [ngStyle]="{'background': this.steps[currentStep.idx - 1].elements['light_color'].value}"
                            class="bi bg-map text-heavy font-lg display-flex flex-align-center arrow-card bi-arrow-left-short"></i>
                        {{this.steps[currentStep.idx - 1].elements['name'].value}}</a>

                    <a *ngIf="currentStep.idx < steps.length - 1"
                        [routerLink]="['/journey-step', this.steps[currentStep.idx + 1].system.codename]"
                        queryParamsHandling="" [ngClass]="{'margin-left-auto': currentStep.idx === 0}"
                        class="journey-map-link display-flex flex-align-center flex-align-self-start font-md text-normal gap-1">
                        {{this.steps[currentStep.idx + 1].elements['name'].value}}<i
                            [ngStyle]="{'background': this.steps[currentStep.idx + 1].elements['light_color'].value}"
                            class="bi bg-map font-lg text-heavy display-flex flex-align-center arrow-card bi-arrow-right-short"></i></a>

                </div>

            </div>

            <div
                class="resource-at-a-glance grid-area-c maxw-mobile display-flex usa-prose flex-column gap-2 padding-y-4">
                <h2 class="" [ngStyle]="{'color': currentStep.step.elements['light_color'].value}">At a Glance</h2>


                <ng-container *ngIf="(currentStep.resources | async) as resources">

                    <div class="resources-count margin-0 display-flex flex-column">
                        <p class="font-md text-semibold">Resources</p>

                        <span class="text-bold line-height-3 font-xl"
                            [ngStyle]="{'color': currentStep.step.elements['light_color'].value}">{{resources.total}}</span>

                    </div>


                    <ng-container *ngIf="resources.all | resourceStages as stages">


                        <div *ngIf="stages.length" class="stages margin-top-0 display-flex flex-column gap-05">
                            <p class="margin-0 font-md text-semibold">Stages</p>


                            <div class="stage-list display-flex flex-wrap">
                                <ng-container *ngFor="let stage of getStages(stages); let isLast = last">

                                    <p [ngStyle]="{'color': currentStep.step.elements['light_color'].value}">{{stage}}
                                    </p>
                                    <i *ngIf="!isLast" class="bi bi-dot text-heavy text-base-dark"></i>
                   </ng-container>


                            </div>



                        </div>




                    </ng-container>

                    <ng-container *ngIf="resources.all | resourceGroups as groups">


                        <div *ngIf="groups.length" class="groups margin-top-0 display-flex flex-column gap-05">
                            <p class="margin-0 font-md text-semibold">User Groups</p>


                            <div class="groups-list display-flex flex-wrap">
                                <ng-container *ngFor="let stage of getGroups(groups); let isLast = last">

                                    <p [ngStyle]="{'color': currentStep.step.elements['light_color'].value}">{{stage}}
                                    </p>
                                    <i *ngIf="!isLast" class="bi bi-dot text-heavy text-base-dark"></i>

                                </ng-container>

                            </div>



                        </div>



                    </ng-container>




                </ng-container>



            </div>
        </div>
    </ng-container>


</section>