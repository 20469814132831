

<ng-container *ngFor="let item of pageComponents" [ngSwitch]="getSystemType(item)">
    
    <app-content-block-authored *ngSwitchCase="'component___authored_content_block'" [content]="item"></app-content-block-authored>
    <app-quote-block *ngSwitchCase="'component___quotation_block'" [content]="item" ></app-quote-block>
    <app-content-carousel *ngSwitchCase="'component___carousel_hero'" [content]="item"></app-content-carousel>
    <app-image-carousel  *ngSwitchCase="'component___carousel_banner'" [content]="item"></app-image-carousel>
    <app-newsletter-sign-up *ngSwitchCase="'component___newsletter_signup'" [content]="item"></app-newsletter-sign-up>
    <app-cta-hero *ngSwitchCase="'component___cta_hero'" [content]="item"></app-cta-hero>
    <app-image-text-section *ngSwitchCase="'component___columnar_text_image_section'" [content]="item"></app-image-text-section>
    <app-text-cta-block *ngSwitchCase="'component___text_cta_block'" [content]="item"></app-text-cta-block>

    <app-block-overview-section *ngSwitchCase="'component___block_overview_section'" [content]="item"></app-block-overview-section>
    <app-block-overview-grid *ngSwitchCase="'component___block_overview_grid'" [content]="item"></app-block-overview-grid>
    <app-block-overview-simple *ngSwitchCase="'component___block_overview_simple'" [content]="item"></app-block-overview-simple>

    <app-journey-steps *ngSwitchCase="'component___journey_steps'" [content]="item"></app-journey-steps>

    <app-journey-step-view *ngSwitchCase="'component___journey_step_view'" [content]="item"></app-journey-step-view>
    <app-featured-resources *ngSwitchCase="'component___feature_resources'" [content]="item"></app-featured-resources>

    <app-resources *ngSwitchCase="'component___resource_library'" [content]="item"></app-resources>
    <app-blogs *ngSwitchCase="'component___blog_library'" [content]="item"></app-blogs>

    <app-user-group-view *ngSwitchCase="'component___user_group_view'" [content]="item"></app-user-group-view>

    <app-view-resource *ngSwitchCase="'component___resource_view'" [content]="item"></app-view-resource>
    <app-view-blog *ngSwitchCase="'component___blog_view'" [content]="item"></app-view-blog>

    <app-faqs *ngSwitchCase="'component___faq'" [content]="item"></app-faqs>

    <app-about-steps *ngSwitchCase="'component___about_journey_steps'" [content]="item"></app-about-steps>

    <app-contact-form *ngSwitchCase="'component___contact_form'" [content]="item"></app-contact-form>
    <div *ngSwitchCase="'component___reference_architecture_resource_view'">
        <app-ra-resource *ngIf="codename === 'reference_architecture_resources'"></app-ra-resource>
        <app-cm-resource *ngIf="codename === 'capability_model_resources'"></app-cm-resource>
    </div>

    <div *ngSwitchCase="'component___data_arch_model'" class="display-flex margin-top-10 width-full flex-align-center flex-column padding-2 bg-white">
        <app-data-arch-model [content]="item"></app-data-arch-model>
    </div>

    <div *ngSwitchCase="'component___capability_model'" class="display-flex  width-full flex-align-center flex-column padding-2 bg-black">
        <app-capability-model class="bg-black" [content]="item"></app-capability-model>
    </div>

</ng-container>







<router-outlet></router-outlet>
 