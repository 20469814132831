import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { KontentDeliveryService } from './service/kontent-delivery.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'COI';

  constructor(
    private kontent: KontentDeliveryService
  ) { }

  async ngOnInit() {
    this.kontent.recordUserAction({ type: `siteLoad` }).subscribe();
  }
  
}
