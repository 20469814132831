import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItems } from 'src/app/core/content-utilities';

@Component({
  selector: 'app-content-carousel',
  templateUrl: './content-carousel.component.html',
  styleUrls: ['./content-carousel.component.scss'],
})
export class ContentCarouselComponent {
  @Input() content: IContentItem | null = null;
  safeHeaderHtml: SafeHtml | null = null;
  safeBodyHtml: SafeHtml | null = null;
  safeCarouselHtml: SafeHtml | null = null;

  public selectedCarouselItemIdx = 0;

  public carouselItems: {
    name: string;
    body: string;
    color: string;
    url: string;
    // icon: string,
    header_image: any;
  }[] = [];

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (!this.content) return;

    let unsafeHtml = this.content.elements['header'].value;
    this.safeHeaderHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);

    unsafeHtml = this.content.elements['body'].value;
    this.safeBodyHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);

    unsafeHtml = this.content.elements['carousel_header'].value;
    this.safeCarouselHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);

    const items = getLinkedItems(this.content, 'items');

    this.carouselItems = items.map((item) => ({
      name: item.elements['name'].value,
      body: item.elements['brief'].value,
      color: item.elements['color'].value,
      url: item.elements['url'].value,
      // icon: item.elements['icon'].value,
      header_image: item.elements['header_image']?.value[0],
    }));
  }


  /**
   * Paginates the carousel item based on the given direction.
   * 
   * @param next - A boolean indicating whether to navigate to the next item (true) or the previous item (false).
   */
  paginateCarouselItem(next: boolean) {
    if (next) {
      this.selectedCarouselItemIdx = (this.selectedCarouselItemIdx + 1) % this.carouselItems.length;
    } else {
      this.selectedCarouselItemIdx = (this.selectedCarouselItemIdx - 1 + this.carouselItems.length) % this.carouselItems.length;
    }
  }
}
