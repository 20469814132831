<section class="faqs usa-section position-relative display-flex flex-column flex-justify-center {{topTriangle}} {{bottomTriangle}} bg-white width-full text-base-darkest dark-text-selector padding-x-2 tablet:padding-x-8 padding-y-8 desktop:padding-y-15">
    <div class="display-flex width-full maxw-desktop flex-column gap-2">
        <span class="usa-prose">
          <h2>{{content.elements['header'].value}}</h2>
        </span>

        <div class="faqs-container maxw-tablet">
            <div *ngFor="let item of faqs; let i = index" class="usa-accordion  usa-accordion--bordered">
                <h3 class="usa-accordion__heading">
                  <button
                    type="button"
                    class="usa-accordion__button"
                    aria-expanded="false"
                    [attr.aria-controls]="i"
                  >
                    {{item.elements['question'].value}}
                  </button>
                </h3>
                <div hidden [id]="i" class="usa-accordion__content usa-prose width-full " [innerHTML]="item.elements['answer'].value">

                </div>
              </div>
              
        </div>
    </div>

</section>