// home-page.resolver.ts

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';
import { inject } from '@angular/core';
import { IContentItem, IContentItemElements } from '@kontent-ai/delivery-sdk';

/**
 * Functional resolver for home page 
 * Home page is just a standard page with special routing and content item codename
 * @param route 
 * @param state 
 * @returns 
 */
export async function homePageResolver(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Promise<IContentItem<IContentItemElements>> {
    const kontentService = inject(KontentDeliveryService);
    const content = await kontentService.getItemAndCache('home_page', [], 3);
    return content
}
