import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItems } from 'src/app/core/content-utilities';
import { TriangleComponent } from 'src/app/interface/TriangleComponent';

@Component({
  selector: 'app-block-overview-grid',
  templateUrl: './block-overview-grid.component.html',
  styleUrls: ['./block-overview-grid.component.scss']
})
export class BlockOverviewGridComponent extends TriangleComponent implements OnInit {

  @Input() override content: IContentItem | null = null;
  safeBodyHtml: SafeHtml | null = null;

  public blocks : {header: SafeHtml, body: SafeHtml, bgColor: string}[] = []

  constructor(
    private sanitizer: DomSanitizer,
    ele: ElementRef
    ) { 
        super(ele);
    }

  override ngOnInit(): void {
    if(!this.content) return;

    const unsafeHtml = this.content.elements['body'].value;
    this.safeBodyHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);

    const blocks = getLinkedItems(this.content, 'blocks');

    this.blocks = blocks.map((block) => ({
      header: this.sanitizer.bypassSecurityTrustHtml(block.elements['header'].value),
      body: this.sanitizer.bypassSecurityTrustHtml(block.elements['body'].value),
      bgColor: block?.elements['header_color']?.value || 'bg-yellow',
    }));

    super.ngOnInit();
  }

}
