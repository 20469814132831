import { Component, Input, OnInit } from '@angular/core';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { constructLink, getLinkedItem, getLinkedItems } from 'src/app/core/content-utilities';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() content: IContentItem | null = null;
  CONTENT_CODENAME = 'global_footer';

  public leftMenu?: IContentItem
  public leftMenuItems?: IContentItem[]

  public rightMenuOne?: IContentItem
  public rightMenuOneItems?: IContentItem[]

  public rightMenuTwo?: IContentItem
  public rightMenuTwoItems?: IContentItem[]

  public copyrightText: string = '';
  
  constructor(private kontent: KontentDeliveryService) { }

  async ngOnInit(): Promise<void> {

    if(!this.content){
      this.content = await this.loadGlobalContent();
    };

    this.copyrightText = this.content.elements['copyright_text'].value;

    this.leftMenu = getLinkedItem(this.content, 'left_content_group')
    this.leftMenuItems = getLinkedItems(this.leftMenu, 'menu_action_items')

    this.rightMenuOne = getLinkedItem(this.content, 'right_content_group_1')
    this.rightMenuOneItems = getLinkedItems(this.rightMenuOne, 'menu_action_items')

    this.rightMenuTwo = getLinkedItem(this.content, 'right_content_group_2')
    this.rightMenuTwoItems = getLinkedItems(this.rightMenuTwo, 'menu_action_items')
  
  }


  async loadGlobalContent(): Promise<IContentItem>{
    const content = await this.kontent.getItemAndCache(this.CONTENT_CODENAME);
    return content;
  }
  
  getLink(item: IContentItem) {
    return constructLink(item);
  }

}
