import { Pipe, PipeTransform } from '@angular/core';
import { getLinkedItems } from '../core/content-utilities';
import { IContentItem } from '@kontent-ai/delivery-sdk';

@Pipe({
  name: 'resourceCategories'
})
export class ResourceCategoriesPipe implements PipeTransform {

  transform(resources: IContentItem[]): IContentItem[] {
    const categories : IContentItem[] = [];

    for(const resource of resources){

      const steps = getLinkedItems(resource, 'journey_step')
     
      for(const step of steps){
        if(categories.findIndex((cat) => cat.system.codename === step.system.codename) === -1) categories.push(step)
      }

    }


    return categories
  }

}
