<section
  class="usa-section display-flex flex-justify-center width-full padding-y-4 gap-4 padding-x-2 tablet:padding-x-8 minh-viewport"
>
  <div
    *ngIf="resource$ | async as resource"
    class="fade-in display-flex width-full maxw-desktop-lg flex-column desktop:flex-row desktop:flex-justify gap-8"
  >
    <ng-container *ngIf="(resource | linkedItems: 'journey_step')[0] as step">
      <div
        class="resource-content flex-1 full-width display-flex flex-column gap-2"
      >
        <a
          (click)="goBack()"
          class="display-flex cursor-pointer flex-align-center font-sm text-normal gap-1"
        >
          <i
            class="bi bg-library text-black radius-pill font-lg display-flex flex-align-center text-heavy bi-arrow-left-short"
          ></i>
          Back</a
        >
        <div class="resource-header maxw-tablet usa-prose">
          <h1 [ngStyle]="{ color: step.elements['light_color'].value }">
            {{ resource.elements["name"].value }}
          </h1>
        </div>
        <p class="font-md margin-0 line-height-3 text-medium text-italic">
          by {{ resource.elements["author"].value }}
        </p>

        <div
          class="resource-body font-md text-normal line-height-3 margin-top-4 maxw-tablet"
          [innerHTML]="resource.elements['description'].value"
        ></div>

        <div class="resource-cta display-flex gap-2 margin-y-4">
          <ng-container *ngIf="resource.elements['url']?.value">
            <a
              [href]="resource.elements['url']?.value"
              target="_blank"
              aria-label="View Resource (opens in a new tab)"
              class="usa-button flex-align-self-start"
              >Explore
              <i
                class="bi display-flex font-lg text-heavy bi-arrow-right-short"
              ></i
            ></a>
          </ng-container>

          <ng-container *ngIf="resource.elements['file']?.value.length">
            <a
              [href]="resource.elements['file']?.value[0].url"
              target="_blank"
              aria-label="Download Resource (opens in a new tab)"
              class="usa-button flex-align-self-start"
              (click)="recordDownload(resource.elements['file']?.value[0])"
              ><i class="bi display-flex font-lg text-heavy bi-download"></i
              >Download Resource
            </a>
          </ng-container>
        </div>

        <div
          class="discover-more order-last padding-top-2 border-top-2px border-base-darker usa-prose margin-y-4 desktop:display-flex display-none"
        >
          <p class="font-md" style="max-width: none">
            Discover even more
            <span
              [ngStyle]="{
                'background-color': step.elements['light_color'].value
              }"
              class="padding-05 text-bold text-black"
              >{{ step.elements["name"].value.toLowerCase() }}</span
            >
            resources on the
            <a
              [routerLink]="['/journey-step', step.system.codename]"
              class="display-inline-flex padding-05 flex-align-center text-bold bg-map text-black gap-1 text-no-underline"
              >Journey Map
              <i
                class="bi display-flex font-lg text-heavy bi-arrow-right-short"
              ></i
            ></a>
          </p>
        </div>
      </div>

      <div
        class="resource-at-a-glance full-width maxw-mobile usa-prose flex-column gap-2"
      >
        <ng-container *ngIf="step.elements['light_color'].value as color">
          <h2 [ngStyle]="{ color: color }">
            {{ content?.elements?.["at_a_glance"]?.value }}
          </h2>

          <div class="at-a-glance-section">
            <p class="text-semibold">
              {{ content?.elements?.["year"]?.value }}
            </p>

            <p [ngStyle]="{ color: color }">
              {{ resource.elements["year"].value }}
            </p>
          </div>

          <div class="at-a-glance-section">
            <p class="text-semibold">
              {{ content?.elements?.["resource_type"]?.value }}
            </p>

            <p [ngStyle]="{ color: color }">
              {{ resource.elements["format"].value[0].name }}
            </p>
          </div>
          <div class="at-a-glance-section">
            <p class="text-semibold">
              {{ content?.elements?.["complexity"]?.value }}
            </p>

            <p [ngStyle]="{ color: color }">
              {{ resource.elements["complexity"].value[0].name }}
            </p>
          </div>
          <div class="at-a-glance-section">
            <p class="text-semibold">
              {{ content?.elements?.["category"]?.value }}
            </p>

            <p [ngStyle]="{ color: color }">
              {{ step.elements["name"].value }}
            </p>
          </div>
          <div class="at-a-glance-section">
            <p class="text-semibold">
              {{ content?.elements?.["stages"]?.value }}
            </p>

            <div class="tags display-flex flex-wrap">
              <ng-container
                *ngFor="
                  let stage of resource | resourceStages;
                  let isLast = last
                "
              >
                <p [ngStyle]="{ color: color }">{{ stage }}</p>
                <i
                  *ngIf="!isLast"
                  class="bi text-heavy text-base-dark bi-dot"
                ></i>
              </ng-container>
            </div>
          </div>
          <div class="at-a-glance-section">
            <p class="text-semibold">
              {{ content?.elements?.["user_groups"]?.value }}
            </p>

            <div class="tags display-flex flex-wrap">
              <ng-container
                *ngFor="
                  let stage of resource | resourceGroups;
                  let isLast = last
                "
              >
                <p [ngStyle]="{ color: color }">{{ stage }}</p>
                <i
                  *ngIf="!isLast"
                  class="bi text-heavy text-base-dark bi-dot"
                ></i>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>

      <div
        class="discover-more order-last padding-top-2 border-top-2px border-base-darker usa-prose margin-y-4 desktop:display-none display-flex"
      >
        <p class="font-md" style="max-width: none">
          Discover even more
          <span
            [ngStyle]="{
              'background-color': step.elements['light_color'].value
            }"
            class="padding-05 text-semibold text-black line-height-3"
            >{{ step.elements["name"].value.toLowerCase() }}</span
          >
          resources on the
          <a
            [routerLink]="['/journey-step', step.system.codename]"
            class="display-inline-flex line-height-3 padding-05 flex-align-center text-semibold bg-map text-black gap-1 text-no-underline"
            >Journey Map
            <i
              class="bi display-flex font-lg text-heavy bi-arrow-right-short"
            ></i
          ></a>
        </p>
      </div>
    </ng-container>
  </div>
</section>
