import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Elements, IContentItem } from '@kontent-ai/delivery-sdk';
import { ExtractedElements, constructImage, constructLink, extractItemElements, getLinkedItems } from 'src/app/core/content-utilities';
import { IContentItemWithLinked } from 'src/app/interface/IContentItemWithLinked';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';
import { MenuContentType } from '../menu/menu';
import { LinkContentType } from '../link/link';
import { HeaderService } from 'src/app/service/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  CONTENT_CODENAME = 'global_header';

  @Input() content: HeaderContentType | null = null;
  menuItems: MenuItem[] = []
  logo: { url: string; description: string; } = { url: '', description: '' };

  side_panel_visible: boolean = false;
  submenu_visible: boolean = false;

  constructor(private kontent: KontentDeliveryService, private headerService: HeaderService) { }

  async ngOnInit(): Promise<void> {
    this.headerService.currentHeader$.subscribe(async header => {
      this.content = header as any;
      if (!this.content) {
        this.content = await this.loadGlobalContent() as HeaderContentType;
      };
      this.buildHeaderActions();
    });
  }

  determineInternalLink(link: string): boolean {
    // Link does not start with http or https
    return !(link.startsWith('http') || link.startsWith('#'));
  }

  async buildHeaderActions() {
    this.menuItems = [];
    if(!this.content) return;

    this.logo = constructImage(this.content, 'logo');

    const headerActions = this.content.elements.header_actions;
    headerActions.linkedItems.forEach((item: MenuContentType|LinkContentType) => {
      // if menu content type
      if(item.system.type === 'component_menu'){
        item = item as MenuContentType;
        const menuItem: MenuItemDropdown = {
          contentType: 'component_menu',
          displayText: item.elements.menu_title.value,
          children: []
        }
        item.elements.menu_action_items.linkedItems.forEach(async (child: LinkContentType) => {
          let childMenuItems: MenuItem[] = [];
          if (child.elements.child_links.value.length) {
            let childLinks = await Promise.all(child.elements.child_links.value.map((link: string) => {
              return this.kontent.getItemAndCache(link);
            }));
            childMenuItems = childLinks.map(link => {
              return {
                contentType: 'component_link',
                displayText: link.elements['link_text'].value,
                target: (link.elements['link_action'] as any).value[0].codename ?? '_self',
                url: constructLink(link)
              }
            }) as MenuItem[];
          }
          let menuItemUrl = constructLink(child);
          if (!menuItemUrl && child.elements.link_destination.value.length) {
            const menuLink = await this.kontent.getItemAndCache(child.elements.link_destination.value[0]);
            menuItemUrl = `/${menuLink.elements['url_slug'].value}`;
          }
          menuItem.children.push({
            contentType: 'component_link',
            displayText: child.elements.link_text.value,
            target: (child.elements['link_action'] as any).value[0].codename ?? '_self',
            url: menuItemUrl,
            children: childMenuItems
          });
        });
        this.menuItems.push(menuItem);
      }
      if(item.system.type === 'component_link'){
        item = item as LinkContentType;
        this.menuItems.push({
          contentType: 'component_link',
          target: (item.elements.link_destination as any).value[0] || (item.elements.link_action as any).value[0]?.codename,
          displayText: item.elements.link_text.value,
          url: constructLink(item)
        })
      }
    });
  }

  getLink(item: IContentItem) {
    return constructLink(item);
  }

  async loadGlobalContent(): Promise<IContentItem> {
    const content = await this.kontent.getItemAndCache(this.CONTENT_CODENAME);
    return content;
  }

  clickListItem(event: any) {
    this.submenu_visible = false;
  }
}

export type HeaderContentType = IContentItem<{
  logo: Elements.AssetsElement;
  header_actions: Elements.LinkedItemsElement<MenuContentType | LinkContentType>;
}>;

interface MenuItemLink {
  contentType: 'component_link';
  displayText: string;
  target: '_blank' | '_self'
  url: string;
  children?: MenuItem[];
}

interface MenuItemDropdown {
  contentType: 'component_menu';
  displayText: string;
  children: MenuItemLink[];
}

type MenuItem = MenuItemLink | MenuItemDropdown;