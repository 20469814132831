import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { catchError, firstValueFrom, of } from 'rxjs';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-newsletter-sign-up',
  templateUrl: './newsletter-sign-up.component.html',
  styleUrls: ['./newsletter-sign-up.component.scss']
})
export class NewsletterSignUpComponent implements OnInit {

  public id = crypto.randomUUID();

  public submitting = false;
  public submitted = sessionStorage.getItem("newsletterSignUp") === "true";
  public error = false;

  @Input() content: IContentItem | null = null;
  safeBodyHtml: SafeHtml | null = null;

  safeHeaderHtml: SafeHtml | null = null;

  constructor(
    private sanitizer: DomSanitizer,
    private kontent: KontentDeliveryService
    ) { }

  ngOnInit(): void {
    if(!this.content) return;

    let unsafeHtml = this.content.elements['body']?.value;
    this.safeBodyHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);

    unsafeHtml = this.content.elements['sign_up_header']?.value;
    this.safeHeaderHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);

  }

  async register(email: string){
    if (this.isEmailValid(email)) {
      this.error = false;
      this.submitting = true;
      this.kontent.registerEmail(email).subscribe();
      await firstValueFrom(this.kontent.registerEmail(email));
      this.submitted = true;
      this.submitting = false;
      this.error = false;
      sessionStorage.setItem("newsletterSignUp", "true");
    } else {
      this.error = true;
    }
  }

  isEmailValid(email: string): boolean {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  }

}
