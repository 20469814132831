<footer class="usa-footer">

    <div class="width-full margin-top-4 usa-footer__primary-section">
        <nav class="usa-footer__nav" aria-label="Footer navigation">
            <div class="grid-row grid-gap-4">
                <div class="tablet:grid-col-8">
                    <section class="usa-footer__primary-content">
                        <p class="usa-footer__primary-link margin-bottom-1">{{leftMenu?.elements?.['menu_title']?.value}}</p>
                        <ul class="usa-list usa-list--unstyled">
                            <li *ngFor="let link of leftMenuItems" class="usa-footer__secondary-link">
                                <a [href]="getLink(link)">{{link.elements['link_text'].value}}</a>
                            </li>
                        </ul>
                    </section>
                </div>
                <div class="tablet:grid-col-2">
                    <section class="usa-footer__primary-content">
                        <p class="usa-footer__primary-link margin-bottom-1">{{rightMenuOne?.elements?.['menu_title']?.value}}</p>
                        <ul class="usa-list usa-list--unstyled">
                            <li *ngFor="let link of rightMenuOneItems" class="usa-footer__secondary-link">
                                <a [href]="getLink(link)">{{link.elements['link_text'].value}}</a>
                            </li>
                        </ul>
                    </section>
                </div>
                <div class="tablet:grid-col-2">
                    <section class="usa-footer__primary-content">
                        <p class="usa-footer__primary-link margin-bottom-1">{{rightMenuTwo?.elements?.['menu_title']?.value}}</p>
                        <ul class="usa-list usa-list--unstyled">
                            <li *ngFor="let link of rightMenuTwoItems" class="usa-footer__secondary-link">
                                <a [href]="getLink(link)">{{link.elements['link_text'].value}}</a>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </nav>
        <div class="display-flex width-full flex-column tablet:flex-row">
            <div [routerLink]="['/']" class="usa-logo cursor-pointer">
                <img src="/assets/coi_full_white.svg" alt="COI Components logo" />
            </div>
            <p class="flex-align-self-end flex-justify-self-end margin-left-auto font-2xs margin-top-2">{{copyrightText}}</p>
        </div>
    </div>
</footer>