<section class="usa-section dark-text-selector display-flex flex-justify-center padding-x-2 tablet:padding-x-4">
    <div class="flex-column gap-6 flex-align-center width-full  display-flex maxw-desktop desktop:flex-row">
        <div *ngIf="content?.elements?.['image']?.value[0]?.url" class="position-relative flex-align-self-center offset-border offset-top-left width-full maxw-tablet maxh-mobile-lg ">
          <img class="position-relative blog-img" [src]="content?.elements?.['image']?.value[0]?.url" alt="blog image">
        </div>

        <div class="display-flex width-full flex-column gap-2">
            <div>
                <span class="date-field padding-1">{{ getDate(content?.elements?.['date']?.value) }}</span>
            </div>
            <div class="font-lg">
                <h1>{{ content?.elements?.['title']?.value }}</h1>
                <div *ngIf="hasImage">
                    <span *ngIf="categories" class="categories font-sm" [innerHtml]="categories | safeHtml"></span>
                    <span class="author-name font-sm">by <span class="text-bold">
                        {{ content?.elements?.['author']?.value }}
                    </span></span>
                </div>
                <div  *ngIf="hasImage" class="padding-top-4">
                    <span class="padding-top-5 font-sm"><i aria-hidden="true" class="bi text-white bi-clock"></i> {{calculateReadingTime(content?.elements?.['body']?.value)}} min read</span>
                    <a [routerLink]="['/blog', content?.elements?.['slug']?.value]" (click)="clickBlog()"
                        class="usa-button width-auto blog-card-learn-more padding-1 padding-left-2 padding-right-2 margin-top-1">Read now<i
                            class="bi font-lg text-heavy bi-arrow-right-short"></i></a>
                </div>
            </div>
        </div>
        <div *ngIf="!hasImage" class="column-content">
            <div>
                <span *ngIf="categories" class="categories font-sm" [innerHtml]="categories | safeHtml"></span>
                <span class="author-name font-sm">by <span class="text-bold">
                    {{ content?.elements?.['author']?.value }}
                </span></span>
            </div>
            <div class="padding-top-4">
                <span class="padding-top-5 font-sm"><i aria-hidden="true" class="bi text-white bi-clock"></i> {{calculateReadingTime(content?.elements?.['body']?.value)}} min read</span>
                <a [routerLink]="['/blog', content?.elements?.['slug']?.value]" (click)="clickBlog()"
                    class="usa-button width-auto blog-card-learn-more padding-1 padding-left-2 padding-right-2 margin-top-1">Read now<i
                        class="bi font-lg text-heavy bi-arrow-right-short"></i></a>
            </div>
        </div>
        
    </div>

</section>