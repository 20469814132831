import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { createDeliveryClient, DeliveryClient, IContentItem, IContentItemElements, IDeliveryClientConfig } from '@kontent-ai/delivery-sdk'
import { environment } from 'src/environments/environment';

const RETRIES = 2;

@Injectable({
    providedIn: 'root'
})
export class KontentDeliveryService {

    private readonly KONTENT_CACHE = "KONTENT";

    private _cache: Cache | null = null;
    private _client: DeliveryClient;

    private config: IDeliveryClientConfig = {
        environmentId: environment.kontentProjectId,
        previewApiKey: environment.previewKey,
        excludeArchivedItems: true,
        defaultQueryConfig: {
            usePreviewMode: environment.deliveryPreview,
        }

    }
    constructor(private http: HttpClient) {


        this._client = createDeliveryClient(this.config);

    }

    public recordUserAction(event: any) {
        return this.http.post(`${environment.apiURL}/action`, event);
    }

    public registerEmail(email: string){
        return this.http.post(`${environment.apiURL}/signup`, {email})
    }

    public registerContact(data: {name: string, email: string, message?: string}){
        return this.http.post(`${environment.apiURL}/contact-signup`, data)
    }



    async getItemByFieldAndCache(type: string, field: string, fieldValue: any, depth: number = 3, retries: number = RETRIES, routeIfError = true, defaultValue = null): Promise<IContentItem<IContentItemElements>[]> {
        const cacheKey = `${type}-${field}-${fieldValue}`;
        if (!this._cache) {
            this._cache = await caches.open(this.KONTENT_CACHE);
        }
        try {
            const match = await this._cache.match(cacheKey);
            if (match) {
                const { expiry, data } = await match.json();
                if (expiry > Date.now()) {
                    return data;
                }
            }
            // Attempt to get the item from the API
            const response = await this._client.items()
                .type(type)
                .equalsFilter(`elements.${field}`, fieldValue)
                .depthParameter(depth)
                .toPromise()


            if (!response || !response.data || !response.data.items || !response.data.items.length) {
                if (routeIfError) {
                    this.routeTo404()
                    // this.errorService.notFound();
                }
                return defaultValue || Promise.reject(new Error('Page not Found'));
            }
            // Cache the new data with a TTL
            const expiryTime = Date.now() + environment.kontentCacheTTL;
            await this._cache.put(cacheKey, new Response(this.stringifySafe({ expiry: expiryTime, data: response.data })));
            return response.data.items;
        }

        catch (err) {
            if (retries > 0) {
                await new Promise(resolve => setTimeout(resolve, 1000)); // Delay before retry
                return this.getItemByFieldAndCache(type, field, fieldValue, depth, retries - 1, routeIfError, defaultValue);
            } else {
                console.log(err)
                throw err;
            }
        }
    }


    get client() {
        return this._client;
    }



    async getItemAndCache(codename: string, elements?: string[], depth = 3, retries = RETRIES): Promise<IContentItem> {
        const cacheRequest = `${codename}`;
        if (!this._cache) {
            this._cache = await caches.open(this.KONTENT_CACHE);
        }

        const match = await this._cache.match(cacheRequest);
        if (match) {
            const { expiry, data } = await match.json();
            if (expiry > Date.now()) {
                return data;
            }
        }

        let request = await this._client.item(codename).depthParameter(depth);

        if (elements && elements.length > 0) {
            request = request.elementsParameter(elements);
        }

        try {
            const rawAPIData = await request.toPromise();
            if (!rawAPIData.response.data?.item) {
                this.routeTo404()
                console.log('error');
                throw new Error('Page not Found');
            }
            await this._cache
                .put(
                    cacheRequest,
                    new Response(JSON.stringify({ expiry: Date.now() + environment.kontentCacheTTL, data: rawAPIData.response.data }))
                )
            return rawAPIData.data.item;
        } catch (error) {
            if (retries > 0) {
                return await this.getItemAndCache(codename, elements, depth, retries - 1)
            }
            this.routeTo404()
            console.log('error');
            throw error;
        }

    }


    stringifySafe(obj: any) {
        const seen = new WeakSet();
        return JSON.stringify(obj, (key, value) => {
          if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
              // Circular reference found, discard key
              return;
            }
            seen.add(value);
          }
          return value;
        });
      }

      async getTaxonomyAndCache(codename: string,  retries = RETRIES): Promise<any> {
        const cacheRequest = `taxonomy-${codename}`;
        if (!this._cache) {
            this._cache = await caches.open(this.KONTENT_CACHE);
        }

        const match = await this._cache.match(cacheRequest);
        if (match) {
            const { expiry, data } = await match.json();
            if (expiry > Date.now()) {
                return {taxonomy: data};
            }
        }

        let request = await this._client.taxonomy(codename)

    

        try {
            const rawAPIData = await request.toPromise();
            if (!rawAPIData.response.data) {
                this.routeTo404()
                console.log('error');
                throw new Error('Page not Found');
            }
            await this._cache
                .put(
                    cacheRequest,
                    new Response(JSON.stringify({ expiry: Date.now() + environment.kontentCacheTTL, data: rawAPIData.response.data }))
                )
            return rawAPIData.data
        } catch (error) {
            if (retries > 0) {
                return await this.getTaxonomyAndCache(codename, retries - 1)
            }
            this.routeTo404()
            console.log('error');
            throw error;
        }

      }

      routeTo404(){
        // to do route to 404
      }
      

      async executeAndCache<T>(requestKey: string, executeRequest: () => Promise<T>, retries = RETRIES): Promise<T> {
        if (!this._cache) {
            this._cache = await caches.open(requestKey);
        }
    
        const match = await this._cache.match(requestKey);
        if (match) {
            const { expiry, data } = await match.json();
            if (expiry > Date.now()) {
                return data as T;
            }
        }
        try {
            const data = await executeRequest();
            await this._cache.put(
                requestKey,
                new Response(JSON.stringify({ expiry: Date.now() + environment.kontentCacheTTL, data }))
            );
            return data;

        } catch (error) {
            if (retries > 0) {
                return await this.executeAndCache(requestKey, executeRequest, retries - 1);
            }
            this.routeTo404();
            console.error('Error:', error);
            throw error;
        }
    }

}


