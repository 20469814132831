<section
  class="usa-section padding-y-8 padding-x-2 tablet:padding-x-8 desktop:padding-y-15 position-relative image-text-section display-flex flex-justify-center {{
    topTriangle
  }} {{ bottomTriangle }}  {{
    content?.elements?.['background_color']?.value || 'bg-white'
  }}"
>
  <div
    class="width-full maxw-desktop-lg display-flex flex-column text-black gap-3"
  >
    <div
      *ngIf="safeHeaderHtml"
      class="header text-white display-flex flex-column remove-margin-from-immediate-children margin-bottom-4"
      [innerHTML]="safeHeaderHtml"
    ></div>

    <div
      class="image-text-section-text-blocks text-white display-flex flex-column gap-8"
    >
      <div
        *ngFor="let section of sections; let i = index"
        class="image-text-section-block-container"
        [ngClass]="{ alternate: i % 2 === 0 && section.image }"
      >
        <!-- Title/Image Section -->
        <div
          class="image-text-section-text-block display-flex flex-column flex-justify-center flex-1 remove-margin-from-immediate-children usa-prose gap-2"
          [innerHTML]="section.body" *ngIf="section.image"
        ></div>
        <div
          class="image-text-section-image flex-1 position-relative flex-align-self-start desktop:order-initial order-first offset-border {{
            i % 2 === 0 ? 'offset-top-right' : 'offset-bottom-left'
          }}" *ngIf="section.image"
        >
          <img
            class="position-relative"
            [src]="section.image"
            [alt]="section.imageAlt"
          />
        </div>

        <!-- Title/Text Block -->

        <div class="grid-row width-full" *ngIf="section.title">
            <div class="display-flex flex-row flex-align-center padding-x-2 padding-y-2 desktop:grid-col-4 border-left-2px bg-{{section.color}} border-{{section.color}}">
                <h3 class="text-black font-lg text-semibold line-height-3">{{section.title}}</h3>
            </div>
            <div class="desktop:grid-col border-2px border-{{section.color}} usa-prose padding-y-3 padding-x-3" [innerHTML]="section.text"></div>

        </div>

      </div>
    </div>
  </div>
</section>
