export const resourceDescriptionMap: {[key:string]:string } = {
  "data_providers": "A Data Provider refers to an entity or system that is responsible for supplying or making data available to the P-20W+ data aggregation environment.<br><br>It can include various sources such as local education agencies, local workforce programs, state agencies, external data providers, data collection systems, or data feeds. Data Providers play a critical role in ensuring the availability and accessibility of data for aggregation, analysis, and reporting within the P-20W+ ecosystem.",
  "data_application": "Applications are the tools and other technologies that enable the goals of the architecture.",
  "data_quality_layer": "The processes and tools that review data for various quality standards and prompt data stewards when there are issues for review and, possibly, for resolution.",
  "business_rules_engine": "A tool that allows users to manage the quality and logical rules that must be applied to data. This includes data format validation and data integrity checks.",
  "standardized_business_rules_representation": "Representing business rules in a standardized format allows them to be run by multiple business rules engines. This allows organizations to share rules and run them at any point they need in the data pipeline.<br><br> This also enables a shared understanding of the intent of the rules across the teams that manage the work at different stages of the data lifecycle.",
  "api_standards_by_p_20w__domain": "An Application Programming Interface, or API, is a method for moving data from one application to another, sometimes enforcing some form of data standard on those data while moving them. ",
  "early_childhood": "Early Childhood encompasses the period of learning that occurs from birth to eight years old (grade 3).",
  "k12": "K12 education encompasses academic levels in primary education starting in kindergarten through secondary education ending in grade 12.",
  "edfi": "The Ed-Fi Standard facilitates data transport and storage in K-12 education. It promotes interoperability among various applications used by educational organizations. For instance, it allows integration between Student Information Systems, assessment systems, and learning management systems. This secure data movement enhances classroom decision-making and student outcomes.",
  "a4l_sif": "A4L, also known as the Schools Interoperability Framework (SIF) data standard, is a community-built data transport standard for K-12 education. It consists of two main components: infrastructure (defining transport and messaging) and a data model (defining payload formats). A4L supports administrative tasks in schools, such as adding new students to the Student Information System (SIS) and integrating data across various technical applications. It operates as a REST API and is platform-independent.",
  "edtech": "1EdTech establishes standards that help ensure a trusted digital ecosystem. Its community-developed and maintained standards help various applications integrate — or work with one another — and then transport student data between those applications as they are used in the teaching and learning environment. 1EdTech standards also help to move and integrate data into an organization’s operational data infrastructure and or data warehousing and reporting infrastructure. Each 1EdTech standard is designed to help facilitate a step in the transportation and integration processes a given organization might need to connect the many applications it uses, from the classroom to the administrative office.",
  "postsecondary": "Postsecondary education refers to the range of formal learning opportunities beyond high school, including those aimed at learning an occupation or earning an academic credential.",
  "pesc": "PESC-approved standards are freely available for education, workforce, health, military, and government communities. Developed collaboratively through an open process, these standards are platform-neutral, supporting various technologies like EDI, JSON, PDF, and XML. They enhance data quality and integrity across different systems.",
  "workforce": "Workforce encompasses the workers engaged in a specific field of work.",
  "adult_education": "Adult Education encompasses educational programs or courses for adults who are out of school or college.",
  "federated_authentication": "Federated authentication is a technology that allows users to access multiple tools, apps, and domains with only one set of credentials. This would allow state users to leverage their existing work account via authorization providers such as Google, Microsoft, and AWS.",
  "authorization": "Authorization procedures ensure users only have access to the data and functionality they should. It is highly recommended applying authorization controls as close to the data-layer as possible so that authorization can be managed centrally rather than within the application-layer. Leverage security best practices, such as Attribute-Based Access Control (ABAC), as recommended by NIST.",
  "data_privacy_obligations": "A standards agnostic machine-readable document that contains all data privacy requirements including contract clauses, privacy obligations, technical control benchmarks, and other control metadata.",
  "data_aggregator": "A Data Aggregator is an entity or component within the P-20W+ data ecosystem that is responsible for collecting, managing, and processing data from multiple sources. \n The Data Aggregator performs activities such as data ingestion, data integration, data quality assessment, and data transformation to create a unified and consistent dataset. It acts as a central hub for aggregating and consolidating data from different providers, ensuring data accuracy, completeness, and interoperability.",
  "data_lineage_and_auditing": "Knowing the genesis of a data collection (e.g., begun as a means to meet a requirement laid out in a statute, rule), how data are defined, where data were reported from/originated and when they were reported are core parts of understanding what they can and cannot be used to do.<br><br> Along with this information, knowing why and how they may have been changed and by whom, are core ways of maintaining both data integrity, and for appropriately reflecting the roles and responsibilities of data providers and data aggregators.",
  "data_dictionary_and_metadata": "Data dictionaries and metadata play a crucial role in promoting data transparency within organizations.<br><br>A data dictionary serves as a comprehensive catalog that documents the meaning, structure, and characteristics of data elements, while metadata provides additional context and information about the data.<br><br> By establishing robust data dictionaries and metadata management practices, organizations can achieve greater transparency by enabling stakeholders to understand, interpret, and trust the data with which they work.<br><br> Data dictionaries provide a common language for describing data elements and their relationships, ensuring consistency and clarity across the organization.<br><br> Metadata, on the other hand, captures essential information about data sources, transformations, and quality, helping users assess the reliability and suitability of the data for their specific needs.",
  "longitudinal_identity_resolution": "Refers to the processes required to ensure that individual records, whether for people or for organizations, can accommodate change over time (e.g., name changes) while retaining the appropriate connections to other data related to that individual record.",
  "landing": "All organizations need a place for data to “land” when they are coming in from the providers who give their data to the aggregator, kind of like an airport. This could be a data lake or an RDBMS in the case of this Reference Architecture.",
  "storage_types": "Data storage refers to the use of recording media  that records and preserves digital information for ongoing or future operations. There are several types of data storage, each serving different purposes.",
  "archived_storage": "Most organizations are required to archive records on some form of records retention schedule.",
  "operational": "Organizations need a place where daily, ongoing work can happen on the data that are arriving in their environment and that they require to do the work of running the organization and or programs the organization oversees.<br><br> This is somewhat like a transitional space that enables daily functions, data processing, and cleaning before a snapshot of those cleaned data can advance in the data pipeline.",
  "standardized": "This area represents a space where the data coming from the providers will be integrated such that they will conform to a given data standard or set of standards that can work together.",
  "curated": "Organizations often require data that are ready to use for official analysis and reporting processes (e.g., setting tax rates, reporting to the Federal government, reporting to the public).<br><br> This means they require sets of data that are ready for such uses, are unlikely to change, and are well-documented so they can be fully understood by their users.",
  "data_retention": "Data retention is a critical aspect of data management that involves defining and implementing policies for the storage and preservation of data over time.<br><br> It encompasses determining the appropriate duration for retaining data based on legal, regulatory, and business requirements. Data retention plays a vital role in ensuring compliance, mitigating risks, and supporting effective data governance practices.<br><br>By establishing clear guidelines for data retention, organizations can strike a balance between retaining data for necessary purposes such as compliance or historical analysis while minimizing the storage of unnecessary or outdated data. It is essential to consider factors such as data sensitivity, security, privacy, and storage costs when defining data retention policies.<br><br> Thoughtful and well-executed data retention strategies contribute to efficient data management, facilitate timely access to relevant information, and promote responsible data stewardship in the P-20W+ landscape.",
  "organize_govern_classify": "Organize/Govern/Classify includes the process of structuring, categorizing, and managing data in a systematic and meaningful manner to facilitate their efficient storage, retrieval, and analysis.<br><br> It involves various activities, including metadata management, tagging, and classification, as well as perhaps partitioning or otherwise governing data.<br><br> Metadata activities play a crucial role in data organization by providing descriptive information about the data, its characteristics, and its context.",
  "ceds_data_warehouse": "The Common Education Data Standards (CEDS) Data Warehouse is an open source model for storing P-20W+ (early childhood through workforce) data longitudinally for a variety of uses, including state and federal accountability reporting, policymaking, program evaluation, and research. Built on CEDS’s standardized schema of data element names and definitions, the CEDS Data Warehouse allows states to integrate data from source systems in numerous state and local agencies and programs. The warehouse model is informed, developed, and supported by the CEDS Open Source Community (OSC), a group of state data system managers, CEDS technical assistance experts, and stakeholders.",
  "validation_level": "Data Validation is the process of ensuring data has undergone data cleansing to confirm they have data quality, that is, that they are both correct and useful.",
  "landing_schema_level": "All organizations need a place for data to “land” when they are coming in from the providers who give their data to the aggregator, kind of like an airport. This could be a data lake or an RDBMS in the case of this Reference Architecture.",
  "operational_unit_level_comparison": "Organizations need a place where daily, ongoing work can happen on the data that are arriving in their environment and that they require to do the work of running the organization and or programs the organization oversees.<br><br> This is somewhat like a transitional space that enables daily functions, data processing, and cleaning before a snapshot of those cleaned data can advance in the data pipeline.",
  "standardized_statistical_and_longitudinal_analysis": "This area represents a space where the data coming from the providers will be integrated such that they will conform to a given data standard or set of standards that can work together.",
  "curated_business_rule_specific": "Organizations often require data that are ready to use for official analysis and reporting processes (e.g., setting tax rates, reporting to the Federal government, reporting to the public).<br><br> This means they require sets of data that are ready for such uses, are unlikely to change, and are well-documented so they can be fully understood by their users.",
  "data_consumer": "A data consumer refers to any user, application, or system that utilizes data collected by another system or stored in a data repository.",
  "federal_reporting": "State level organizations are often required to report certain data sets and calculations to the Federal government as part of implementing laws, rules, and or guidelines.",
  "reporting_analytics": "Organizations often report data internally and externally. These products may or may not be in response to laws, rules, or regulations.",
  "operational_systems": "Organizations often require different spaces and tools in their systems to maintain their operations than those they might need for conducting data science, analysis, or other types of reporting.<br><br> Operational systems usually need the freshest data available as they are designed to support the everyday management of the institution and its programs.",
  "consumer_application": "Sometimes, external organizations might need data to run an application they have built.<br><br> In the case of this Reference Architecture, this consumer application would be relying on the official, standardized, curated, and ready-for-release data, as opposed to operational data that might be changing from day to day as a part of program administration and or cleaning and validation processes.",
  "data_sharing": "Often, organizations need to share data to support public data sharing requests and other organizations in performing their responsibilities for program management or required reporting.<br><br> Sometimes organizations might share data to perform analysis or research they have an agreement with an external institution to conduct.",
  "aggregator_data_quality_layer": "The processes and tools that review data for various quality standards and prompt data stewards when there are issues for review and, possibly, for resolution.",
  "quality_business_rules_engine": "A tool that allows users to manage the quality and logical rules that must be applied to data. This includes data format validation and data integrity checks.",
  "quality_standardized_business_rules_representation": "A tool that allows users to manage the quality and logical rules that must be applied to data. This includes data format validation and data integrity checks."
  }