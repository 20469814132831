import { Pipe, PipeTransform } from '@angular/core';
import { IContentItem } from '@kontent-ai/delivery-sdk';

@Pipe({
  name: 'resourceStages'
})
export class ResourceStagesPipe implements PipeTransform {

  transform(resources: IContentItem[] | IContentItem): string[] {
    const stages = new Set<string>()


    if(Array.isArray(resources)){

      for(const resource of resources){
     
        resource.elements['stages'].value.forEach((stage: any) =>  stages.add(stage.name))
  
      }


    }else{

      resources.elements['stages'].value.forEach((stage: any) =>  stages.add(stage.name))
    }




    return [...stages]
  }

}
