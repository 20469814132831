import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItems } from 'src/app/core/content-utilities';
import { TriangleComponent } from 'src/app/interface/TriangleComponent';

@Component({
  selector: 'app-image-text-section',
  templateUrl: './image-text-section.component.html',
  styleUrls: ['./image-text-section.component.scss']
})
export class ImageTextSectionComponent extends TriangleComponent implements OnInit {
  safeHeaderHtml: SafeHtml | null = null;

  public sections: any[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    ele: ElementRef
    ) { 
      super(ele);
    }

  override ngOnInit(): void {
    if (!this.content) return;

    if (this.content.elements['header'].value) {
      const unsafeHtml = this.content.elements['header'].value;
      this.safeHeaderHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);
    }

    const sections = getLinkedItems(this.content, 'sections');

    this.sections = sections.map((section) => {
      if (section.system.type === 'component___title_text_block')
        return {
          title: section.elements['title'].value,
          text: section.elements['text'].value,
          color: section.elements['color'].value
        };
      return {
        image: section.elements['image'].value[0].url,
        imageAlt: section.elements['image'].value[0].description,
        body: this.sanitizer.bypassSecurityTrustHtml(section?.elements?.['body']?.value || '')
      };
    });

    super.ngOnInit();
  }

  public flexDirection(i: number){
    let bool;
    if(this.content!.elements['alternate_type'].value[0] === 'even'){
      bool = i % 2 === 0;
    }else{
      bool = i % 2 !== 0;
    }
    return bool ? 'row' : 'row-reverse'
  }


}
