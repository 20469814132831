import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';

@Component({
  selector: 'app-data-arch-model',
  templateUrl: './data-arch-model.component.html',
  styleUrls: ['./data-arch-model.component.scss'],
})
export class DataArchModelComponent implements OnInit {
  hovered_section: string = '';

  @Input() content: IContentItem | null = null;
  title: SafeHtml | null = null;
  subtitle: SafeHtml | null = null;

  ngOnInit(): void {
    this.title = this.content?.elements['title'].value;
    this.subtitle = this.content?.elements['subtitle'].value;
    // console.log(this.content);
  }

  baseSlug = `/reference-architecture-resources?view=`;
  // Data "model" to test tab focus order

  dataProvider = {
    name: 'Data Providers',
    url: `${this.baseSlug}data_providers`,
    children: [
      {
        name: 'Data Application',
        url: `${this.baseSlug}data_application`,
        children: [
          {
            name: 'Data Quality Layer',
            url: `${this.baseSlug}data_quality_layer`,
            children: [
              {
                name: 'Business Rules Engine',
                url: `${this.baseSlug}business_rules_engine`,
              },
              {
                name: 'Standardized Business Rules Representation',
                url: `${this.baseSlug}standardized_business_rules_representation`,
              },
            ],
          },
        ],
      },
    ],
  };

  APIStandards = {
    name: 'API Standards By P-20W+ Domain',
    url: `${this.baseSlug}api_standards_by_p_20w__domain`,
    children: [
      {
        name: 'Early Childhood',
        url: `${this.baseSlug}early_childhood`,
      },
      {
        name: 'K12',
        url: `${this.baseSlug}k12`,
        children: [
          {
            name: 'EdFi',
            url: `${this.baseSlug}edfi`,
          },
          {
            name: 'A4L/SIF',
            url: `${this.baseSlug}a4l_sif`,
          },
          {
            name: '1EdTech',
            url: `${this.baseSlug}edtech`,
          },
        ],
      },
      {
        name: 'Postsecondary',
        url: `${this.baseSlug}postsecondary`,
        children: [
          {
            name: 'PESC',
            url: `${this.baseSlug}pesc`,
          },
        ],
      },
      {
        name: 'Workforce',
        url: `${this.baseSlug}workforce`,
      },
      {
        name: 'Adult Education',
        url: `${this.baseSlug}adult_education`,
      },
    ],
  };

  FederatedAuthentication = {
    name: 'Federated Authentication',
    url: `${this.baseSlug}federated_authentication`,
  };

  Authorization = {
    name: 'Authorization',
    url: `${this.baseSlug}authorization`,
  };

  DataPrivacyObligations = {
    name: 'Data Privacy Obligations',
    url: `${this.baseSlug}data_privacy_obligations`,
  };

  DataAggregator = {
    name: 'Data Aggregator',
    url: `${this.baseSlug}data_aggregator`,
    children: [
      {
        name: 'Data Lineage and Auditing',
        url: `${this.baseSlug}data_lineage_and_auditing`,
      },
      {
        name: 'Data Dictionary and Metadata',
        url: `${this.baseSlug}data_dictionary_and_metadata`,
      },
      {
        name: 'Longitudinal Identity Resolution',
        url: `${this.baseSlug}longitudinal_identity_resolution`,
      },
      {
        name: 'Landing',
        url: `${this.baseSlug}landing`,
      },
      {
        name: 'CEDS Data Warehouse',
        url: `${this.baseSlug}ceds_data_warehouse`,
      },
      {
        name: 'Storage Types',
        url: `${this.baseSlug}storage_types`,
        children: [
          {
            name: 'Archived Storage',
            url: `${this.baseSlug}archived_storage`,
          },
          {
            name: 'Operational',
            url: `${this.baseSlug}operational`,
          },
          {
            name: 'Standardized',
            url: `${this.baseSlug}standardized`,
          },
          {
            name: 'Curated',
            url: `${this.baseSlug}curated`,
          },
          {
            name: 'Data Retention',
            url: `${this.baseSlug}data_retention`,
          },
          {
            name: 'Organize/Govern/Classify',
            url: `${this.baseSlug}organize_govern_classify`,
          },
        ],
      },
      {
        name: 'Validation Level',
        url: `${this.baseSlug}validation_level`,
        children: [
          [
            {
              name: 'Landing Schema Level',
              url: `${this.baseSlug}landing_schema_level`,
            },
            {
              name: 'Operational Unit Level Comparison',
              url: `${this.baseSlug}operational_unit_level_comparison`,
            },
            {
              name: 'Standardized Statistical and Longitudinal Analysis',
              url: `${this.baseSlug}standardized_statistical_and_longitudinal_analysis`,
            },
            {
              name: 'Curated Business Rule Specific',
              url: `${this.baseSlug}curated_business_rule_specific`,
            },
          ]
        ]
      },
      {
        name: 'Data Quality Layer',
        url: `${this.baseSlug}data_quality_layer`,
        children: [
          {
            name: 'Business Rules Engine',
            url: `${this.baseSlug}business_rules_engine`,
          },
          {
            name: 'Standardized Business Rules Representation',
            url: `${this.baseSlug}standardized_business_rules_representation`,
          },
        ],
      },
    ],
  };

  DataConsumers = {
    name: 'Data Consumers',
    url: `${this.baseSlug}data_consumer`,
    children: [
      {
        name: 'Federal Reporting',
        url: `${this.baseSlug}federal_reporting`,
      },
      {
        name: 'Reporting/Analytics',
        url: `${this.baseSlug}reporting_analytics`,
      },
      {
        name: 'Operational Systems',
        url: `${this.baseSlug}operational_systems`,
      },
      {
        name: 'Consumer Application',
        url: `${this.baseSlug}consumer_application`,
      },
      {
        name: 'Data Sharing',
        url: `${this.baseSlug}data_sharing`,
      },
    ],
  };

  // RA Component

  // L1: Data Providers

  // L2: Data Application

  // L3: Data Quality Layer

  // L4: Business Rules Engine

  // L4: Standardized Business Rules Representation

  // L1: API Standards By P-20W+ Domain

  // L2: Early Childhood

  // L2: K12

  // L3: EdFi

  // L3: A4L/SIF

  // L3: 1EdTech (In the figma this is misspelled as OneEdTech)

  // L2: Postsecondary

  // L3: PESC

  // L2: Workforce

  // L2: Adult Education

  // L1: Federated Authentication

  // L1: Authorization

  // L1: Data Privacy Obligations

  // L1: Data Aggregator

  // L2: Data Lineage and Auditing

  // L2: Data Dictionary and Metadata

  // L2: Longitudinal Identity Resolution

  // L2: Landing

  // L2: Storage Types

  // L3: Archived Storage

  // L3: Operational

  // L3: Standardized

  // L3: Curated

  // L3: Data Retention

  // L3: Organize/Govern/Classify

  // L3: CEDS Data Warehouse

  // L2: Validation Level

  // L3: Landing Schema Level

  // L3: Operational Unit Level Comparison

  // L3: Standardized Statistical and Longitudinal Analysis

  // L3: Curated Business Rule Specific

  // L2: Data Quality Layer

  // L3: Business Rules Engine

  // L3: Standardized Business Rules Representation

  // L1: Data Consumer

  // L2: Federal Reporting

  // L2: Reporting/Analytics

  // L2: Operational Systems

  // L2: Consumer Application

  // L2: Data Sharing
}
