import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, IsActiveMatchOptions, Router } from '@angular/router';
import { IContentItem, IContentItemElements } from '@kontent-ai/delivery-sdk';
import { Observable, delay, filter, map, of, switchMap, throwError } from 'rxjs';
import { getLinkedItems } from 'src/app/core/content-utilities';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';

@Component({
  selector: 'app-journey-step-view',
  templateUrl: './journey-step-view.component.html',
  styleUrls: ['./journey-step-view.component.scss']
})
export class JourneyStepViewComponent implements OnInit {

  routerLinkActiveOptions: IsActiveMatchOptions = {
    matrixParams: 'ignored',
    queryParams: 'ignored',
    fragment: 'ignored',
    paths: 'subset'
  };
  Math = Math
  public  ITEMS_PER_PAGE = window.localStorage.getItem('COI-ItemsPerPage') ? parseInt(window.localStorage.getItem('COI-ItemsPerPage') || '12') : 12;
  @Input() content: IContentItem | null = null;
  public page: number = 1;
  public maxPages = 1;
  public steps: IContentItem[] = [];
  public search: string = ''
  public type: string = '';
  public group: string = '';
  public stageOrder: string[] = [];
  public orderedGroups: string[] = [];

  @HostBinding('attr.style')
  public get cssVars() {
    return this.steps.map((item, idx) => `--body-color-${idx +1}: ${item.elements['base_color'].value || 'white'};`).join("\n")
  }

  public step = this.route.params.pipe(
    delay(1),
    filter((params) => this.steps.findIndex((item) => item.system.codename === params['extra']) !== -1),
    switchMap((params) => {
      const extra = params['extra'];
      const idx = this.steps.findIndex((item) => item.system.codename === extra);
      return this.route.queryParams.pipe(map(query => {
        this.type = query['type'] || '';
        this.search = query['search'] || '';
        this.group = query['group'] || '';
        this.page = parseInt(query['page']) || 1;

        this.ITEMS_PER_PAGE = parseInt(query['perPage']) || this.ITEMS_PER_PAGE;

        this.filterForm.patchValue({type: this.type, group: this.group});
    
        const resources = this.kontent.client.items().type('coi___resource').containsFilter('elements.journey_step', [this.steps[idx].system.codename])
          .toAllPromise()
          .then((result) => (
            {
              total: result.data.items.length, 
              all: result.data.items, 
              result: result.data.items.filter((item) => 
                (!this.search?.length 
                  || this.compareElement(item, 'name', this.search)
                  || this.compareElement(item, 'short_description', this.search)
                  || this.compareElement(item, 'author', this.search)) 
                && 
                (this.type === '' || item.elements['format'].value.some((format: any) => format.codename === this.type)) && 
                (this.group === '' || item.elements['user_groups'].value.some((user_group: any) => user_group === this.group))
              )
            }))
          .then(({result, all, total}) => {
            
            this.maxPages = Math.ceil(result.length / this.ITEMS_PER_PAGE)
            return {total, all, items: result.slice((this.page - 1) * this.ITEMS_PER_PAGE, this.page * this.ITEMS_PER_PAGE)}
          });

          return {idx, step: this.steps[idx], resources} 
      }));
    })
  )

  public resourceTypes = this.kontent.client.taxonomy('format').toPromise().then(result => result.data.taxonomy.terms);
  public userGroups = this.kontent.client.items().type('user_group').toPromise().then(result => result.data.items);
  public filterForm: FormGroup;

  constructor(private route: ActivatedRoute, private kontent: KontentDeliveryService, private fb: FormBuilder, private router: Router) {
    this.filterForm = this.fb.group({
      'type': this.fb.control(''),  
      'group': this.fb.control(''),  
    });

    this.kontent.client.taxonomy('stages').toPromise().then(data => {
      this.stageOrder = data.data.taxonomy.terms.map(t => t.name);
    });
    this.kontent.client.items().type('user_group').toPromise().then(result => {
      this.orderedGroups = result.data.items.sort((a: any, b: any) => a.elements.sort_index.value - b.elements.sort_index.value).map(g => g.elements?.['name'].value);
    });

    this.filterForm.valueChanges.subscribe((changes) => {
      const cleaned = Object.keys(changes).reduce((accum, key) => !!changes[key] ? Object.assign(accum, {[key]: changes[key]}) : accum, {}) as any;
      let params: any = {};
      if (cleaned.group === undefined) params.group = '';
      else params.group = cleaned.group;
      if (cleaned.type === undefined) params.type = '';
      else params.type = cleaned.type;

      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: params,
        queryParamsHandling: 'merge',
      });
    });
  }

  ngOnInit(): void {
    if (!this.content) return;
    this.steps = getLinkedItems(this.content, 'journey_steps');
  }

  public onItemsPerPage() {
    window.localStorage.setItem('COI-ItemsPerPage', this.ITEMS_PER_PAGE.toString());

    this.router.navigate(['.'], {queryParams: {perPage: this.ITEMS_PER_PAGE, page: 1}, relativeTo: this.route, queryParamsHandling: 'merge'})
    .then(() => window.scrollTo(0, 0))
  }

  private compareElement(item: IContentItem, field: string, compare: string){
    return (item?.elements?.[field]?.value || '').toLowerCase().includes(compare?.toLowerCase())
  }

  public onSearch(search: string) {
    this.router.navigate(['.'], {relativeTo: this.route, queryParams: {search}, queryParamsHandling: 'merge'})
  }

  public getStages(stages: string[]): string[] {
    return this.stageOrder.filter(s => stages.includes(s));
  }
  public getGroups(groups: string []): string[] {
    return this.orderedGroups.filter(g => groups.includes(g));
  }

}

