import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItems } from 'src/app/core/content-utilities';

@Component({
  selector: 'app-featured-blog',
  templateUrl: './featured-blog.component.html',
  styleUrls: ['./featured-blog.component.scss']
})
export class FeaturedBlogComponent implements OnInit {
  @Input() content: IContentItem | null = null;
  public hasImage: boolean = false

  public categories?: string;

  constructor(){ }

  ngOnInit(): void {
    if(!this.content) return;

    this.hasImage = this.content.elements?.['image']?.value?.length > 0;
    this.categories = getLinkedItems(this.content, 'categories').map((v: any) => { return `<span style="color:${v.elements.light_color.value}; font-weight: bold;">${v.elements.name.value}</span>`}).join(`<i class="bi bi-dot"></i>`);
  }

  getDate(date: string) {
    return new Date(date).toLocaleString('en-us',{month:'long', day:'numeric', year:'numeric'});
  }

  public calculateReadingTime(body: string) {
    if (!body) {
      return 0;
    }
    const numberOfWords: number = (body.match(/\b[-?(\w+)?]+\b/gi) ?? []).length;
    const wordsPerMin = 180;
    const newReadingTime = Math.ceil(numberOfWords / wordsPerMin);

    return newReadingTime;
  }

  public clickBlog() {
    sessionStorage.setItem('clickScrollY', window.scrollY.toString());
    sessionStorage.setItem('clickScrollY-lock', 'locked');
  }
}
