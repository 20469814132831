import { IContentItem } from "@kontent-ai/delivery-sdk";
import { IContentItemWithLinked } from "../interface/IContentItemWithLinked";


/**
 * Use this to get an array of linked items from a modular content field
 * @param item content item to extract linked items from
 * @param elementName modular content field name
 * @returns IContentItem[]
 */
export function getLinkedItems(item: IContentItem, elementName: string): IContentItem[] {

    const result = item.elements[elementName] as IContentItemWithLinked;
    return result.linkedItems ?? [];

}/**
 * Use this to get a single linked item from a modular content field
 * @param item content item to extract linked items from
 * @param elementName modular content field name
 * @returns IContentItem
 */
export function getLinkedItem(item: IContentItem, elementName: string): IContentItem {
    const result = item.elements[elementName] as IContentItemWithLinked;
    return result.linkedItems[0] ?? {};

}

/**
 * Use this to create an image object from an asset field
 * @param item content item to extract image from
 * @param elementName asset field name
 * @returns url: string, description: string
 */
export function constructImage(item: IContentItem, elementName: string): { url: string, description: string } {
    const image = item.elements[elementName]?.value[0];
    return { url: image.url ?? '', description: image.description ?? '' };
}

/**
 * Use this to create a url from a content item
 * @param item link content item
 * @returns a url to the content item or a manual link destination
 */
export function constructLink(item: IContentItem): string {

    const destination = getLinkedItems(item, 'link_destination');
    const linkedItem = destination[0] ?? null;
    // We will switch on element.system.type to determine route
    let url = '';
    switch (linkedItem?.system.type) {
        case 'template_standard_page':
            // home page special case
            if (linkedItem?.elements['url_slug']?.value === '/') {
                url = '/';
                break;
            }

     
            url = `${linkedItem?.elements['url_slug']?.value}`;
            break;

        // continue with other cases
        // case 'template_other' etc

        default:
            url = '';
    }


    // manual destination can be used for external links or internal links
    const manualDestination = item.elements['manual_link_destination']?.value;
    if (manualDestination)
        return manualDestination;

    return url;
}

export function getItemSystemType(item: IContentItem): string {
    return item.system.type;
}


/**
 * Extract given elements from a Kentico Item  in a flat object format
 * @param {object} rawItemData - The raw Kontent API Item Data
 * @returns All elements in a flat object format (key: value) with the element codename as the key and modular_content Map() type 
 */
export function extractItemElements(rawItemData: any, elementNames: string[] = []) {
    if (!rawItemData?.elements) {
        return {};
    };

    const elementsToExtract = elementNames ? elementNames : Object.keys(rawItemData.elements);

    return elementsToExtract.reduce((selectedElements: any, elementName) => {
        if (elementName in rawItemData.elements) {
            const element = rawItemData.elements[elementName];
            selectedElements[elementName] = element.value;
        }
        return selectedElements;
    }, {});
}


/**
 * Get a modular content item from a modular_content Map() type
 * @param {string} itemCodename - The codename of the item to get
 * @param {Map<string, any>} modularContent - The modular_content Map() type
 * @returns The modular_content item
 */

export function getItemFromModularContent(itemCodename: string, modularContent: Map<string, any>) {
    return modularContent.get(itemCodename);
}

/**
 * Get a modular content item from a modular_content Map() type
 * @param {Map<string, any>} content - The content
 * @returns 2 length string array
 */

export function getTriangleClasses(content: IContentItem): [string, string]{
    let top = '', bottom = '';


    if(content.elements['triangles__top_enabled'].value[0].codename === 'yes'){

        top = `top-${content.elements['triangles__top_location'].value[0].codename}-triangle`
        
    }

    if(content.elements['triangles__bottom_enabled'].value[0].codename === 'yes'){

        bottom = `bottom-${content.elements['triangles__bottom_location'].value[0].codename}-triangle`
        
    }

    return [top, bottom]

}





export type ExtractedElements = {
    [key: string]: any;
    contentType: string;
    modular_content?: Map<string, ExtractedElements>;
}


