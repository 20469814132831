<section
  class="usa-section width-full padding-y-8 desktop:padding-y-15 padding-x-2 tablet:padding-x-8 display-flex flex-justify-center"
>
  <div
    class="newsletter-sign-up-content maxw-desktop display-flex width-full flex-column desktop:flex-row gap-6 flex-align-center"
  >
    <div
      role="region"
      aria-label="Email Sign Up"
      class="newsletter-sign-up-container position-relative flex-align-self-center offset-border offset-bottom-left width-full maxw-tablet"
    >
      <div
        class="newsletter-sign-up display-flex flex-column position-relative bg-white padding-2 text-base-darkest width-full height-full gap-2"
      >
        <div
          class="sign-up-header dark-text-selector"
          [innerHTML]="safeHeaderHtml"
        ></div>

        <form class="display-flex flex-column gap-1">
          <div class="display-flex flex-column dark-text-selector">
            <label class="usa-label" [for]="'email' + '-' + id">{{
              content?.elements?.["input_label"]?.value || "Email address"
            }}</label>
            <input
              class="usa-input"
              placeholder="name@emaildomain.com..."
              #email
              autocomplete="email"
              [id]="'email' + '-' + id"
              type="text"
            />
          </div>

          <div class="display-flex gap-1 flex-align-center">
            <button
                (keydown.enter)="register(email.value)"
                (click)="register(email.value)"
                class="usa-button margin-top-2 flex-align-self-start remove-margin-from-immediate-children"
                [disabled]="submitted || submitting"
            >
                {{ content?.elements?.["cta_text"]?.value || "Submit" }}
                <i
                class="ti display-flex ti-arrow-right text-normal font-lg"
                ></i>
            </button>
            <span aria-live="polite" class="margin-top-2 margin-left-2 text-primary-dark text-semibold"
                        *ngIf="submitting">{{content?.elements?.['submitting_text']?.value}}</span>
            <div *ngIf="submitted" class="flex-align-center display-flex margin-left-2">
                <i class="bi text-green font-xl margin-top-2 bi-check"></i>
                <span aria-live="polite" class="margin-top-2 text-semibold">{{content?.elements?.['success_message']?.value}}</span>
            </div>
            <div *ngIf="error" class="flex-align-center display-flex margin-left-2 gap-1">
                <i class="bi bi-exclamation-circle text-red text-bold font-md margin-top-2"></i>
                <span aria-live="polite" class="margin-top-2 text-semibold">{{content?.elements?.['error_message']?.value}}</span>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div
      class="newsletter-sign-up-body display-flex width-full flex-column gap-2"
    >
      <div
        class="body-content usa-prose display-flex flex-column gap-2"
        [innerHTML]="safeBodyHtml"
      ></div>
    </div>
  </div>
</section>
