<section
  #quoteSection
  class="usa-section width-full padding-y-8 desktop:padding-y-15 padding-x-2 tablet:padding-x-8 display-flex flex-justify-center {{
    topTriangle
  }} {{ bottomTriangle }} {{
    content?.elements?.['background_color']?.value ?? 'bg-primary-dark'
  }}"
>
  <div
    class="maxw-desktop-lg width-full display-flex flex-justify-center flex-column "
  >
    <div
      [innerHTML]="safeAttributionHtml"
      class="remove-margin-from-immediate-children maxw-tablet-lg"
    ></div>

    <a *ngIf="content?.elements?.['cta_url']?.value && content?.elements?.['cta']?.value" [href]="content?.elements?.['cta_url']?.value"  class="cta usa-button flex-align-self-start">
        <span class="remove-margin-from-immediate-children" [innerHTML]="content?.elements?.['cta']?.value"></span>
        <i class="bi display-flex text-heavy font-xl {{content?.elements?.['cta_icon']?.value || 'bi-arrow-right-short'}}"></i>
    </a>
  </div>
</section>
