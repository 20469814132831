<div class="usa-overlay" (click)="side_panel_visible = false" [ngClass]="{'is-visible': side_panel_visible}"></div>
<header class="usa-header fade-in z-500 position-fixed top-0 width-full usa-header--basic">
    <div class="usa-nav-container">
        <div class="usa-navbar">
            <div class="usa-logo">
                <a routerLink="/" title="COI Components">
                    <img [src]="logo.url" [alt]="logo.description" />
                </a>
            </div>
            <button type="button" aria-label="Menu" class="usa-menu-btn" (click)="side_panel_visible = true;closeMenu.focus()">
                <i class="ti ti-menu-2"></i>
            </button>
        </div>

        <nav aria-label="Primary navigation" class="usa-nav" [ngClass]="{'is-visible': side_panel_visible}">
            <button type="button" #closeMenu aria-label="Close" class="usa-nav__close" (click)="side_panel_visible = false">
                <i class="ti ti-x"></i>
            </button>
            <ul class="usa-nav__primary usa-accordion">
                <div *ngFor="let item of menuItems">
                    <ng-container [ngSwitch]="item.contentType">
                      <ng-container *ngSwitchCase="'component_menu'" [ngTemplateOutlet]="menu" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
                      <ng-container *ngSwitchCase="'component_link'" [ngTemplateOutlet]="link" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
                    </ng-container>
                  </div>
             
            </ul>
        </nav>
    </div>
</header>


<ng-template #menu let-item>
    <li class="usa-nav__primary-item">
        <button type="button" class="usa-accordion__button usa-nav__link"
            [attr.aria-expanded]="submenu_visible" (click)="submenu_visible = !submenu_visible" aria-controls="basic-nav-toolset">
            <span>
                {{item.displayText}}
            </span>
            <i class="ti ti-caret-down-filled"></i>
        </button>
        <ul id="basic-nav-toolset" class="usa-nav__submenu">
            <li *ngFor="let entry of item.children"  class="usa-nav__submenu-item" (click)="clickListItem($event)">
                <a [target]="entry.target" *ngIf="!entry.children.length && determineInternalLink(entry.url)" [routerLink]="entry.url">
                    <span>{{ entry.displayText }}</span>
                </a>
                <a [target]="entry.target" *ngIf="!entry.children.length && !determineInternalLink(entry.url)" [href]="entry.url">
                    <span>{{ entry.displayText }}</span>
                </a>
                <button *ngIf="entry.children.length" type="button">
                    <span id="sub-parent-menu-item">
                        {{entry.displayText}}
                    </span>
                    <i class="ti ti-caret-down-filled"></i>
                </button>
                <ul id="sub-nav-toolset" class="usa-nav__submenu">
                    <li *ngFor="let childItem of entry.children"  class="usa-nav__submenu-item" (click)="clickListItem($event)">
                        <a [target]="childItem.target" *ngIf="determineInternalLink(entry.url)" [routerLink]="childItem.url">
                            <span>{{ childItem.displayText }}</span>
                        </a>
                        <a [target]="childItem.target" *ngIf="!determineInternalLink(entry.url)" [href]="childItem.url">
                            <span>{{ childItem.displayText }}</span>
                        </a>
                    </li>
                </ul>
            </li>

        </ul>
    </li>
</ng-template>


<ng-template #link let-item>
    <li class="usa-nav__primary-item">
        <a class="usa-nav__link" *ngIf="determineInternalLink(item.url)" [target]="item.target" [routerLink]="item.url"><span>{{item.displayText}}</span></a>
        <a class="usa-nav__link" *ngIf="!determineInternalLink(item.url)" [target]="item.target" [href]="item.url"><span>{{item.displayText}}</span></a>
    </li>
</ng-template>