<section class="usa-section position-relative width-full padding-y-8 desktop:padding-y-15 padding-x-2 tablet:padding-x-8 display-flex flex-justify-center bg-library-light bottom-right-triangle text-base-darkest top-left-triangle">
    <div class="display-flex width-full maxw-desktop-lg flex-column gap-2">
        <span class="usa-prose">
            <h2>{{content?.elements?.['header']?.value || 'Featured resources'}}</h2>
        </span>
       

        <div class="featured-resource-list gap-3 display-flex flex-column desktop:flex-row width-full maxw-desktop-lg">
            <app-resource-card class="shadowed" *ngFor="let resource of relatedResources" [content]="resource"></app-resource-card>
        </div>
    </div>


</section>