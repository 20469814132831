<ng-container *ngIf="blog$ | async as blog">
    <section
    class="usa-section width-full padding-y-0 gap-4 padding-x-0 tablet:padding-x-0 "
    >
        <div class="full-width gap-8">
            <div *ngIf="blog?.elements?.['image']?.value[0]?.url" class="fade-in  flex-align-center flex-justify-center width-full maxh-tablet gap-8 minh-tablet"
            [ngStyle]="{'background': 'center / cover no-repeat url(' + blog?.elements?.['image']?.value[0]?.url + ') var(--usa-white)'}">
            </div>
            <div class="card-header display-flex flex-column desktop:flex-justify desktop:maxw-tablet"
                [ngClass]="{'card-header-no-image': blog?.elements?.['image']?.value[0] === undefined}">
                <div class="eyebrow desktop:width-tablet padding-2 mobile:maxw-mobile">
                    <span class="date-field padding-1">{{ getDate(blog.elements['date'].value) }}</span>
                    <span class="padding-top-1"><i aria-hidden="true" class="bi text-white bi-clock"></i> {{calculateReadingTime(content?.elements?.['body']?.value)}} min read</span>
                </div>
                <div class="blog-title padding-x-4">
                    <h1>{{ blog.elements['title'].value }}</h1>
                </div>
                <div class="subtext desktop:width-tablet padding-2">
                    <span *ngIf="categories" class="categories" [innerHtml]="categories | safeHtml"></span>
                    <span class="author-name" [ngClass]="{'author-name-no-image': blog?.elements?.['image']?.value[0] === undefined}">by <span class="text-bold">
                        {{ blog.elements['author'].value }}
                    </span></span>
                </div>
            </div>
            <div class="blog-body display-flex flex-column desktop:flex-justify maxw-tablet" [innerHtml]="blog.elements['body'].value"
            [ngClass]="{'blog-body-no-image': blog?.elements?.['image']?.value[0] === undefined}">
            </div>

        </div>
        
        
    </section>
</ng-container>