import { Component, Input, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, RouteReuseStrategy } from '@angular/router';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { from, map, switchMap } from 'rxjs';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';

@Component({
  selector: 'app-view-resource',
  templateUrl: './view-resource.component.html',
  styleUrls: ['./view-resource.component.scss']
})
export class ViewResourceComponent implements OnDestroy {
  @Input() content: IContentItem | null = null;


  public resource$ = this.route.params.pipe(switchMap((params => {


    return this.route.queryParams.pipe(switchMap(query => {

      const res = this.kontent.client.items()
      .type('coi___resource')
      .equalsFilter('elements.slug', params['extra'])
      .toPromise()
      .then(val => val.data.items[0])
      // console.log(res)
      return from(res);
    }))


  })))

  constructor(private route: ActivatedRoute, private kontent: KontentDeliveryService, private location: Location){

  }

  goBack(): void {
    this.location.back();
  }

  async recordDownload(resource: any) {
    this.kontent.recordUserAction({ type: `resourceDownload`, name: resource.name }).subscribe();
  }

  ngOnDestroy(): void {
      sessionStorage.removeItem('clickScrollY-lock');
  }
}
