import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItem, getLinkedItems } from 'src/app/core/content-utilities';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss'],
//   encapsulation: ViewEncapsulation.None
})
export class BlogCardComponent implements OnInit {

  @Input() content?: IContentItem;

  public categories?: string;

  ngOnInit(): void {
    if(!this.content) return;

    this.categories = getLinkedItems(this.content, 'categories').map((v: any) => { return `<span style="color:${v.elements.light_color.value}; font-weight: bold;">${v.elements.name.value}</span>`}).join(`<i class="bi bi-dot"></i>`);
  }

  public clickBlog() {
    sessionStorage.setItem('clickScrollY', window.scrollY.toString());
    sessionStorage.setItem('clickScrollY-lock', 'locked');
  }

  getDate(date: string) {
    return new Date(date).toLocaleString('en-us',{month:'long', day:'numeric', year:'numeric'});
  }

  public calculateReadingTime(body: string) {
    if (!body) {
      return 0;
    }
    const numberOfWords: number = (body.match(/\b[-?(\w+)?]+\b/gi) ?? []).length;
    const wordsPerMin = 180;
    const newReadingTime = Math.ceil(numberOfWords / wordsPerMin);

    return newReadingTime;
  }
}
