<section id="resources"
    class="usa-section fade-in grid-row padding-y-8 desktop:padding-y-15 gap-4  padding-x-2 tablet:padding-x-8">
    <div
        class="resource-library-filters width-full minh-full position-relative maxw-card-lg desktop:display-flex display-none flex-column gap-3">
        <div
            class="resource-library-filters-header remove-margin-from-immediate-children display-flex flex-column gap-1">
            <h2 class="font-lg text-semibold line-height-3">Filter by</h2>
            <p class="font-xs text-normal line-height-3">
                Find what you need with a few quick refinements to the filter options below.
            </p>
        </div>



        <form class="display-flex position-sticky flex-column gap-2" [formGroup]="filterFormGroup">
            <button class="usa-button flex-align-self-start" (click)="resetFilters()">Reset Filters</button>
            <div class="resource-library-category-filter margin-top-05">
                <fieldset class="usa-fieldset ">
                    <legend class="text-primary-light margin-bottom-1 text-medium font-md">Category</legend>
                    <div *ngFor="let item of content?.elements?.['categories']?.value" tabindex="0"
                        (keydown.enter)="clickCategory(item)" class="usa-radio">
                        <input #category formControlName="category" tabindex="-1" class="usa-radio__input" [id]="item"
                            type="radio" [value]="item" (click)="clickCategory(item)" />
                        <label class="usa-radio__label text-white" [for]="item">{{item.replace("_", " ") |
                            titlecase}}</label>
                    </div>

                </fieldset>
            </div>

            <div class="resource-library-format-filter">
                <fieldset class="usa-fieldset">
                    <legend class="text-primary-light margin-bottom-05 text-medium font-md">Format</legend>
                    <div *ngFor="let item of content?.elements?.['formats']?.value" tabindex="0"
                        (keydown.enter)="clickFormat(item.codename)" class="usa-radio">
                        <input #format class="usa-radio__input" tabindex="-1" [id]="item.codename" type="radio"
                            formControlName="format" [value]="item.codename" (click)="clickFormat(item.codename)" />
                        <label class="usa-radio__label text-white" [for]="item.codename">{{item.name |
                            titlecase}}</label>
                    </div>

                </fieldset>
            </div>

            <div class="resource-library-complexity-filter">
                <fieldset class="usa-fieldset">
                    <legend class="text-primary-light margin-bottom-05 text-medium font-md">Complexity</legend>
                    <div *ngFor="let item of content?.elements?.['complexites']?.value" tabindex="0"
                        (keydown.enter)="clickComplexity(item.codename)" class="usa-radio">
                        <input #complexity tabindex="-1" class="usa-radio__input" [id]="item.codename" type="radio"
                            formControlName="complexity" [value]="item.codename"
                            (click)="clickComplexity(item.codename)" />
                        <label class="usa-radio__label text-white" [for]="item.codename">{{item.name |
                            titlecase}}</label>
                    </div>

                </fieldset>
            </div>
        </form>
    </div>
    <div class="resource-library-list display-flex maxw-widescreen flex-column gap-4 flex-1">
        <div role="search"
            class="resource-library-list-search width-full display-flex tablet:flex-row margin-0 flex-column gap-2 border-top-2px border-bottom-2px border-base-dark flex-align-center padding-y-05">
            <div class="search-box padding-left-2 display-flex flex-1 desktop:maxw-mobile-lg gap-1 flex-align-center">
                <input #src [value]="search" type="text" (keyup.enter)="onSearch(src.value)"
                    aria-label="Search for Resources" id="search-box"
                    class="usa-input margin-0 text-white width-full border-0" placeholder="Search resources...">
                <div class="">
                    <button (click)="onSearch(src.value)" class="desktop:display-flex display-none usa-button "> <i
                            class="bi bi-search"></i> Search</button>
                </div>
            </div>

            <div class="sort-box display-flex flex-1 padding-x-2 flex-align-center gap-1">

                <label for="sort-select" class="margin-left-auto desktop:display-flex display-none">Sort by:</label>


                <select [(ngModel)]="sort" (ngModelChange)="onSort(sort)" id="sort-select"
                    class="usa-select bg-transparent text-white margin-0  maxw-card">
                    <option class="text-black" value="recent">Most Recent</option>
                    <option class="text-black" value="atoz">A to Z</option>
                    <option class="text-black" value="ztoa">Z to A</option>
                </select>
            </div>
        </div>

        <ng-container *ngIf="library | async as library">
            <div class="resources-grid">
                <app-resource-card *ngFor="let resource of library.resources" [content]="resource"></app-resource-card>
            </div>

            <div
                class="resources-pagination border-top-2px border-base-dark display-flex width-full flex-column padding-top-2 padding-x-2 gap-2 tablet:padding-left-0 desktop:padding-right-0 flex-align-center">
                <div class="display-flex width-full flex-column flex-justify-center tablet:flex-row gap-2">
                    <!-- (page - 1) * this.ITEMS_PER_PAGE, page * this.ITEMS_PER_PAGE -->
                    <div aria-live="polite" aria-atomic="true"
                        class="showing display-flex width-full flex-align-center">
                        <p class="font-2xs margin-y-2 margin-x-auto tablet:margin-x-0">
                            Showing {{library.resources.length > 0 ? ((page - 1) * this.ITEMS_PER_PAGE) + 1 : 0}} -
                            {{Math.min(library.total, page * this.ITEMS_PER_PAGE)}} of <strong>{{library.total }}
                                items</strong></p>
                    </div>
                    <div class="items-per-page width-full display-flex flex-align-center gap-1">
                        <div
                                class="display-flex gap-1 tablet:margin-left-auto flex-align-center tablet:margin-right-0 margin-x-auto">
                                <select [(ngModel)]="ITEMS_PER_PAGE" (ngModelChange)="onItemsPerPage()" id="per-page"
                                    class="usa-select bg-transparent  text-white margin-y-0 width-auto padding-left-1">
                                    <option [value]="12">12</option>
                                    <option [value]="24">24</option>
                                    <option [value]="36">36</option>
                                </select>
                                <label for="per-page" class="font-2xs">Items per page</label>
                            </div>
                    </div>
                </div>


                <div class="paginator width-full">
                    <app-pagination [page]="page" [maxPages]="maxPages"></app-pagination>
                </div>
<!-- 
    
                <div class="paginator desktop:display-none display-flex ">
                    <app-pagination [page]="page" [maxPages]="maxPages"></app-pagination>
                </div> -->

            </div>

        </ng-container>
    </div>
</section>