<section class="cta-hero fade-in dark-text-selector usa-section width-full padding-y-8 desktop:padding-y-15 padding-x-2 tablet:padding-x-8 display-flex flex-justify-center bg-white text-base-darkest" [ngStyle]="{'background': 'center / cover no-repeat url(' + content?.elements?.['background']?.value[0]?.url + ') var(--usa-white) ' }">
    <div class="display-flex width-full maxw-desktop-lg flex-column gap-4">
        <div class="cta-hero-content display-flex flex-justify-center flex-column gap-1" [innerHTML]="safeHtml">

        </div>



        <a *ngIf="content?.elements?.['cta_url']?.value && content?.elements?.['cta']?.value" [href]="content?.elements?.['cta_url']?.value"  class="cta usa-button flex-align-self-start">
            <span class="remove-margin-from-immediate-children" [innerHTML]="content?.elements?.['cta']?.value"></span>
            <i class="bi display-flex text-heavy font-xl {{content?.elements?.['cta_icon']?.value || 'bi-arrow-right-short'}}"></i>
        </a>



    </div>
</section>