import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItems } from 'src/app/core/content-utilities';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';

@Component({
  selector: 'app-journey-steps',
  templateUrl: './journey-steps.component.html',
  styleUrls: ['./journey-steps.component.scss']
})
export class JourneyStepsComponent implements OnInit {

  @Input() content?: IContentItem;

  public steps: IContentItem[] = []

  public resourceCounts: number[] = [];

  public previousColor = 'black'



  @HostBinding('attr.style')
  public get cssVars() {
    // return `
    //   --left-tri-color: ${this.leftTriColor};
    //   --right-tri-color: ${this.rightTriColor};
    // `;

    return this.steps.map((item, idx) => `--body-color-${idx +1}: ${item.elements['base_color'].value || 'white'};`).join("\n")
  }

  constructor(
    private ele: ElementRef,
    private kontent: KontentDeliveryService
    ) { 

      setTimeout(async () => {

        const prev = await this._getSibling();

        if(prev){
          const prevComputed = getComputedStyle(prev!.firstChild! as Element)
          this.previousColor = prevComputed.backgroundColor;
        }


      } , 100)

    }


  public generateGradient(color1: string, color2: string){
    return `linear-gradient(180deg, ${color1} 20%, ${color2} 55%) 30`
  }

  async ngOnInit() {
    if(!this.content) return;
      
    this.steps = getLinkedItems(this.content, 'available_steps')


    this.resourceCounts = await Promise.all(this.steps.map((step) => this.kontent.client.items().type('coi___resource').containsFilter('elements.journey_step', [step.system.codename]).toAllPromise().then(result => result.data.items.length)))


  }

  private async _getSibling(what : 'previousElementSibling' | 'nextElementSibling' = 'previousElementSibling'){
    await new Promise((res, rej) => setTimeout(res))
    let cursor = (this.ele.nativeElement as HTMLDivElement)[what];

    return cursor;
  }



}
