import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItem, getLinkedItems } from 'src/app/core/content-utilities';

@Component({
  selector: 'app-resource-card',
  templateUrl: './resource-card.component.html',
  styleUrls: ['./resource-card.component.scss']
})
export class ResourceCardComponent implements OnInit {

  @Input() content?: IContentItem;

  public stages?: [];
  public userGroups?:  IContentItem[];
  public standard?: IContentItem[];
  public journeyStep?: IContentItem;

  @HostBinding('attr.style')
  public get cssVars() {
    return `
      --base-category-color: ${this.journeyStep?.elements?.['base_color'].value || `var(--usa-primary)`};
      --light-category-color: ${this.journeyStep?.elements?.['light_color'].value || `var(--usa-primary)`};
    `;
  }


  ngOnInit(): void {
    if(!this.content) return;

    this.standard = getLinkedItems(this.content, 'standard');
    this.journeyStep = getLinkedItem(this.content, 'journey_step');
    this.stages = this.content.elements['stages'].value.map((s:any) => s.name);
    this.userGroups = getLinkedItems(this.content, 'user_groups').map(ug => ug.elements?.['name']?.value);
  }

  public clickResource() {
    sessionStorage.setItem('clickScrollY', window.scrollY.toString());
    sessionStorage.setItem('clickScrollY-lock', 'locked');
  }
}
