export const resourceDescriptionMap: {[key:string]:string } = {
  data_providers: "A Data Provider refers to an entity or system that is responsible for supplying or making data available to the P-20W+ data aggregation environment.<br><br>It can include various sources such as local education agencies, local workforce programs, state agencies, external data providers, data collection systems, or data feeds, etc. Data Providers play a critical role in ensuring the availability and accessibility of data for aggregation, analysis, and reporting within the P-20W+ ecosystem.",
  data_aggregators: "A Data Aggregator is an entity or component within the P-20W+ data ecosystem that is responsible for collecting, managing, and processing data from multiple sources.<br><br>The Data Aggregator performs activities such as data ingestion, data integration, data quality assessment, and data transformation to create a unified and consistent dataset. It acts as a central hub for aggregating and consolidating data from different providers, ensuring data accuracy, completeness, and interoperability",
  data_consumers: "A Data Consumer refers to an entity or system that leverages the data available within the P-20W+ data environment for various purposes.",
  provision: "This swim lane represents the capabilities of the data providers in submitting or making data available to the P-20W+ data environment. It involves activities such as establishing data connectivity, using defined data access mechanisms, and ensuring the timely and accurate delivery of data to the aggregation environment.",
  provide: "This capability involves the process of supplying relevant data to the P-20W+ data environment. It includes collecting and submitting data from various sources, ensuring accuracy, completeness, and timeliness.",
  data_quality___improve: "This capability focuses on enhancing the overall quality of the data collected within the P-20W+ data environment. It involves performing data cleansing, normalization, and enrichment activities to eliminate errors, inconsistencies, and duplications, ensuring the data are of high quality and reliability.",
  data_quality___certify: "This capability involves conducting thorough assessments and validations on the collected data to certify its quality. It includes performing data quality audits, verifying adherence to predefined quality standards, and issuing certifications to confirm the reliability and accuracy of the data.",
  collection: "This swim lane focuses on the collection phase of data aggregation within the P-20W+ data pipeline. It includes activities related to gathering data from various sources, such as data providers, data feeds, or data collection\n'systems. The emphasis is on the acquisition, extraction, and ingestion of data for further processing and analysis.",
  collect: "This container focuses on the collection phase of data aggregation within the P-20W+ data pipeline. It involves activities related to gathering and acquiring data from different sources, ensuring data quality, and validating accuracy and completeness.",
  move: "This capability refers to the process of transferring data from various sources to the P-20W+ data pipeline. It involves securely moving data from external systems, such as databases or files, into the pipeline's storage infrastructure.",
  ingest: "This capability focuses on the intake and reception of data into the P-20W+ data pipeline. It includes receiving data from different sources, validating their format and integrity, and preparing them for further processing and analysis.",
  data_quality___review: "This capability involves conducting a thorough examination of the collected data to assess their quality. It includes performing data quality checks, identifying, and resolving any issues or anomalies, as well as ensuring that the data meet the required standards.",
  data_quality___validate: "This capability focuses on verifying the accuracy, consistency, and completeness of the collected data. It includes applying validation rules, conducting data profiling, and performing integrity checks to ensure the data conforms to predefined quality criteria.",
  enable: "This container represents the enabling capabilities within the P-20W+ data environment. It includes activities related to data automation, data interaction, data synthesis, and data storage to enhance the efficiency, usability, and accessibility of the data.",
  automate: "This capability involves automating data-related processes and workflows within the P-20W+ data environment. It includes leveraging technology and tools to streamline data management tasks, increase efficiency, and reduce manual effort.",
  synthesize_data: "This capability involves synthesizing or consolidating data from various sources and formats within the P-20W+ data environment. It includes data integration, aggregation, and transformation processes to create a unified and comprehensive view of the data.",
  management: "This swim lane represents the management capabilities within the P-20W+ data pipeline. It encompasses activities related to the governance, control, and administration of data. This includes metadata management, data documentation, data classification, and ensuring compliance with data governance policies and standards.",
  steward: "This container represents the management and stewardship of data within the P-20W+ data pipeline. It includes activities related to metadata management, documentation, tagging, classification, and mapping of data lineage to ensure proper governance and control over the data.",
  manage_metadata: "This capability involves organizing and maintaining metadata associated with the data stored in the P-20W+ data environment.<br><br>It includes creating metadata repositories, documenting data attributes, and ensuring the availability of comprehensive metadata for effective data management longitudinally.",
  document: "This capability refers to the process of creating and maintaining documentation related to the data within the P-20W+ data environment. It includes capturing data definitions, data lineage, data transformation processes, and other relevant information to support data governance, data management, and data use.",
  tag: "This capability involves assigning specific tags or labels to data elements within the P-20W+ data environment. It helps in categorizing and classifying data based on different criteria or attributes, facilitating easier data management, discovery, and retrieval.",
  classify: "This capability focuses on categorizing data based on predefined classification schema or taxonomies. It involves assigning data elements to specific classes or categories to enable efficient data organization, protection, search, and retrieval.",
  map_lineage: "This capability involves establishing and maintaining the lineage or traceability of data within the P-20W+ data pipeline. It includes capturing and visualizing the relationships and dependencies between different data elements, providing insights into the origin, transformation, and flow of data",
  master: "This container emphasizes the management of master data within the P-20W+ data pipeline. It involves activities related to resolving and maintaining master data entities, ensuring consistency, integrity, and accuracy across the ecosystem.",
  resolve: "This capability refers to the process of resolving conflicts or inconsistencies within the master data stored in the P-20W+ data environment. It involves identifying and resolving duplicate or conflicting records, ensuring that the master data remain accurate and reliable.",
  maintain: "This capability involves the ongoing management and maintenance of master data within the P-20W+ data environment. It includes updating and validating master data records, ensuring their accuracy, completeness, and relevance to support data-driven decision-making.",
  enable_0ece198: "This container represents the enabling capabilities within the P-20W+ data environment. It includes activities related to data automation, data interaction, data synthesis, and data storage to enhance the efficiency, usability, and accessibility of the data.",
  interact: "This capability focuses on providing interactive interfaces and functionalities within the P-20W+ data environment. It includes enabling users to interact with the data, perform data exploration, conduct queries, and access relevant information in a user-friendly manner.",
  store: "This capability refers to the process of  securely storing and managing data within the P-20W+ data environment. It includes implementing robust storage infrastructure, data backup mechanisms, and data recovery strategies to ensure data durability and availability.",
  integrate: "This container focuses on the integration of data within the P-20W+ data pipeline. It includes activities such as combining data from different sources, establishing relationships between datasets, and integrating data to create a unified view for analysis and reporting purposes.",
  combine: "This capability focuses on merging or integrating data from various sources within the P-20W+ data pipeline.<br><br>It involves combining datasets with compatible structures and formats to create a unified view of the data for analysis and reporting purposes.",
  link: "This capability involves establishing connections or relationships between different data entities within the P-20W+ data environment. It includes creating links or associations between related data elements to enable holistic analysis, data exploration, and data-driven insights.",
  retain: "This container pertains to the retention and preservation of data within the P-20W+ data environment. It involves activities related to archiving data, implementing data retention policies, auditing data usage, and ensuring data recovery capabilities.",
  archive: "This capability involves storing and preserving historical data within the P-20W+ data ecosystem for long-term retention. It includes implementing data archiving strategies, ensuring data integrity, and providing access to archived data when required.",
  audit: "This capability focuses on tracking and monitoring data usage and activities within the P-20W+ data environment. It includes capturing and recording data access, modifications, and other relevant events for compliance, auditing, and security purposes.",
  recover: 'This capability refers to the process of restoring or recovering data in the event of data loss or system failures within the P-20W+ ecosystem. It involves implementing data backup and recovery mechanisms to ensure data resilience and minimize the impact of potential disruptions.',
  secure: "This container emphasizes the security aspects of data management within the P-20W+ data environment. It includes activities related to data privacy, data suppression, pseudonymization, data authorization, and authentication to protect sensitive information and ensure secure access to the data.",
  suppress: 'This capability involves hiding or restricting access to sensitive or confidential data within the P-20W+ data environment. It includes applying data suppression techniques to protect privacy and ensure compliance with data protection regulations.',
  pseudonymize: 'This capability focuses on anonymizing or replacing identifiable data with pseudonyms within the P-20W+ data environment. It involves applying data de-identification techniques to safeguard privacy while retaining the usefulness of the data for analysis and reporting.',
  secure_enable: "Secure: This container emphasizes the security aspects of data management within the P-20W+ data environment. It includes activities related to data privacy, data suppression, pseudonymization, data authorization, and authentication to protect sensitive information and ensure secure access to the data.<br><br>Enable: This container represents the enabling capabilities within the P-20W+ data environment. It includes activities related to data automation, data interaction, data synthesis, and data storage to enhance the efficiency, usability, and accessibility of the data.",
  authorize: 'This capability involves granting appropriate permissions and access rights to authorized individuals or roles within the P-20W+ data environment. It includes defining and enforcing access control policies to ensure that only authorized users can interact with the data.',
  authenticate: "This capability refers to the process of verifying the identity and credentials of users accessing the P-20W+ data environment. It includes implementing authentication mechanisms, such as username/password or multi-factor authentication, to ensure secure and authorized access to the data.",
  governance: "This container focuses on the governance aspects of data management within the P-20W+ data environment. It includes activities related to data standardization, enforcing retention policies, sharing data, and ensuring compliance with privacy regulations and policies.",
  standardize: "This capability focuses on establishing and enforcing data standardization practices within the P-20W+ data environment. It includes defining data formats, naming conventions, and data quality rules to ensure consistency, interoperability, and data integrity.",
  enforce_retention_policies: "This capability involves enforcing data retention policies and practices within the P-20W+ data environment. It includes defining and implementing rules for data retention periods, archival requirements, and data disposal to comply with legal, regulatory, and business needs.",
  use: "This swim lane encompasses a range of capabilities involved in utilizing data effectively.<br><br>It includes activities such as preparing, curating, and formatting data for analysis, delivering analysis results and data to stakeholders, notifying relevant parties about data updates, collaborating on datarelated tasks, enhancing data quality and reliability, augmenting data with additional context or insights, and aggregating data from various sources to support decisionmaking and reporting.<br><br>These capabilities enable users to extract value from data assets and leverage them to drive informed actions and outcomes.",
  prepare: "This container emphasizes the preparation of data for analysis within the P-20W+ data environment. It includes activities such as data curation, formatting, data delivery, and collaboration to ensure the data are in a usable and appropriate state for analysis.",
  curate: "This capability involves selecting, organizing, and preparing data for analysis and reporting within the P-20W+ data environment. It includes data cleansing, data transformation, and data enrichment activities to ensure the data are in a usable and reliable state.",
  format: "This capability focuses on structuring and formatting data within the P-20W+ data environment. It includes converting data into appropriate formats, standardizing data representations, and ensuring data compatibility for effective analysis and reporting.",
  deliver_analysis: "This capability refers to providing analytical insights and reports based on the data within the P-20W+ data environment. It includes generating visualizations, dashboards, and reports to communicate data-driven insights and facilitate decision-making.",
  deliver_data: "This capability involves delivering data to designated recipients or systems within the P-20W+ data environment. It includes establishing data delivery mechanisms, such as data exports or data feeds, to provide access to the relevant data to authorized users or downstream systems.",
  notify: "This capability focuses on notifying data consumers about new or updated data within the P-20W+ data environment. It includes generating notifications, alerts, or data change notifications to keep data consumers informed and updated with the latest data changes.",
  collaborate: "This capability involves facilitating collaboration and knowledge sharing among users within the P-20W+ data environment. It includes providing collaboration features, such as data annotations, comments, and data sharing capabilities, to promote teamwork and effective data collaboration.",
  enhance: "This container focuses on enhancing the data within the P-20W+ data environment to derive more insights and value. It includes activities such as data augmentation, data aggregation, and additional processing to enrich the data.",
  augment: "This capability focuses on enhancing or enriching the data within the P-20W+ data environment. It includes integrating additional data sources, performing data enrichment through external datasets or APIs, and incorporating derived or calculated data to provide a more comprehensive view.",
  aggregate: "This capability involves consolidating or summarizing data within the P-20W+ data environment. It includes performing data aggregation operations, such as calculating totals, averages, or other statistical measures, to provide aggregated insights and higherlevel summaries of the data.",
  governance_d73fa29: "This container focuses on the governance aspects of data management within the P-20W+ data environment. It includes activities related to data standardization, enforcing retention policies, sharing data, and ensuring compliance with privacy regulations and policies.",
  share: "This capability refers to the process of securely sharing data with authorized parties or systems within the P-20W+ data environment. It includes establishing data sharing agreements, implementing data access controls, and facilitating data exchange to support collaboration and information sharing.",
  enforce_privacy_policies: "This capability focuses on ensuring compliance with privacy regulations and policies within the P-20W+ data environment. It includes implementing privacy controls, consent management mechanisms, and data anonymization techniques to protect individual privacy and uphold data protection standards.",
  leverage: "This swim lane represents the capabilities of data consumers in leveraging the data available within the P-20W+ data environment.<br><br>It includes activities such as accessing, analyzing, interpreting, and utilizing the data to derive insights, support decision-making, and drive organizational outcomes. The focus is on utilizing the data to generate value and achieve desired objectives.",
  consume: "This container represents the capabilities of data consumers within the P-20W+ data environment.<br><br>It includes activities related to accessing, interacting with, and utilizing the data, such as viewing, analyzing, downloading, evaluating, and searching the data to derive insights and support decision-making.",
  view: "This capability refers to accessing and visualizing the data within the P-20W+ data environment.<br><br>It includes providing data viewing interfaces, such as dashboards or data exploration tools, to allow data consumers to interact with and explore the data.",
  interact_6a7baf4: "This capability focuses on providing interactive interfaces and functionalities within the P-20W+ data environment. It includes enabling users to interact with the data, perform data exploration, conduct queries, and access relevant information in a user-friendly manner.",
  download: "This capability focuses on enabling data consumers to download or export data from the P-20W+ data environment.<br><br>It includes providing data export functionalities, allowing users to retrieve the data in different formats or for offline usage.",
  evaluate: "This capability refers to assessing the quality, reliability, and relevance of the data within the P-20W+ data environment.<br><br>It includes performing data quality checks, data profiling, and evaluating the data's fitness for specific purposes or analyses.",
  search: "This capability focuses on enabling data consumers to search and discover relevant data within the P-20W+ data environment.<br><br>It includes providing search functionalities, metadata-based search, or keyword-based search to facilitate data discovery and retrieval.",
  analyze: "This container involves conducting data analysis using the data within the P-20W+ data environment.<br><br>It includes applying statistical methods, data mining techniques, or other analytical approaches to extract insights, identify patterns, and make data-driven decisions.",
  interpret: "This capability involves extracting meaning, insights, and understanding from the data within the P-20W+ data environment.<br><br>It includes analyzing the data in-depth, identifying patterns, trends, and correlations, and interpreting the findings to derive meaningful conclusions or actionable recommendations.",
  predict: "This capability focuses on utilizing the data within the P-20W+ data environment to make predictions or forecasts.<br><br>It includes applying statistical modeling, machine learning, or predictive analytics techniques to uncover patterns, establish predictive models, and make informed predictions about future outcomes based on historical or real-time data."
}