import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';

@Component({
  selector: 'app-cta-hero',
  templateUrl: './cta-hero.component.html',
  styleUrls: ['./cta-hero.component.scss']
})
export class CtaHeroComponent {
  @Input() content: IContentItem | null = null;
  safeHtml: SafeHtml | null = null;



  constructor(
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    if(!this.content) return;

    const unsafeHtml = this.content.elements['body'].value;
    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);

  }


}
