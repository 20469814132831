import { Injectable } from '@angular/core';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { BehaviorSubject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  public currentHeader = new BehaviorSubject<IContentItem | null>(null)
  public currentHeader$ = this.currentHeader.asObservable().pipe(filter(header => !!header))
  constructor() { }
}
