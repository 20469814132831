import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Contracts, IContentItem } from '@kontent-ai/delivery-sdk';
import { BehaviorSubject } from 'rxjs';
import { getLinkedItems } from 'src/app/core/content-utilities';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';
import { resourceDescriptionMap } from 'src/app/component/ra-resource/taxonomy-description-map';

@Component({
  selector: 'app-ra-resource',
  templateUrl: './ra-resource.component.html',
  styleUrls: ['./ra-resource.component.scss'],
})
export class RaResourceComponent implements OnInit {
  public ITEMS_PER_PAGE = window.localStorage.getItem('COI-ItemsPerPage')
    ? parseInt(window.localStorage.getItem('COI-ItemsPerPage') || '12')
    : 12;

  taxonomy: Term[] = [];
  allTerms = new Map<string, Term>();
  selectedItem: Term | undefined;
  selectedItemCodeName: BehaviorSubject<string> = new BehaviorSubject<string>(
    '',
  );
  selectedIndex = -1;
  selectedParent: Term | undefined;

  description = '';

  coiResources: IContentItem[] = [];
  coiResourcesFiltered: IContentItem[] = [];
  coiCrosswalk: any[] = [];

  stages: string[] = [];
  userGroups: string[] = [];

  taxonomiesToDisplay: string[] = [];
  Math = Math;

  public page: number = 1;
  public maxPages = 1;
  public search: string = '';
  public type: string = '';
  public category: string = '';
  public stageOrder: string[] = [];
  public filterForm: FormGroup = new FormGroup({});

  public resourceTypes = this.kontentDeliveryService.client
    .taxonomy('format')
    .toPromise()
    .then((result) => result.data.taxonomy.terms);
  public categories = this.kontentDeliveryService.client
    .items()
    .type('journey_step')
    .toPromise()
    .then((result) => result.data.items);

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) {}

  async ngOnInit() {
    const currentView = this.route.snapshot.queryParams['view'];
    this.search = this.route.snapshot.queryParams['search'] ?? '';
    this.type = this.route.snapshot.queryParams['type'] ?? '';
    this.category = this.route.snapshot.queryParams['category'] ?? '';

    this.filterForm = this.fb.group({
      type: this.fb.control(this.type),
      category: this.fb.control(this.category),
    });

    if (currentView) {
      this.selectedItemCodeName.next(currentView);
    }

    this.filterForm.valueChanges.subscribe((changes) => {
      const cleaned = Object.keys(changes).reduce(
        (accum, key) =>
          !!changes[key]
            ? Object.assign(accum, { [key]: changes[key] })
            : accum,
        {},
      ) as any;

      let params: any = {};
      if (cleaned.category === undefined) params.category = '';
      else params.category = cleaned.category;
      if (cleaned.type === undefined) params.type = '';
      else params.type = cleaned.type;

      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: params,
        queryParamsHandling: 'merge',
      });
    });

    // sync get taxonomy
    const response = await this.kontentDeliveryService.getTaxonomyAndCache(
      'reference_architecture',
    );
    this.taxonomy = response.taxonomy.terms as Term[];
    this.taxonomy.forEach((term, idx) => {
      this.allTerms.set(term.codename, term);
      term.mappedTerms = this.populateMapWithTerms(term);
      this.allTerms = new Map([...this.allTerms, ...term.mappedTerms]);
      term.terms = Array.from(term.mappedTerms.values());
      term.isOpen =
        term.codename === this.selectedItemCodeName.value ||
        term.mappedTerms.has(this.selectedItemCodeName.value);
      if (term.isOpen) {
        this.selectedParent = term;
        this.selectedIndex = idx;
      }
    });

    const coiResources = await this.kontentDeliveryService.executeAndCache(
      'coi-resources',
      () =>
        this.kontentDeliveryService.client
          .items()
          .type('coi___resource')
          .toAllPromise(),
    );
    this.coiResources = coiResources.responses
      .map((response) => response.data.items)
      .flat();

    // after sync call get create subscriptions
    this.selectedItemCodeName?.subscribe((codeName) => {
      if (this.allTerms.has(codeName)) {
        this.selectedItem = this.allTerms.get(codeName);
        if (this.selectedItem?.codename) {
          // console.log('selectedItem', this.selectedItem);
          this.description =
            resourceDescriptionMap[this.selectedItem.codename] || '';
          this.taxonomiesToDisplay = this.collectChildTaxonomies(
            this.selectedItem,
          );
          this.filterCoiResources();
        }
      }
    });
  }
  collectChildTaxonomies(term: Term) {
    const taxonomies = [term.codename];
    term.terms?.forEach((child) => {
      taxonomies.push(...this.collectChildTaxonomies(child));
    });
    return taxonomies;
  }

  filterCoiResources() {
    this.coiResourcesFiltered = this.coiResources.filter((resource) => {
      return this.taxonomiesToDisplay.some((taxonomy) =>
        resource.elements['reference_architecture'].value.some(
          (item: any) => item.codename === taxonomy,
        ),
      );
    });

    // If any of these filtered resources have a capability_model key with a value array, return them
    this.coiCrosswalk = this.coiResourcesFiltered.filter((resource) => {
      return resource.elements['capability_model'].value.length > 0;
    });
    // If crosswalk items are found, map the array to a new array of just the capability_model values
    this.coiCrosswalk = this.coiCrosswalk.map(
      (resource) => resource.elements['capability_model'].value,
    );
    if (this.coiCrosswalk.length === 0) {
      this.coiCrosswalk.push({ name: 'Predict', codename: 'predict' });
    }

    console.log('coiResourcesFiltered', this.coiResourcesFiltered);
    console.log('coiCrosswalk', this.coiCrosswalk);

    if (this.search) {
      this.coiResourcesFiltered = this.coiResourcesFiltered.filter(
        (item) =>
          !this.search?.length ||
          this.compareElement(item, 'name', this.search) ||
          this.compareElement(item, 'short_description', this.search) ||
          this.compareElement(item, 'author', this.search),
      );
    }

    if (this.type) {
      this.coiResourcesFiltered = this.coiResourcesFiltered.filter((resource) =>
        resource.elements['format'].value.some(
          (format: any) => format.codename === this.type,
        ),
      );
    }
    if (this.category) {
      this.coiResourcesFiltered = this.coiResourcesFiltered.filter((resource) =>
        resource.elements['journey_step'].value.some(
          (journey: any) => journey.codename === this.category,
        ),
      );
    }

    const stages = new Set<string>();
    let groups = new Map<string, IContentItem>();
    this.coiResourcesFiltered.forEach((resource) => {
      resource.elements['stages'].value.forEach((stage: any) =>
        stages.add(stage.name),
      );
      getLinkedItems(resource, 'user_groups').forEach((group) =>
        groups.set(group.system.codename, group),
      );
    });

    this.userGroups = Array.from(groups.values()).map(
      (group) => group.elements['name'].value,
    );
    this.stages = Array.from(stages);
  }

  populateMapWithTerms(
    term: Term,
    map: Map<string, Term> = new Map(),
    depth = 0,
  ): Map<string, Term> {
    // Destructure to exclude the 'terms' property and then reassemble the term without it
    const terms = Object.assign({}, term).terms;
    const termWithoutTerms = Object.assign({}, term);
    termWithoutTerms.depth = depth;
    if (!map.has(term.codename) && (termWithoutTerms.depth ?? 0) > 0)
      map.set(term.codename, Object.assign({}, termWithoutTerms));
    else {
    }

    // If there are child terms, iterate through them recursively
    if (terms && terms.length > 0) {
      terms.forEach((childTerm) =>
        this.populateMapWithTerms(childTerm, map, depth + 1),
      );
    }

    return map;
  }

  wrapDivPerDepth(child: Term) {
    // for each level of depth wrap in a div
    const isSelected = this.selectedItem?.codename === child.codename; // Check if the term is selected
    let wrappedContent = `<div class='${isSelected ? 'selected' : ''} l2 '>${
      isSelected ? '<i class="bi bi-arrow-right-short arrow-i"></i>' : ''
    }${child.name}</div>`;
    const depth = child.depth ?? 0;
    for (let i = 1; i < depth; i++) {
      wrappedContent = `<div class='l2'>${wrappedContent}</div>`;
    }
    return wrappedContent;
  }

  selectItem(event: Event, term: Term): void {
    event.stopPropagation(); // Prevents triggering the click event on parent terms
    // change query params
    const params = { view: term.codename };
    this.router.navigate([], {
      relativeTo: this.route, // this.route is the ActivatedRoute
      queryParams: params,
      queryParamsHandling: 'merge', // merge with existing query params
    });
  }

  public onItemsPerPage() {
    window.localStorage.setItem(
      'COI-ItemsPerPage',
      this.ITEMS_PER_PAGE.toString(),
    );

    this.router
      .navigate(['.'], {
        queryParams: { perPage: this.ITEMS_PER_PAGE, page: 1 },
        relativeTo: this.route,
        queryParamsHandling: 'merge',
      })
      .then(() => window.scrollTo(0, 0));
  }

  public onSearch(search: string) {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { search },
      queryParamsHandling: 'merge',
    });
  }

  getLink(direction: 'previous' | 'next') {
    const currentIndex = this.taxonomy.findIndex(
      (t) => t.codename === this.selectedItem?.codename,
    );
    let nextCodename = '';
    if (direction === 'previous') {
      nextCodename =
        currentIndex > 0 ? this.taxonomy[currentIndex - 1].codename : '';
    } else {
      nextCodename =
        currentIndex < this.taxonomy.length - 1
          ? this.taxonomy[currentIndex + 1].codename
          : '';
    }

    return (
      '/reference-architecture-resources' +
      (nextCodename ? `?view=${nextCodename}` : '')
    );
  }
  private compareElement(item: IContentItem, field: string, compare: string) {
    return (item?.elements?.[field]?.value || '')
      .toLowerCase()
      .includes(compare?.toLowerCase());
  }
}

type Term = {
  name: string;
  codename: string;
  terms?: Term[];
  isOpen?: boolean;
  depth?: number;
  mappedTerms?: Map<string, Term>;
  content?: string;
};

// <div class="side-nav">
//     <!-- Non-recursive part for level 1 items -->
//     <div class="side-nav-item">
//         <div *ngFor="let term of taxonomy" >
//             <span (click)="toggleVisibility(term, $event)" [class.no-border]="!term.isOpen" [class.selected]="term?.codename === selectedItem?.codename" class="l1 typography" >
//              {{ term.name }}</span>
//             <div *ngIf="term.isOpen && (term.mappedTerms?.size ?? 0) > 0"  class="l1" >
//                    <div *ngFor="let child of term.terms">
//                     <div   (click)="selectItem($event, child)"  [innerHtml]="wrapDivPerDepth(child)"></div>

//                    </div>
//                 </div>
//         </div>
//     </div>

// </div>
