import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from "@angular/router";

export class CustomRouteReuseStrategy implements RouteReuseStrategy {

    handlers: { [key: string]: DetachedRouteHandle } = {};
  
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
      return route.data["shouldReuse"] || false;
    }
  
    store(route: ActivatedRouteSnapshot, handle: {}): void {

      this.handlers[route?.routeConfig?.path || ''] = handle
    }
  
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
      return !!route.routeConfig && !!this.handlers[route?.routeConfig?.path || ''];
    }
  
    retrieve(route: ActivatedRouteSnapshot): any {
      if (!route.routeConfig) return null;
      return this.handlers[route?.routeConfig?.path || ''];
    }
  
    shouldReuseRoute(future: any, curr: ActivatedRouteSnapshot): boolean {
  
      return false;
    }
  
  }