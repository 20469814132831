<section class="usa-section fade-in content-carousel-section display-flex flex-justify-center padding-x-2 tablet:padding-x-8">
    <div class="display-flex flex-column desktop:flex-row gap-4 desktop:flex-justify flex-justify-center flex-align-center maxw-desktop-lg">
        <div class="padding-x-2 desktop:padding-left-0 desktop:grid-col-5 display-flex flex-column gap-4">
            <div class="body-header usa-prose" [innerHTML]="safeHeaderHtml"></div>

            <div class="body-content remove-margin-from-immediate-children font-xl" [innerHTML]="safeBodyHtml"></div>

            <a class="text-no-underline" [routerLink]="content?.elements?.['cta_url_path']?.value"> 
                <button class="usa-button margin-top-4 flex-align-self-start" >{{content?.elements?.['cta_text']?.value}} 
                    <i class="bi text-primary-light font-lg text-heavy {{content?.elements?.['cta_icon']?.value || 'bi-arrow-right-short'}}"></i>
                </button>
            </a>
        </div>
        <div role="region" aria-label="Carousel" class="content-carousel-container desktop:margin-right-2 margin-top-8 desktop:margin-top-0 position-relative flex-align-self-center  maxw-tablet desktop:maxw-mobile-lg offset-border offset-top-right width-full">
            <!-- <div class="content-carousel-background-border bottom-2 z-0 left-2 position-absolute height-full width-full border-05 border-primary"></div> -->
            <div class="content-carousel display-flex flex-column position-relative bg-white padding-x-2 desktop:padding-x-0 padding-y-2 text-base-darkest height-full gap-3">
                <ng-container *ngIf="carouselItems[selectedCarouselItemIdx] as item">
                    <section aria-atomic="true"  aria-live="polite"  class="content-carousel-header desktop:padding-x-2 dark-text-selector width-full display-flex flex-align-center">
                        <div class="carousel-header-content remove-margin-from-immediate-children width-full" [innerHTML]="safeCarouselHtml"></div>
                        <span  class="text-primary-dark flex-justify-end display-flex flex-align-center gap-1 text-heavy font-lg">{{selectedCarouselItemIdx + 1}} <span class="text-base-darkest text-semibold font-sm">of</span> {{carouselItems.length}}</span>
                    </section>
    
                    <div class="content-carousel-body width-full gap-2">
                        <button [attr.aria-label]="'Previous Carousel Item: ' + item.name" 
                            (click)="paginateCarouselItem(false)"
                            class="item-a display-flex usa-button--unstyled content-carousel-header-content-left-control font-2xl flex-justify-center cursor-pointer flex-align-self-center flex-align-center text-{{item.color}}  width-auto">
                                <i class="bi bi-chevron-left text-heavy"></i>
                        </button>
                        
                        <div class="content-carousel-body-content item-b padding-3 text-white grid-col width-full display-flex flex-column gap-2 bg-base-darkest radius-lg">
                            <div class="content-carousel-body-header">
                                <!-- <p class="text-bold font-lg">{{item.name}}</p>
                                <p class="font-3xs margin-top-neg-1 display-flex flex-wrap gap-05 flex-align-center"><i class="bi {{item.icon}} text-{{item.color}} "></i><strong>Community</strong> of <strong>Innovation</strong></p> -->
                                <img *ngIf="item.header_image !== undefined" [src]="item.header_image.url" [alt]="item.header_image.url">
                            </div>
                            <div class="content-carousel-body-main display-flex gap-2">
                                <p class="usa-prose margin-0 font-sm">{{item.body}}</p>
                                <a aria-label="Explore (opens in a new tab)" [href]="item.url" target="_blank" class="carousel-item-link padding-1 bg-{{item.color}} text-base-darkest font-lg flex-align-self-end ">
                                    <i class="bi bi-arrow-right-short text-heavy"></i>
                                </a>
                           </div>
                        </div>
                        <button [attr.aria-label]="'Next Carousel Item: ' + item.name" 
                            (click)="paginateCarouselItem(true)"
                            class="display-flex item-c cursor-pointer content-carousel-header-content-right-control usa-button--unstyled flex-justify-center font-2xl  flex-align-self-center flex-align-center text-{{item.color}} width-auto">
                                <i class="bi bi-chevron-right text-heavy"></i>
                        </button>
                        <div role="group" aria-label="Carousel Controls" class="content-carousel-pagination item-d display-flex gap-105 flex-align-center width-full flex-justify-center">
                            <button (click)="selectedCarouselItemIdx = i" *ngFor="let item of carouselItems; let i = index;" 
                                [attr.aria-label]="'Go to carousel item ' + (i + 1) + ' name: ' + item.name" 
                                class="pagination-orb usa-button--unstyled bg-base-lighter radius-pill height-2 width-2 hover:bg-primary-dark" 
                                [ngClass]="{'bg-primary-dark': i === selectedCarouselItemIdx}">
                            </button>
                        </div>
                    </div>


                </ng-container>
            </div>
        </div>
    </div>
</section>