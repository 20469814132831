import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getLinkedItems } from 'src/app/core/content-utilities';
import { TriangleComponent } from 'src/app/interface/TriangleComponent';

@Component({
  selector: 'app-block-overview-simple',
  templateUrl: './block-overview-simple.component.html',
  styleUrls: ['./block-overview-simple.component.scss']
})
export class BlockOverviewSimpleComponent extends TriangleComponent implements OnInit {

  @Input() override content: IContentItem | null = null;
  safeBodyHtml: SafeHtml | null = null;
  bgColor: string = '';

  constructor(
    private sanitizer: DomSanitizer,
    ele: ElementRef
    ) { 
        super(ele);
    }

  override ngOnInit(): void {
    if(!this.content) return;

    const unsafeHtml = this.content.elements['body'].value;
    this.safeBodyHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);
    if (this.content.elements['background_color'].value) {
        this.bgColor = this.content.elements['background_color'].value;
    }

    super.ngOnInit();
  }

}
