import { Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';

@Component({
  selector: 'app-capability-model',
  templateUrl: './capability-model.component.html',
  styleUrls: ['./capability-model.component.scss']
})
export class CapabilityModelComponent {

  @Input() content: IContentItem | null = null;
  title: SafeHtml | null = null;

  baseSlug = `/capability-model-resources?view=`;
  // Data "model" to test tab focus order

  dataProvider = {
    name: 'Data Providers',
    url: `${this.baseSlug}data_providers`,
    children: [
      {
        name: 'Data Application',
        url: `${this.baseSlug}data_application`,
        children: [
          {
            name: 'Data Quality Layer',
            url: `${this.baseSlug}data_quality_layer`,
            children: [
              {
                name: 'Business Rules Engine',
                url: `${this.baseSlug}business_rules_engine`,
              },
              {
                name: 'Standardized Business Rules Representation',
                url: `${this.baseSlug}standardized_business_rules_representation`,
              },
            ],
          },
        ],
      },
    ],
  };

  APIStandards = {
    name: 'API Standards By P-20W+ Domain',
    url: `${this.baseSlug}api_standards_by_p_20w__domain`,
    children: [
      {
        name: 'Early Childhood',
        url: `${this.baseSlug}early_childhood`,
      },
      {
        name: 'K12',
        url: `${this.baseSlug}k12`,
        children: [
          {
            name: 'EdFi',
            url: `${this.baseSlug}edfi`,
          },
          {
            name: 'A4L/SIF',
            url: `${this.baseSlug}a4l_sif`,
          },
          {
            name: '1EdTech',
            url: `${this.baseSlug}edtech`,
          },
        ],
      },
      {
        name: 'Postsecondary',
        url: `${this.baseSlug}postsecondary`,
        children: [
          {
            name: 'PESC',
            url: `${this.baseSlug}pesc`,
          },
        ],
      },
      {
        name: 'Workforce',
        url: `${this.baseSlug}workforce`,
      },
      {
        name: 'Adult Education',
        url: `${this.baseSlug}adult_education`,
      },
    ],
  };

  FederatedAuthentication = {
    name: 'Federated Authentication',
    url: `${this.baseSlug}federated_authentication`,
  };

  Authorization = {
    name: 'Authorization',
    url: `${this.baseSlug}authorization`,
  };

  DataPrivacyObligations = {
    name: 'Data Privacy Obligations',
    url: `${this.baseSlug}data_privacy_obligations`,
  };

  DataAggregator = {
    name: 'Data Aggregator',
    url: `${this.baseSlug}data_aggregator`,
    children: [
      {
        name: 'Data Lineage and Auditing',
        url: `${this.baseSlug}data_lineage_and_auditing`,
      },
      {
        name: 'Data Dictionary and Metadata',
        url: `${this.baseSlug}data_dictionary_and_metadata`,
      },
      {
        name: 'Longitudinal Identity Resolution',
        url: `${this.baseSlug}longitudinal_identity_resolution`,
      },
      {
        name: 'Landing',
        url: `${this.baseSlug}landing`,
      },
      {
        name: 'CEDS Data Warehouse',
        url: `${this.baseSlug}ceds_data_warehouse`,
      },
      {
        name: 'Storage Types',
        url: `${this.baseSlug}storage_types`,
        children: [
          {
            name: 'Archived Storage',
            url: `${this.baseSlug}archived_storage`,
          },
          {
            name: 'Operational',
            url: `${this.baseSlug}operational`,
          },
          {
            name: 'Standardized',
            url: `${this.baseSlug}standardized`,
          },
          {
            name: 'Curated',
            url: `${this.baseSlug}curated`,
          },
          {
            name: 'Data Retention',
            url: `${this.baseSlug}data_retention`,
          },
          {
            name: 'Organize/Govern/Classify',
            url: `${this.baseSlug}organize_govern_classify`,
          },
        ],
      },
      {
        name: 'Validation Level',
        url: `${this.baseSlug}validation_level`,
        children: [
          [
            {
              name: 'Landing Schema Level',
              url: `${this.baseSlug}landing_schema_level`,
            },
            {
              name: 'Operational Unit Level Comparison',
              url: `${this.baseSlug}operational_unit_level_comparison`,
            },
            {
              name: 'Standardized Statistical and Longitudinal Analysis',
              url: `${this.baseSlug}standardized_statistical_and_longitudinal_analysis`,
            },
            {
              name: 'Curated Business Rule Specific',
              url: `${this.baseSlug}curated_business_rule_specific`,
            },
          ]
        ]
      },
      {
        name: 'Data Quality Layer',
        url: `${this.baseSlug}data_quality_layer`,
        children: [
          {
            name: 'Business Rules Engine',
            url: `${this.baseSlug}business_rules_engine`,
          },
          {
            name: 'Standardized Business Rules Representation',
            url: `${this.baseSlug}standardized_business_rules_representation`,
          },
        ],
      },
    ],
  };

  DataConsumers = {
    name: 'Data Consumers',
    url: `${this.baseSlug}data_consumer`,
    children: [
      {
        name: 'Federal Reporting',
        url: `${this.baseSlug}federal_reporting`,
      },
      {
        name: 'Reporting/Analytics',
        url: `${this.baseSlug}reporting_analytics`,
      },
      {
        name: 'Operational Systems',
        url: `${this.baseSlug}operational_systems`,
      },
      {
        name: 'Consumer Application',
        url: `${this.baseSlug}consumer_application`,
      },
      {
        name: 'Data Sharing',
        url: `${this.baseSlug}data_sharing`,
      },
    ],
  };
}
