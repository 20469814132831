import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-bypassHtmlSafety-component',
    template: ``})
    /**
     *  Extends this class to implement bypassSecurityTrustHtml function to allow html to be rendered in the DOM
     */
export class TrustedHtmlComponent {

  constructor(
    private sanitizer: DomSanitizer
    ) { }

    bypassSecurityTrustHtml(html: string) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
        
      }
}