import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { catchError, firstValueFrom, of } from 'rxjs';
import { TriangleComponent } from 'src/app/interface/TriangleComponent';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent extends TriangleComponent implements OnInit {
  safeTitleHtml: SafeHtml | null = null;

  public contactFormGroup: FormGroup;

  public submitting = false;

  public submitted = sessionStorage.getItem("contactSignUp") === "true"
  public error = false;

  constructor(private elementRef: ElementRef, private fb: FormBuilder, private data: KontentDeliveryService, private sanitizer: DomSanitizer){
    super(elementRef)
    this.contactFormGroup = this.fb.group({
      'name': this.fb.control('', [Validators.required]),
      'email': this.fb.control('', [Validators.email, Validators.required]),
      'message': this.fb.control('', [])
    })
  }


  override ngOnInit(): void {
   
    if(!this.content) return;

    let unsafeHtml = this.content.elements['title'].value;
    this.safeTitleHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);


    super.ngOnInit()
  }

  async onSubmit(){
    if(!this.contactFormGroup.valid){
      return;
    }
    this.submitting = true;
  

    try{

      await firstValueFrom(this.data.registerContact(this.contactFormGroup.getRawValue()))
      this.submitted = true;
      this.submitting = true;
      this.error = false;
      sessionStorage.setItem("contactSignUp", "true")
    }catch(err){
      this.submitting = false; 
      this.error = true;

    }





  }

  get name(){
    return this.contactFormGroup.get('name')!
  }

  get email(){
    return this.contactFormGroup.get('email')!
  }

  get message(){
    return this.contactFormGroup.get('message')!
  }

}
