<div class="resource-card display-flex flex-column bg-white dark-text-selector">
    <div
        class="resource-card-header text-base-darkest text-semibold  remove-margin-from-immediate-children padding-1 font-xs line-height-3 display-flex gap-05">
        <p>{{content?.elements?.['year']?.value}}</p>
        <i class="bi bi-dot text-heavy opacity-50"></i>
        <p>{{content?.elements?.['format']?.value[0].name}}</p>
        <i class="bi bi-dot text-heavy opacity-50"></i>
        <p>{{content?.elements?.['complexity']?.value[0].name}}</p>
    </div>
    <div class="resource-card-body flex-1 padding-3 text-base-darkest display-flex flex-column gap-2">

        <div class="resource-card-name display-flex flex-column gap-1">
            <h3 class="text-semibold line-height-3 font-lg">{{content?.elements?.['name']?.value}}</h3>
            <p class="font-xs text-italic margin-0">by {{content?.elements?.['author']?.value}}</p>
        </div>

        <p class="font-xs margin-0 flex-1 remove-margin-from-immediate-children line-height-3">
            {{content?.elements?.['short_description']?.value}}
        </p>

        <div class="display-flex flex-column gap-1">

            <div class="resource-card-tags text-semibold font-xs display-flex flex-wrap gap-05">
                <ng-container *ngFor="let tag of stages; let isLast = last">
                    <p class="margin-0">{{tag}}</p>
                    <i *ngIf="!isLast" class="bi bi-dot text-heavy opacity-50"></i>
                </ng-container>
            </div>

            <div class="resource-card-tags text-semibold font-xs display-flex flex-wrap gap-05">
                <ng-container *ngFor="let tag of userGroups; let isLast = last">
                    <p class="margin-0">{{tag}}</p>
                    <i *ngIf="!isLast" class="bi bi-dot"></i>
                </ng-container>
            </div>

        </div>

        <!-- <div *ngIf="!standards?.length && standard?.elements?.['logo']?.value[0].url?.length" class="resource-card-icon width-10">
            <img [src]="standard?.elements?.['logo']?.value[0].url" [alt]="standard?.elements?.['logo']?.value[0].url">
        </div> -->
        <div *ngIf="standard?.length" class="resource-card-icon width-10">
            <img *ngFor="let std of standard" [src]="std?.elements?.['logo']?.value[0].url" [alt]="std?.elements?.['logo']?.value[0].url">
        </div>

    </div>
    <div class="resource-card-footer flex-align-center flex-justify text-base-darkest text-semibold remove-margin-from-immediate-children padding-1 font-xs line-height-3 display-flex">
        <p>{{journeyStep?.elements?.['name']?.value}}</p>
        <a [routerLink]="['/resource', content?.elements?.['slug']?.value]" (click)="clickResource()"
            class="usa-button margin-left-auto width-auto resource-card-learn-more">Learn More <i
                class="bi font-lg text-heavy bi-arrow-right-short"></i></a>
    </div>
</div>