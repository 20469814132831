import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { getTriangleClasses } from '../core/content-utilities';
import { IContentItem } from '@kontent-ai/delivery-sdk';

@Component({
  selector: 'app-triangle-component',
  template: ``,
})
export class TriangleComponent implements OnInit, AfterViewInit {
  @Input() content: any;

  public leftTriColor = 'var(--usa-body-background)';
  public rightTriColor = 'var(--usa-primary-darkest)';

  public topTriangle = '';
  public bottomTriangle = '';

  @HostBinding('attr.style')
  public get cssVars() {
    return `
      --left-tri-color: ${this.leftTriColor};
      --right-tri-color: ${this.rightTriColor};
    `;
  }

  constructor(private ele: ElementRef) {}

  public ngOnInit() {
    const [top, bottom] = getTriangleClasses(this.content);

    this.topTriangle = top;
    this.bottomTriangle = bottom;
  }

  async ngAfterViewInit() {
    const prev = await this._getSibling();

    const next = await this._getSibling('nextElementSibling');

    if (prev && prev.firstChild) {
      const prevComputed = getComputedStyle(prev!.firstChild! as Element);
      this.setTopLeftTriangleColor(
        this._defaultBg(prevComputed.backgroundColor),
      );
    }

    if (next && next.firstChild) {
      const nextComputed = getComputedStyle(next!.firstChild! as Element);

      this.setBottomRightTriangleColor(
        this._defaultBg(nextComputed.backgroundColor),
      );
    }
  }

  private async _getSibling(
    what:
      | 'previousElementSibling'
      | 'nextElementSibling' = 'previousElementSibling',
  ) {
    await new Promise((res, rej) => setTimeout(res));
    let cursor = (this.ele.nativeElement as HTMLDivElement)[what];

    return cursor;
  }

  private _defaultBg(bg: string) {
    if (bg === 'rgba(0, 0, 0, 0)') {
      return 'var(--usa-body-background)';
    }
    return bg;
  }

  public setTopLeftTriangleColor(color: string) {
    this.leftTriColor = color;
  }

  public setBottomRightTriangleColor(color: string) {
    this.rightTriColor = color;
  }
}
