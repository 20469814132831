// home-page.resolver.ts

import { ActivatedRouteSnapshot } from '@angular/router';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';
import { inject } from '@angular/core';

export async function standardPageResolver(
    route: ActivatedRouteSnapshot,
) {
    const slug = route.paramMap.get('slug');
    const kontentService = inject(KontentDeliveryService);
    const content = await kontentService.getItemByFieldAndCache('template_standard_page','url_slug',slug, 3);
    return content[0]
}
