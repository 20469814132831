import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { getTriangleClasses } from 'src/app/core/content-utilities';
import { TriangleComponent } from 'src/app/interface/TriangleComponent';

@Component({
  selector: 'app-text-cta-block',
  templateUrl: './text-cta-block.component.html',
  styleUrls: ['./text-cta-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextCTABlockComponent
  extends TriangleComponent
  implements OnInit, AfterViewInit
{
  safeAttributionHtml: SafeHtml | null = '';

  @ViewChild('quoteSection') quoteSection?: ElementRef<HTMLDivElement>;

  constructor(
    private sanitizer: DomSanitizer,
    ele: ElementRef,
  ) {
    super(ele);
  }

  override ngOnInit(): void {
    if (!this.content) return;

    const unsafeAttributionHtml = this.content.elements['body'].value;
    this.safeAttributionHtml = this.sanitizer.bypassSecurityTrustHtml(
      unsafeAttributionHtml,
    );

    super.ngOnInit();
  }
}
