import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { from, map, startWith, switchMap, tap } from 'rxjs';
import { KontentDeliveryService } from 'src/app/service/kontent-delivery.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResourcesComponent implements OnInit {
  Math = Math

  public  ITEMS_PER_PAGE = window.localStorage.getItem('COI-ItemsPerPage') ? parseInt(window.localStorage.getItem('COI-ItemsPerPage') || '12') : 12;

  @Input() content?: IContentItem

  public filterFormGroup: FormGroup;
  
  public page: number = 1;

  public maxPages = 1;

  public sort = 'recent';
  public search = '';
  public category = '';
  public format = '';
  public complexity = '';

  public library = from(this.kontent.client.items().type('coi___resource').toAllPromise())
  .pipe(switchMap(result => {
    return this.route.queryParams
    .pipe(startWith({}))
    .pipe(map((params: Params) => { 
      let items = result.data.items;
      this.page = Math.max(1, parseInt(params['page'] || '1'))
      this.search = params['search'] || '';
      const {category, format, complexity} = params;
      const sort = params['sort'] || 'recent';
      this.sort = sort;
      this.ITEMS_PER_PAGE = parseInt(params['perPage'] || this.ITEMS_PER_PAGE);

      this.filterFormGroup.patchValue({category, format, complexity}, {emitEvent: false})
      let resources: IContentItem[] = items
      .filter((item) => (!this.search?.length 
        || this.compareElement(item, 'name', this.search)
        || this.compareElement(item, 'short_description', this.search)
        || this.compareElement(item, 'author', this.search))
        && (!complexity || complexity === 'all' || item.elements['complexity'].value[0].codename === complexity)
        && (!category || item.elements['journey_step']?.value[0] === category)
        && (!format || item.elements['format'].value[0].codename === format)
      );

      resources = resources.sort((a,b) => {
        switch(sort){
          case 'recent':{
            return b.elements['year'].value - a.elements['year'].value
          }
          case 'atoz':{
            return a.elements['name'].value.localeCompare(b.elements['name'].value)
          }
          case 'atoz':{
            return b.elements['name'].value.localeCompare(a.elements['name'].value)
          }
        }
      });

      this.maxPages = Math.ceil(resources.length / this.ITEMS_PER_PAGE)
      return {total: resources.length, resources: resources.slice((this.page - 1) * this.ITEMS_PER_PAGE, this.page * this.ITEMS_PER_PAGE)}
    }));
  }));

  constructor(private fb: FormBuilder, private kontent: KontentDeliveryService, private route: ActivatedRoute, private router: Router){
    this.filterFormGroup = this.fb.group({
      'category': this.fb.control(''),
      'format': this.fb.control(''),
      'complexity': this.fb.control('')
    });
  }

  private compareElement(item: IContentItem, field: string, compare: string){

    return (item?.elements?.[field]?.value || '').toLowerCase().includes(compare?.toLowerCase())

  }

  public clickFormat(format: string) {
    this.format = this.format === format ? '' : format;
    this.filterFormGroup.controls['format'].setValue(this.format, { emitEvent: false });
    this.filterLibrary();
  }

  public clickCategory(category: string) {
    this.category = this.category === category ? '' : category;
    this.filterFormGroup.controls['category'].setValue(this.category, { emitEvent: false });
    this.filterLibrary();
  }

  public clickComplexity(complexity: string) {
    this.complexity = this.complexity === complexity ? '' : complexity;
    this.filterFormGroup.controls['complexity'].setValue(this.complexity, { emitEvent: false });
    this.filterLibrary();
  }

  ngOnInit(): void { }

  public filterLibrary() {
    this.library = from(this.kontent.client.items().type('coi___resource').toAllPromise())
    .pipe(map(result => {
      let items = result.data.items;
      let resources: IContentItem[] = items
        .filter((item) => (!this.search?.length 
          || this.compareElement(item, 'name', this.search)
          || this.compareElement(item, 'short_description', this.search)
          || this.compareElement(item, 'author', this.search))
          && (!this.complexity || this.complexity === 'all' || item.elements['complexity'].value[0].codename === this.complexity)
          && (!this.category || item.elements['journey_step']?.value[0] === this.category)
          && (!this.format || item.elements['format'].value[0].codename === this.format)
        );
  
        resources = resources.sort((a,b) => {
          switch(this.sort){
            case 'recent':{
              return b.elements['year'].value - a.elements['year'].value;
            }
            case 'atoz':{
              return a.elements['name'].value.localeCompare(b.elements['name'].value);
            }
            case 'ztoa':{
              return b.elements['name'].value.localeCompare(a.elements['name'].value);
            }
          }
        });
  
        this.maxPages = Math.ceil(resources.length / this.ITEMS_PER_PAGE);
        return {
          total: resources.length, 
          resources: resources.slice((this.page - 1) * this.ITEMS_PER_PAGE, this.page * this.ITEMS_PER_PAGE)
        }
      }));
  }

  public onItemsPerPage(){
    window.localStorage.setItem('COI-ItemsPerPage', this.ITEMS_PER_PAGE.toString());

    this.router.navigate(['.'], { fragment: 'resources', queryParams: {perPage: this.ITEMS_PER_PAGE}, relativeTo: this.route, queryParamsHandling: 'merge'})
    .then(() => window.scrollTo(0, 0))
  }

  public onSearch(search: string) {
    this.search = search?.toLowerCase();
    this.filterLibrary();
  }

  public onSort(sort: string) {
    this.sort = sort;
    this.filterLibrary();
  }

  private _copy(data: any, times: number){
    let copy = [];
    for(let i = 0; i < times; i++){
      copy.push(...structuredClone(data))
    }
    return copy;
  }

  public resetFilters(){
    this.category = '';
    this.filterFormGroup.controls['category'].setValue(this.category, { emitEvent: false });
    this.format = '';
    this.filterFormGroup.controls['format'].setValue(this.format, { emitEvent: false });
    this.complexity = '';
    this.filterFormGroup.controls['complexity'].setValue(this.complexity, { emitEvent: false });
    this.filterLibrary();
  }

}
